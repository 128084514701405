// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getSetPointsForDevice } from '_redux/iot/selectors';

// Components
import ShowHideGroup from '_components/device_settings/ShowHideGroup.component';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';

// UI Framework
import {
  Text,
  View,
} from 'native-base';


import { styles } from '_components/device_settings/MiniDiversionStatusVitalsSettings.style';

export const defaultVitalsSettings = {
  highLevel: { show: true, order: 0 },
  highLevelSwitch: { show: true, order: 1 },
  lowLevel: { show: true, order: 2 },
  lowLevelSwitch: { show: true, order: 3 },
  backwash: { show: true, order: 4 },
  transducerFailure: { show: true, order: 5 },
  highVoltage: { show: true, order: 6 },
  lowVoltage: { show: true, order: 7 },
  pumpRunFail: { show: true, order: 8 },
  controllerFault: { show: true, order: 9 },
};

class MiniDiversionAlarmStatusVitalsSettings extends Component {

  constructor(props) {
    super(props);

    const { deviceSettings } = props;
    let { vitalsSettings = defaultVitalsSettings } = deviceSettings;

    this.state = {
      currentVitals: vitalsSettings,
    }

    this._show = this._show.bind(this);
    this._hide = this._hide.bind(this);
  }

  _modifyShowHide(vital, show) {
    const { settingsChanged, setError } = this.props;

    // Update the settings
    const newVitalsSettings = { ...this.state.currentVitals, ...{ [vital.id]: { order: vital.order, show: show } } };
    const numShow = Object.entries(newVitalsSettings).reduce((prevVal, curVal) => prevVal + (curVal[1].show ? 1 : 0), 0);

    if (numShow <= 8) {
      settingsChanged({ vitalsSettings: newVitalsSettings });
      setError(false, '');
    } else {
      setError(true, 'Only 8 vitals may be selected');
    }
    // Update the state
    this.setState({ currentVitals: newVitalsSettings });
  }

  _show(vital) {
    this._modifyShowHide(vital, true);
  }

  _hide(vital) {
    this._modifyShowHide(vital, false);
  }

  render() {
    const { currentVitals } = this.state;

    const vitalsMap = [
      { id: 'highLevel', label: 'HIGH LEVEL'},
      { id: 'highLevelSwitch', label: 'HIGH LEVEL SWITCH'},
      { id: 'lowLevel', label: 'lowLevel'},
      { id: 'lowLevelSwitch', label: 'LOW LEVEL SWITCH'},
      { id: 'backwash', label: 'BACKWASH'},
      { id: 'transducerFailure', label: 'TRANSDUCER FAILURE'},
      { id: 'highVoltage', label: 'HIGH VOLTAGE'},
      { id: 'lowVoltage', label: 'LOW VOLTAGE'},
      { id: 'pumpRunFail', label: 'PUMP RUN FAIL'},
      { id: 'controllerFault', label: 'CONTROLLER FAULT'},
    ];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(currentVitals).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    const vitalsComponents = [];
    sortedVitals.forEach((vital) => {
      const label = vitalsMap.filter(item => item.id === vital[0])[0].label;
      vitalsComponents.push(
        <View key={vital[0]} style={[styles.vitalsRow]}>
          <Text style={[styles.vitalsLabel]}>{label}</Text>
          <ShowHideGroup
            onShow={() => this._show({ id: vital[0], order: vital[1].order })}
            onHide={() => this._hide({ id: vital[0], order: vital[1].order })}
            show={vital[1].show}
          />
        </View>
      )
    });

    return (
      <View style={[styles.vitalsContainer]} >
        {vitalsComponents}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
  };
};

MiniDiversionAlarmStatusVitalsSettings.propTypes = {
  deviceId: PropTypes.string.isRequired,
  settingsChanged: PropTypes.func.isRequired,
  deviceSettings: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, null)(MiniDiversionAlarmStatusVitalsSettings);
