import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  card: {
    maxWidth: 650,
    width: '100%',
  },
  headerImage: {
    height: 120,
    maxWidth: 320,
    width: '100%',
    marginBottom: 15,
    marginTop: 25,
  },
  formContainer:{
    width: '100%',
    maxWidth: 400,
  },
  paragraph: {
    fontSize: 20,
    marginTop: 25,
    marginBottom: 25,
  },
  subtitle: {
    fontSize: 26,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 80,
    fontWeight: 'bold',
  },
  backToSignIn: {
    marginTop: 75,
  },
  signIn: {
    fontSize: 16,
    height: '100%',
    marginTop: 75,
    marginBottom: 25,
  },
  errorMessage: {
    margin: 10,
    color: 'red',
  },
  resendCode: {
    alignSelf: 'flex-end',
    marginRight: 100,
  },
  resendCodeText: {
    fontSize: 16,
    height: '100%',
    marginTop: 10,
    marginBottom: 10,
  }
});
