import { StyleSheet } from 'react-native';

import {
  textLight,
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  vitalsContainer: {
    marginTop: 5,
  },
  vitalsRow:{
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 150,
    paddingTop: 1,
    paddingBottom: 1,

    borderColor: '#424242',
    borderBottomWidth: 1,
    height: '100%',
  },
  noBorder: {
    borderBottomWidth: 0,
  },
  vitalsLabel: {
    color: textLight,
    fontFamily: robotoCondensed,
    fontSize: 14,
    width: 100,
  },
  vitalsValue: {
    marginLeft: 15,
  },
  vitalsUnit: {
    color: textLight,
    fontFamily: robotoCondensed,
    fontSize: 14,
    textAlign: 'right',
    width: 30,
  },
});
