// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import {
  iotDeviceIsConnected,
  isAlarmOnForDevice,
  isWarningOnForDevice,
  isEngineRunningForDevice,
}
from '_redux/iot/selectors';
import { isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import CurrentEngine from '_components/device_card/CurrentEngine.component';
import MiniCurrentEngine from '_components/device_card/MiniCurrentEngine.component';
import MiniCurrentCentral from '_components/device_card/MiniCurrentCentral.component';
import DeviceSettings from '_components/device_settings/DeviceSettings.component';
import SignalStrength from '_components/device_card/MiniSignalStrength.component';
import MiniCurrentDiversionStatus1 from '_components/device_card/MiniCurrentDiversionStatus1.component';
import MiniCurrentDiversionStatus2 from '_components/device_card/MiniCurrentDiversionStatus2.component';
import MiniCurrentDiversionAlarm from '_components/device_card/MiniCurrentDiversionAlarm.component';


// React-Native
import { Image, TouchableOpacity } from 'react-native';

// Native Base
import {
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';

import { styles as global, card_yellow, card_gray, card_red, card_green } from '_style/Global.style';
import { styles } from '_components/device_card/MiniDeviceCard.style';

class MiniDeviceCard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSettings: false,
    };

    this._handleSettingsButtonPress = this._handleSettingsButtonPress.bind(this);
    this._handleCloseSettingsButtonPress = this._handleCloseSettingsButtonPress.bind(this);
  }

  _handleSettingsButtonPress() {
    this.setState({
      showSettings: true,
    });
  }

  _handleCloseSettingsButtonPress() {
    this.setState({
      showSettings: false,
    });
  }

  render() {

    const {
      deviceId,
      deviceName,
      deviceIsConnected,
      disabled = false,
      isFetchingCardSettings,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      engineNumber,
      centralDeviceCard,
      engineDeviceCard,
      miniDiversionStatus1Card,
      miniDiversionStatus2Card,
      miniDiversionAlarmCard,
    } = this.props;
    const { showSettings } = this.state;

    let headerColor = card_gray;
    if (isEngineRunningForDevice) headerColor = card_green;
    if (isWarningOnForDevice) headerColor = card_yellow;
    if (isAlarmOnForDevice) headerColor = card_red;

    return (
      <Card style={ [global.card, styles.deviceCard] }>
        {
          disabled &&
            <View style={[styles.disabledContainer]}>
              <Text style={[styles.disabledText]}>This device has been suspended.</Text>
            </View>
        }
        {
          !deviceIsConnected &&
            <View style={[global.disconnectedContainer]}>
              <Spinner color='white' />
            </View>
        }
        {/* Header */}
        <CardItem header style={ [styles.deviceCardTitle, { backgroundColor: headerColor }] }>

          {/* Device Id */}
          <Text style={[
            global.fontRobotoCondensedBold,
            global.textDark,
            styles.deviceCardTitleText
          ]}>
            { deviceName || deviceId }
          </Text>

          {/* Signal & Settings */}
          {/* <View style={[styles.rightContainer]}>
            {
              deviceIsConnected ?
                <SignalStrength strength={5} />
                :
                null
            }
          </View> */}
        </CardItem>

        {/* Current Engine & Settings */}
        <CardItem style={ [styles.deviceCardContent]}>
          {
            engineDeviceCard ?
            <MiniCurrentEngine engineNumber={engineNumber} deviceId={deviceId} />
            :
            centralDeviceCard ?
            <MiniCurrentCentral deviceId={deviceId} />
            :
            miniDiversionStatus1Card ?
            <MiniCurrentDiversionStatus1 deviceId={deviceId} />
            :
            miniDiversionStatus2Card ?
            <MiniCurrentDiversionStatus2 deviceId={deviceId} />
            :
            miniDiversionAlarmCard ?
            <MiniCurrentDiversionAlarm deviceId={deviceId} />
            :
            null
          }
        </CardItem>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceIsConnected: iotDeviceIsConnected(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
  };
};

MiniDeviceCard.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  deviceIsConnected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isFetchingCardSettings: PropTypes.bool.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(MiniDeviceCard);
