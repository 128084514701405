// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getTelemetryForDiversionBitPack, getAlisoViejoTelemetryForDevice, getTelemetryForEngineBitPack, isEnginePresent } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import MiniTelemetryValue from '_components/device_card/MiniTelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/MiniDiversionStatusVitals.style';

import { defaultVitalsSettings } from '_components/device_settings/MiniDiversionTimerStatusVitalsSettings.component';

const MAX_VITALS = 20;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <Text style={[styles.vitalsValue]}>{value}</Text>
    </Row>
  );
}


class MiniDiversionStatus1Vitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      deviceSettings,
      isUserFetchingDeviceCardSettings,
      engineData,
      centralData,
      REMOTE_DATA_TX,
      isEnginePresent,
      engineNumber,
      statusData,
    } = this.props;

    const {
      mbSystem2StatusBitpack,
    } = statusData;

    const {
      sdly_1ST_STAGE_STARTb_timer_active,
      sdly_1ST_STAGE_STOPb_timer_active,
      sdly_PUMP_1_RUN_DELAYb_timer_active,
      b_low_level_alarm_dly_timing,
      b_hi_level_alarm_dly_timing,
    } = mbSystem2StatusBitpack;

    let startDelayTiming = "-";
    if(sdly_1ST_STAGE_STARTb_timer_active){
      startDelayTiming = 'ON';
    }else{
      startDelayTiming = 'OFF';
    }

    let stopDelayTiming = "-";
    if(sdly_1ST_STAGE_STOPb_timer_active){
      stopDelayTiming = 'ON';
    }else{
      stopDelayTiming = 'OFF';
    }

    let pumpRunFailTiming = "-";
    if(sdly_PUMP_1_RUN_DELAYb_timer_active){
      pumpRunFailTiming = 'ON';
    }else{
      pumpRunFailTiming = 'OFF';
    }

    let lowLevelBubbleTiming = "-";
    if(b_low_level_alarm_dly_timing){
      lowLevelBubbleTiming = 'ON';
    }else{
      lowLevelBubbleTiming = 'OFF';
    }

    let highLevelBubbleTiming = "-";
    if(b_hi_level_alarm_dly_timing){
      highLevelBubbleTiming = 'ON';
    }else{
      highLevelBubbleTiming = 'OFF';
    }

    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;



    //Note: for the engine hours and water level it's divided by 10 to give the correct ammount. We may want to perform this or other calculations outside
    //Note: for the engine hours and water level it's divided by 10 to give the correct ammount. We may want to perform this or other calculations outside
    // of the "vitals" const.
    const vitals = [
      { id: 'startDelayTiming', label: 'Start Dly Timing', value: startDelayTiming, unit: '' },
      { id: 'stopDelayTiming', label: 'Stop Dly Timing', value: stopDelayTiming, unit: '' },
      { id: 'pumpRunFailTiming', label: 'Pmp Rn/Fl Timing', value: pumpRunFailTiming, unit: '' },
      { id: 'lowLevelBubbleTiming', label: 'Low Lvl Bubble Timing', value: lowLevelBubbleTiming, unit: '' },
      { id: 'highLevelBubbleTiming', label: 'High Lvl Bubble Timing', value: highLevelBubbleTiming, unit: '' },
    ];




    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    engineData: getTelemetryForEngineBitPack(state, ownProps.deviceId),
    isEnginePresent: isEnginePresent(state, ownProps.deviceId),
    statusData: getTelemetryForDiversionBitPack(state, ownProps.deviceId),
  };
};

MiniDiversionStatus1Vitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(MiniDiversionStatus1Vitals);
