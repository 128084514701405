// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Amplify
import { Auth } from 'aws-amplify';

// Routing
import { navigate } from '_routes/RootNavigation';
import { getQueryStringParameters } from '_util';

// Redux
import { connect } from 'react-redux';
import { INVITE_CODE_KEY } from '_redux/user/utils';

// Components
import
  AccessControl,
  { ADMIN, MANUFACTURER, CREW_LEADER, CREW_MEMBER, DEALER, OWNER }
from '_components/auth/AccessControl.component';
import AppFooter from "_components/common/AppFooter.component";
import InviteModalContent from '_components/common/InviteModalContent.component';
import Modal from '_components/common/Modal.component';
import Notifications from '_components/Notifications.component';

// UI Framework
import {
  Spinner,
  Text,
  View,
} from 'native-base';
import {
  AsyncStorage,
  Image,
  Linking,
  TouchableOpacity,
} from 'react-native';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_pages/Home.style';

/**
 * NavItem is the component on the Home Page that links
 * to other screens. These are the clickable links left
 * of the notifications.
 */
class NavItem extends Component {
  /**
   * Constructor
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this._navigate = this._navigate.bind(this);
  }

  /**
   * Will navigate the user to another screen in the application based
   * on the route that was supplied as a prop to this component.
   */
  _navigate() {
    const { route } = this.props;

    navigate(route);
  }

  /**
   * Component Render
   */
  render() {
    const { route, icon } = this.props;

    return (
      <TouchableOpacity onPress={this._navigate}>
        <View style={[styles.navItem, global.dropShadow]}>
          <View style={[styles.navItemIcon]}>
            { icon }
          </View>
          <Text style={[styles.navItemText]}>{ route }</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

NavItem.propTypes = {
  route: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
};

/**
 * Home Page.
 */
class Home extends Component {

  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      showInviteModal: false, // true to show the invite modal (found an invite code).
    };

    this._signOut = this._signOut.bind(this);
    this._openDrawer = this._openDrawer.bind(this);

    this._showInviteModal = this._showInviteModal.bind(this);
    this._hideInviteModal = this._hideInviteModal.bind(this);
  }

  /**
   * Check if there is an invite code, if so, we need to show the join modal
   * and query the backend to check for possible error state (e.g. user is
   * already a part of a crew or they are an owner with devices.)
   *
   * @param {*} prevProps
   */
  async componentDidMount() {
    // Get the URL, parse the query string parameter(s), and check local storage
    // for an invite code.
    const initialUrl = await Linking.getInitialURL();
    const queryStringParameters = getQueryStringParameters(initialUrl);
    const storedInviteCode = await AsyncStorage.getItem(INVITE_CODE_KEY);

    // Case where there is an invite code as a query string parameter. First, save
    // it to local storage so it will persist in a more permanent location for
    // Google/Facebook users, then show the invite modal.
    // ****Commented out going to InviteModalContent. InviteModalContent is
    // ****navigated to from the CustomConfirmSignUp component.
    // ****Now the Query string is removed from the url if clicks on a sign up link
    // ****while already signed in.
    if (queryStringParameters.inviteCode) {
      // await AsyncStorage.setItem(INVITE_CODE_KEY, queryStringParameters.inviteCode);
      // this._showInviteModal();
      await AsyncStorage.removeItem(INVITE_CODE_KEY);
      await Linking.openURL('/');
    // If we don't have an invite code as a query string parameter, but we do have one
    // in local storage, that means the user has logged into the application with a
    // 3rd party identity (Google / Facebook).
    }
    // else if (storedInviteCode) {
    //   this._showInviteModal();
    // }
  }

  /**
   * Shows the invite modal.
   */
  _showInviteModal() {
    this.setState({
      showInviteModal: true,
    });
  }

  /**
   * Hides the invite modal.
   */
  _hideInviteModal() {
    this.setState({
      showInviteModal: false,
    });
  }

  /**
   * Sign the user out of the app. Should route them to the
   * sign in / sign up screen.
   */
  _signOut() {
    Auth.signOut();
  }

  /**
   * Open up the side drawer.
   */
  _openDrawer() {
    this.props.navigation.openDrawer();
  }

  /**
   * Component Render
   */
  render() {
    const {
      showInviteModal,
    } = this.state;

    const {
      isFetchingUserInfo,
      userInformation
    } = this.props;
    console.log("enable "+userInformation.is_subscribed);
    return (
      <View style={[styles.home]}>
        {
          isFetchingUserInfo ?
           <Spinner color={'red'} />
           :
          <View style={[styles.mainContent]}>
            {/* Navigation */}
            <View style={[styles.navigationContainer]}>

              <AccessControl allowedGroups={[ADMIN, MANUFACTURER, DEALER]}>
                <NavItem
                  route={'Accounts'}
                  icon={ <Image style={[{ width: 40.03, height: 40.03 }]} source={require('_assets/images/accounts-icon-off.png')} /> }
                />
              </AccessControl>

              <AccessControl allowedGroups={[OWNER, CREW_LEADER, CREW_MEMBER]}>
                <NavItem
                  route={'Dashboard'}
                  icon={ <Image style={[{ width: 40.03, height: 40.03 }]} source={require('_assets/images/dashboard-icon-off.png')} /> }
                />
              </AccessControl>

              <AccessControl allowedGroups={[OWNER, CREW_LEADER, CREW_MEMBER]}>
                <NavItem
                  route={'Assignments'}
                  icon={ <Image style={[{ width: 33, height: 43 }]} source={require('_assets/images/jobs-icon-off.png')} /> }
                />
              </AccessControl>

              <AccessControl allowedGroups={[OWNER]}>
                <NavItem
                  route={'Crew Members'}
                  icon={ <Image style={[{ width: 39, height: 37 }]} source={require('_assets/images/crew-members-icon-off.png')} /> }
                />
              </AccessControl>

              <AccessControl allowedGroups={[OWNER]}>
                <NavItem
                  route={'Stations'}
                  icon={ <Image style={[{ width: 42, height: 42 }]} source={require('_assets/images/devices-icon-off.png')} /> }
                />
              </AccessControl>
              {/* <AccessControl allowedGroups={[ADMIN, MANUFACTURER, OWNER, CREW_LEADER, CREW_MEMBER]}>
                <NavItem
                  route={'Sites'}
                  icon={ <Image style={[{ width: 40, height: 36 }]} source={require('_assets/images/site-icon-off.png')} /> }
                />
              </AccessControl> */}
              {/* <AccessControl allowedGroups={[ADMIN, MANUFACTURER, DEALER, OWNER, CREW_LEADER]}>
                <NavItem
                  route={'Feedback'}
                  icon={ <Image style={[{ width: 40, height: 36 }]} source={require('_assets/images/feedback-icon-off.png')} /> }
                />
              </AccessControl> */}

            </View>

            {/* Notifications */}
            <View style={[styles.notificationsContainer]}>
              <Notifications />
            </View>
          </View>
        }

        {/* Invite Modal */}
        {/* <View>
          <Modal
            isVisible={showInviteModal}
            hideTitle={true}
            hideExitIcon={true}
            content={showInviteModal ? <InviteModalContent /> : null}
          />
        </View> */}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingUserInfo: state.user.isFetchingUserInfo,
    userInformation : state.user.userInfo,
  };
};

Home.propTypes = {
  navigation: PropTypes.any.isRequired,

  isFetchingUserInfo: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(Home);


