// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonGroup from '_components/device_card/ButtonGroup.component';
import EngineState from '_components/device_card/EngineState.component';
import Indicator from '_components/device_card/Indicator.component';
import PressureControl from '_components/device_card/PressureControl.component';
import SpeedControl from '_components/device_card/SpeedControl.component';
import TelemetryValue from '_components/device_card/TelemetryValue.component';
import CentralVitals from '_components/device_card/CentralVitals.component';
import Valves from '_components/device_card/Valves.component';

// Redux
import { connect } from 'react-redux';
import { executeCommand } from '_redux/iot/actions';
import { getTelemetryForDevice, isWarningOnForDevice, isAlarmOnForDevice, isEngineRunningForDevice, getWaterLevelForDevice } from '_redux/iot/selectors';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { TELEMETRY_DEFAULT_VALUE } from '_redux/iot/selectors';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Image, TouchableOpacity } from 'react-native';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { LinearGauge } from 'react-canvas-gauges';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';


// Styling
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/CurrentCentral.style';

class CurrentCentral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // waterLevelData: [], // Initialize an empty data array
    };
  }

  componentDidMount() {
    // // Set up an interval to update the data every 3.5 seconds (adjust as needed)
    // this.interval = setInterval(this.updateWaterLevelData, 3500);
  }

  componentWillUnmount() {
    // // Clear the interval when the component is unmounted
    // clearInterval(this.interval);
  }

  // updateWaterLevelData = () => {
  //   const { REMOTE_DATA_TX } = this.props;
  //   const { s_lvl } = REMOTE_DATA_TX;

  //   // Update the data array with the current time and water level
  //   this.setState((prevState) => {
  //     const newWaterLevelData = [
  //       ...prevState.waterLevelData,
  //       {
  //         time: new Date().toLocaleTimeString(),
  //         Water_Level: s_lvl / 10,
  //         compareTime: new Date()
  //       },
  //     ];

  //     const filteredWaterData = newWaterLevelData.filter((item) => {
  //       const itemTime = new Date(item.compareTime);
  //       const oneHourAgo = new Date();
  //       oneHourAgo.setHours(oneHourAgo.getHours() - 4);

  //       const itemTimestamp = itemTime.getTime();
  //       const oneHourAgoTimestamp = oneHourAgo.getTime();

  //       return itemTimestamp > oneHourAgoTimestamp;
  //     });

  //     return {
  //       waterLevelData: filteredWaterData,
  //     };
  //   });
  // };
  convertEpochToLocalTime(epochTimestamp) {
    const date = new Date(epochTimestamp);

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };

    const localTime = date.toLocaleTimeString([], options); // Convert to formatted local time string
    return localTime;
  }


  render() {
    const {
      deviceId,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      engineNumber,
      REMOTE_DATA_TX,
      waterLevelDataNew,
    } = this.props;

    const { waterLevelData: waterLevelDataDeconstructed } = waterLevelDataNew;

    const graphWaterLevelData = Array.isArray(waterLevelDataDeconstructed) ? waterLevelDataDeconstructed : [];


    // const { waterLevelData } = this.state;
    const {
      s_lvl,
      s_lvl_maint,
      s_lvl_dband,
      s_lvl_stop
    } = REMOTE_DATA_TX

    const {
      vitalsMode,
    } = this.state;

    const currentLevel = s_lvl / 10;
    const dataWhileLoading = [
      {
        timeStamp: new Date().toLocaleTimeString(),
        Water_Level: currentLevel,
      },
      // {
      //   name: '',
      //   Current_Water_Level: currentLevel,
      //   Maintain_Level: maintainLevel,
      //   Stop_Level: stopLevel,
      // },
      // {
      //   name: '',
      //   Current_Water_Level: currentLevel,
      //   Maintain_Level: maintainLevel,
      //   Stop_Level: stopLevel,
      // },
      // {
      //   name: '',
      //   Current_Water_Level: currentLevel,
      //   Maintain_Level: maintainLevel,
      //   Stop_Level: stopLevel,
      // },
    ];



    //water level data needs to be divided by 10.
    const stopLevel = s_lvl_stop / 10;
    const maintainLevel = s_lvl_maint / 10;

    const processedData = graphWaterLevelData.map(entry => ({
      ...entry,
      localTime: this.convertEpochToLocalTime(entry.timeStamp),
    }));

    const xAxisTicks = graphWaterLevelData.map((entry, index) => {
      if (index === 0 || index === graphWaterLevelData.length - 1) {
        return entry.time;
      }
      return null; // return null for ticks you want to hide
    });

    return (
      <Grid>
        {/* Left Column */}
        <Col size={100} style={[styles.leftColumn]}>
          {
            <CentralVitals deviceId={deviceId} />
          }
          <View style={{ alignItems: 'center' }}>
            <Text>Station Level Chart (FT)
            </Text>
          </View>
          {graphWaterLevelData.length <= 0 ?
            <ResponsiveContainer height={250} >
              <AreaChart
                width={300}
                height={250}
                data={dataWhileLoading}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
                <XAxis dataKey={"timeStamp"} interval='preserveStartEnd' tick={false} />
                <YAxis type="number" domain={[0, 25]} interval={0} tickCount={7} tick={{ fill: 'white' }} />
                <Tooltip />
                <Area type="monotone" dataKey="Water_Level" stackId="1" stroke="#8BBAEA" fill="#8BBAEA" />
                <ReferenceLine y={stopLevel} stroke="red" label={{ position: 'top', value: 'Stop Lvl.', fill: 'white', fontSize: 14 }} />
                <ReferenceLine y={maintainLevel} stroke="green" label={{ position: 'top', value: 'Maintain Lvl.', fill: 'white', fontSize: 14 }} />
              </AreaChart>
            </ResponsiveContainer> :
            <ResponsiveContainer height={250} >
              <AreaChart
                width={300}
                height={250}
                data={processedData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
                <XAxis dataKey="localTime" interval='preserveStartEnd' ticks={xAxisTicks} />
                <YAxis type="number" domain={[0, 25]} interval={0} tickCount={7} tick={{ fill: 'white' }} />
                <Tooltip />
                <Area type="monotone" dataKey="Water_Level" stackId="1" stroke="#8BBAEA" fill="#8BBAEA" />
                <ReferenceLine y={stopLevel} stroke="red" label={{ position: 'top', value: 'Stop Lvl.', fill: 'white', fontSize: 14 }} />
                <ReferenceLine y={maintainLevel} stroke="green" label={{ position: 'top', value: 'Maintain Lvl.', fill: 'white', fontSize: 14 }} />
              </AreaChart>
            </ResponsiveContainer>
          }
        </Col>

      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    waterLevelDataNew: getWaterLevelForDevice(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
    REMOTE_DATA_TX: getTelemetryForDevice(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
  };
};

CurrentCentral.propTypes = {
  deviceId: PropTypes.string.isRequired,
  executeCommand: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentCentral);
