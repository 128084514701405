// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Routing
import { navigate } from '_routes/RootNavigation';

// Redux
import { connect } from 'react-redux';
import { getDashboardDevices, clearState } from '_redux/dashboard/actions';
import { connectDevice, disconnectDevice, showConnectedEngines, showConnectedCentralControllers, showConnectedDiversionControllers } from '_redux/iot/actions';
import { getUserParentId, getUserCognitoSub, getUserRoleId } from '_redux/user/selectors';

// Components
// import { OWNER, CREW_LEADER, CREW_MEMBER } from '_components/auth/AccessControl.component';
import DashboardFooter from '_components/device_card/DashboardFooter.component';
import DeviceCard from '_components/device_card/DeviceCard.component';
import MiniDeviceCard from '_components/device_card/MiniDeviceCard.component';
import CurrentHydroStat from '_components/device_card/CurrentHydroStat.component';
import MiniCurrentHydroStat from '_components/device_card/MiniCurrentHydroStat.component';

import Modal from '_components/common/Modal.component';


// UI Framework
import { Spinner, Button, Text } from 'native-base';
import { Col, Row, Grid } from 'react-native-easy-grid';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_pages/Dashboard.style';

class Dashboard extends Component {

  /**
   * When the component mounts, query the backend for the dashboard
   * devices.
   */
  constructor(props) {
    super(props);

    this.state = {
      connectedDevices: false,
      showLoadJobDashboardModal: false,
      showMini: false,
      showAllValves: false,
      showAllVitals: true,
      readyToLoadDevices: false,
      onlineVerified: false,
      enginesOnlineState: [],
      centralControllerOnline: false,
      diversionControllerOnline: false,
      showAllPossibleDevices: false,
    };

    const {
      getDashboardDevices,
      lastViewedJobId,
      route,
      userCognitoSub,
    } = this.props;
    const { jobId, deviceIds } = route.params || {};

    // Entering the dashboard from the Account screen.
    if (deviceIds) {
      getDashboardDevices(null, deviceIds, userCognitoSub);
      // Entering the dashboard from the jobs page ("View Job Dashboard")
    } else if (jobId) {
      getDashboardDevices(jobId, null, userCognitoSub);
      // Entering the dashboard after the user has already viewed a job.
    } else if (lastViewedJobId) {
      getDashboardDevices(lastViewedJobId, null, userCognitoSub);
    } else {
      // // Instruct the user to load a dashboard from the Jobs page
      this.state = { ...this.state, ...{ showLoadJobDashboardModal: true } };
    }

    this._closeLoadJobDashboardModal = this._closeLoadJobDashboardModal.bind(this);
  }



  /**
   * When the component unmounts, disconnect the devices
   */
  async componentWillUnmount() {
    const {
      dashboardDevices = [],
      disconnectDevice,
    } = this.props;

    // Disconnect all devices.
    dashboardDevices.forEach((device) => {
      disconnectDevice(device.device_id);
    });
    this.props.clearState();
  }

  /**
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    const { dashboardDevices: nextDevices, devicesFromIotState } = this.props;
    const { connectedDevices, onlineVerified, showAllPossibleDevices } = this.state;

    if (nextDevices.length > 0 && !connectedDevices) {
      nextDevices.forEach((device) => {
        if (!device.is_suspended) {
          this.props.connectDevice(device.device_id);
        }
      });
      setTimeout(() => {
        this.setState({
          showAllPossibleDevices: true,
        });
      }, 10000);
      this.setState({
        connectedDevices: true,
      });
    }

    if (connectedDevices && !onlineVerified) {
      let isItLoadedYet = false;
      let numOfDevices = nextDevices.length;
      let currentDeviceCount = 0;
      for (let i = 0; i < nextDevices.length; i++) {
        const device = nextDevices[i];
        try {
          if (devicesFromIotState[device.device_id].telemetry.REMOTE_DATA_TX !== undefined) {
            currentDeviceCount += 1;
            if (numOfDevices === currentDeviceCount) {
              isItLoadedYet = true;
            }
          }
        } catch (error) {
          break;
        }
      }
      if (isItLoadedYet) {
        nextDevices.forEach((device) => {
          this.props.showConnectedEngines(device.device_id);
          this.props.showConnectedCentralControllers(device.device_id);
          this.props.showConnectedDiversionControllers(device.device_id);
        });
        this.setState({
          readyToLoadDevices: true,
          onlineVerified: true,
        });
      }


      //If one of the devices isn't online, then the ones that are online for a job will show-up online after this 10 second setTimeout,
      //leaving the ones that are offline behind.
      if (showAllPossibleDevices && !onlineVerified) {
        nextDevices.forEach((device) => {
          this.props.showConnectedEngines(device.device_id);
          this.props.showConnectedCentralControllers(device.device_id);
          this.props.showConnectedDiversionControllers(device.device_id);
        });
        this.setState({
          readyToLoadDevices: true,
          onlineVerified: true,
        });
      }



    }
  }

  /**
   *
   */
  //  _showLoadJobDashboardModal() {
  //   this.setState({
  //     showLoadJobDashboardModal: true,
  //   });
  // }

  _closeLoadJobDashboardModal() {
    this.setState({
      showLoadJobDashboardModal: false,
    });
    navigate('Jobs');
  }

  // Callback from Dashboard Footer to activate Mini Cards
  handleCallback = (childData) => {
    this.setState({ showMini: childData })
  }

  // Receive callback from CurrentEngine to DeviceCard to Dashboard
  // Called when we go to valves or vitals mode in local card
  handleHandleCallbackMakeValvesVitalsFalse = () => {
    this.setState({ showAllValves: false });
    this.setState({ showAllVitals: false });

  }

  // Callback from Dashboard Footer to show all valves
  handleDashboardCallbackValvesAll = () => {
    this.setState({ showaAllVitals: false })
    this.setState({ showAllValves: true })
  }

  // Callback from Dashboard Footer to show all vitals
  handleDashboardCallbackVitalsAll = () => {
    this.setState({ showAllValves: false })
    this.setState({ showAllVitals: true })
  }

  render() {

    const showMini = this.state.showMini;
    const showAllValves = this.state.showAllValves;
    const showAllVitals = this.state.showAllVitals;
    const readyToLoadDevices = this.state.readyToLoadDevices;
    const { dashboardDevices, isGettingDashboardDevices, devicesFromIotState } = this.props;
    const { showLoadJobDashboardModal } = this.state;

    const deviceCards = [];
    const deviceIds = [];


    if (showMini === true && readyToLoadDevices === true) {

      for (let i = 0; i < dashboardDevices.length; i += 1) {
        const device = dashboardDevices[i];
        deviceIds.push(device['device_id']);
        const deviceIdForIoT = device['device_id'];

        const { card_settings = {} } = device;

        const { enginesOnline, centralOnline, diversionOnline } = devicesFromIotState[deviceIdForIoT];

        const {
          engine1Online,
          engine2Online,
          engine3Online,
          engine4Online,
          engine5Online,
        } = enginesOnline;

        if (centralOnline) {
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Central"}>
              <MiniDeviceCard deviceId={device['device_id']} central={true} deviceName={device['device_id'] + " Central"} centralDeviceCard={true} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
        }

        let engineVariables = [engine1Online, engine2Online, engine3Online, engine4Online, engine5Online];
        //We may not want the deviceName to be equal to the device_id or have to rely on it
        for (let index = 0; index < engineVariables.length; index++) {
          const element = engineVariables[index];
          const displayNumber = index + 1;
          if (element) {
            deviceCards.push((
              <React.Fragment key={device['device_id'] + index}>
                <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_id'] + " Engine " + displayNumber} engineDeviceCard={true} engineNumber={index} disabled={device['is_suspended'] ? true : false} />
              </React.Fragment>
            ));
          }
        }

        if (diversionOnline) {
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Station Level"}>
              <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} diversionStationCard={true} deviceName={device['device_id'] + " Station Level"} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Misc. Data"}>
              <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} diversionMiscCard={true} deviceName={device['device_id'] + " Misc. Data"} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Flowrates"}>
              <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} diversionFlowrateCard={true} deviceName={device['device_id'] + " Flowrates"} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Status1"}>
              <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_id'] + " Status"} miniDiversionStatus1Card={true} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Status2"}>
              <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_id'] + " Status"} miniDiversionStatus2Card={true} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Alarm & Shutdown"}>
              <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_id'] + " Alarm & Shutdown"} miniDiversionAlarmCard={true} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
        }
      }

    }

    if (showMini === false && readyToLoadDevices === true) {
      for (let i = 0; i < dashboardDevices.length; i += 1) {
        const device = dashboardDevices[i];
        deviceIds.push(device['device_id']);

        const deviceIdForIoT = device['device_id'];

        const { enginesOnline, centralOnline, diversionOnline } = devicesFromIotState[deviceIdForIoT];

        const {
          engine1Online,
          engine2Online,
          engine3Online,
          engine4Online,
          engine5Online,
        } = enginesOnline;

        if (centralOnline) {
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Central"}>
              <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} centralDeviceCard={true} deviceName={device['device_id'] + " Central"} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
        }

        let engineVariables = [engine1Online, engine2Online, engine3Online, engine4Online, engine5Online];

        for (let index = 0; index < engineVariables.length; index++) {
          const element = engineVariables[index];
          const displayNumber = index + 1;
          if (element) {
            deviceCards.push((
              <React.Fragment key={device['device_id'] + index}>
                <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} engineDeviceCard={true} engineNumber={index} deviceName={device['device_id'] + " Engine " + displayNumber} disabled={device['is_suspended'] ? true : false} />
              </React.Fragment>
            ));
          }
        }

        if (diversionOnline) {
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Station Level"}>
              <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} diversionStationCard={true} deviceName={device['device_id'] + " Station Level"} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Misc. Data"}>
              <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} diversionMiscCard={true} deviceName={device['device_id'] + " Misc. Data"} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Flowrates"}>
              <DeviceCard showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} diversionFlowrateCard={true} deviceName={device['device_id'] + " Flowrates"} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Status1"}>
              <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_id'] + " Status"} miniDiversionStatus1Card={true} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Status2"}>
              <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_id'] + " Status"} miniDiversionStatus2Card={true} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
          deviceCards.push((
            <React.Fragment key={device['device_id'] + "Alarm & Shutdown"}>
              <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_id'] + " Alarm & Shutdown"} miniDiversionAlarmCard={true} disabled={device['is_suspended'] ? true : false} />
            </React.Fragment>
          ));
        }

        const { card_settings = {} } = device;

      }

    }

    return (
      <Grid>
        {!readyToLoadDevices ?
          <Row style={[styles.row, styles.dashboard]} size={90}>
            <Col style={styles.loading}>
              <Spinner color="red" />
            </Col>
          </Row>
          :
          <Row style={[styles.row, styles.dashboard]} size={90}>
            {deviceCards}
          </Row>
        }
        <Row size={6}>
          <DashboardFooter parentCallback={this.handleCallback} dashboardCallbackValvesAll={this.handleDashboardCallbackValvesAll} dashboardCallbackVitalsAll={this.handleDashboardCallbackVitalsAll} deviceIds={deviceIds} />
        </Row>
        {showLoadJobDashboardModal &&
          <Modal
            isVisible={showLoadJobDashboardModal}
            title={'NO ASSIGNMENT SELECTED'}
            subTitle={'Please load one of the available assignments into the dashboard by selecting the assignment name and clicking the View Assignment Dashboard button.'}
            hideExitIcon={true}
            onClose={this._closeLoadJobDashboardModal}
            content={showLoadJobDashboardModal &&
              <Button style={[global.confirmationButton, { width: 500, marginBottom: 15 }]} onPress={this._closeLoadJobDashboardModal}>
                <Text style={[global.confirmationButtonText]}>
                  CLOSE
                </Text>
              </Button>}
          />
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardDevices: state.dashboard.devices,
    isGettingDashboardDevices: state.dashboard.isGettingDashboardDevices,
    lastViewedJobId: state.dashboard.lastViewedJobId,
    userCognitoSub: getUserCognitoSub(state),
    userParentId: getUserParentId(state),
    userRoleId: getUserRoleId(state),
    devicesFromIotState: state.iot.devices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectDevice: (deviceId) => dispatch(connectDevice(deviceId)),
    disconnectDevice: (deviceId) => dispatch(disconnectDevice(deviceId)),

    getDashboardDevices: (jobId, deviceIds, cognitoSub) => dispatch(getDashboardDevices(jobId, deviceIds, cognitoSub)),
    clearState: () => dispatch(clearState()),

    showConnectedEngines: (deviceId) => dispatch(showConnectedEngines(deviceId)),
    showConnectedCentralControllers: (deviceId) => dispatch(showConnectedCentralControllers(deviceId)),
    showConnectedDiversionControllers: (deviceId) => dispatch(showConnectedDiversionControllers(deviceId)),

  };
};

Dashboard.propTypes = {
  clearState: PropTypes.func.isRequired,
  dashboardDevices: PropTypes.array.isRequired,

  lastViewedJobId: PropTypes.any,

  userCognitoSub: PropTypes.string,
  userParentId: PropTypes.string,
  userRoleId: PropTypes.string,

  route: PropTypes.any.isRequired,

  getDashboardDevices: PropTypes.func.isRequired,
  isGettingDashboardDevices: PropTypes.bool.isRequired,

  connectDevice: PropTypes.func.isRequired,
  disconnectDevice: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
