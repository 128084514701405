import {
  addCrewMember,
  checkCrewMemberEligibility,
  clearState as clearCrewMemberState,
  leaveAndAddComplete,
  leaveAndAddInProgress,
  checkAndAddComplete as crewMemberCheckAndAddComplete,
  checkAndAddInProgress as crewMemberCheckAndAddInProgress,
  removeCrewMember,
} from '_redux/crewMembers/actions';

import {
  addSystemUser,
  clearState as clearAdminState,
  checkSystemUserEligibility,
  checkAndAddComplete as systemUserCheckAndAddComplete,
  checkAndAddInProgress as systemUserCheckAndAddInProgress,
} from '_redux/admin/actions';

import {
  getInvite,
} from '_redux/user/actions';
import {  } from '../admin/actions';

/**
 * Performs the steps necessary to remove a user from their existing crew, and
 * add them to a new crew based on the invite they received.
 *
 * @param {string} inviteCode invite code
 */
export const leaveAndJoinCrew = (inviteCode) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(clearCrewMemberState());
      await dispatch(leaveAndAddInProgress());
      await dispatch(getInvite(inviteCode));

      const invite = getState().user.invite;
      const userUserName = getState().user.userInfo.username;
      const {
        entity_id: ownerCognitoSub,
        crew_role_type: crewRoleType,
      } = invite;

      await dispatch(removeCrewMember());
      await dispatch(addCrewMember(ownerCognitoSub, null, crewRoleType === 'Crew Leader', userUserName));
    } catch (e) {
      console.log(e);
    } finally {
      await dispatch(leaveAndAddComplete());
    }
  };
};

/**
 * Performs the steps necessary to check if a user is eligible to join a
 * new crew. If the user is eligible, add them to the crew automatically.
 *
 * @param {string} inviteCode invite code.
 */
export const checkEligibilityAndAdd = (inviteCode) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(clearCrewMemberState());
      await dispatch(crewMemberCheckAndAddInProgress());
      await dispatch(checkCrewMemberEligibility());

      const error = getState().crewMembers.crewMemberEligibilityError;

      // If the crew member is eligible, the error code will be null.
      if (!error) {

        let invite = getState().user.invite;
        if (!invite) {
          await dispatch(getInvite(inviteCode));
        }
        invite = getState().user.invite;

        const {
          entity_id: ownerCognitoSub,
          crew_role_type: crewRoleType,
        } = invite;

        await dispatch(addCrewMember(ownerCognitoSub, null, crewRoleType === 'Crew Leader', inviteCode));
      }

    } catch (e) {
      console.log(e);
    } finally {
      await dispatch(crewMemberCheckAndAddComplete());
    }
  };
};

/**
 *
 * @param {*} inviteCode
 */
export const checkSystemUserEligibilityAndAdd = (inviteCode) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(clearAdminState());
      await dispatch(systemUserCheckAndAddInProgress());
      await dispatch(checkSystemUserEligibility(null, inviteCode));

      const error = getState().admin.systemUserEligibilityError;

      if (!error) {
        let invite = getState().user.invite;
        if (!invite) {
          await dispatch(getInvite(inviteCode));
        }
        invite = getState().user.invite;

        const {
          entity_id: groupId,
          is_owner_invite: isOwnerInvite,
        } = invite;

        await dispatch(addSystemUser(groupId, null, isOwnerInvite, inviteCode));
      }

    } catch (e) {
      console.log(e);
    } finally {
      await dispatch(systemUserCheckAndAddComplete());
    }
  };
};

/**
 *
 * @param {*} inviteCode
 */
export const startInviteFlow = (inviteCode) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(clearCrewMemberState());
      await dispatch(clearAdminState());

      await dispatch(getInvite(inviteCode));

      const invite = getState().user.invite;

      if (invite.is_crew_invite) {
        dispatch(checkEligibilityAndAdd(inviteCode));
      } else {
        dispatch(checkSystemUserEligibilityAndAdd(inviteCode));
      }

    } catch (e) {
      console.log(e);
    } finally {
      // await dispatch(crewMemberCheckAndAddComplete());
      // await dispatch(systemUserCheckAndAddComplete());
    }
  };
};
