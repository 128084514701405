// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonGroup from '_components/device_card/ButtonGroup.component';
import EngineState from '_components/device_card/EngineState.component';
import Indicator from '_components/device_card/Indicator.component';
import PressureControl from '_components/device_card/PressureControl.component';
import SpeedControl from '_components/device_card/SpeedControl.component';
import TelemetryValue from '_components/device_card/TelemetryValue.component';
import DiversionMiscVitals from '_components/device_card/DiversionMiscVitals.component';
import Valves from '_components/device_card/Valves.component';

// Redux
import { connect } from 'react-redux';
import { executeCommand } from '_redux/iot/actions';
import { getAlisoViejoTelemetryForDevice, isWarningOnForDevice, isAlarmOnForDevice, isEngineRunningForDevice, getWaterLevelForDevice } from '_redux/iot/selectors';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { TELEMETRY_DEFAULT_VALUE } from '_redux/iot/selectors';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Image, TouchableOpacity } from 'react-native';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { LinearGauge } from 'react-canvas-gauges';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';


// Styling
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/CurrentDiversionMisc.style';

class CurrentDiversionMisc extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }




  render() {
    const {
      deviceId,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      REMOTE_DATA_TX,

    } = this.props;


    const {
      MON_P1_run_hours,
      TUE_P1_run_hours,
      WED_P1_run_hours,
      THR_P1_run_hours,
      FRI_P1_run_hours,
      SAT_P1_run_hours,
      SUN_P1_run_hours,
      MON_backwash_run_hours,
      TUE_backwash_run_hours,
      WED_backwash_run_hours,
      THR_backwash_run_hours,
      FRI_backwash_run_hours,
      SAT_backwash_run_hours,
      SUN_backwash_run_hours,
      MON_sewer_flow,
      TUE_sewer_flow,
      WED_sewer_flow,
      THR_sewer_flow,
      FRI_sewer_flow,
      SAT_sewer_flow,
      SUN_sewer_flow,
      MON_treated_flow,
      TUE_treated_flow,
      WED_treated_flow,
      THR_treated_flow,
      FRI_treated_flow,
      SAT_treated_flow,
      SUN_treated_flow,
    } = REMOTE_DATA_TX



    const {
      vitalsMode,
    } = this.state;


    // Motor and Backwash data need to be divided by 10 to show accurate hours. Treated Flow
    // and Sewer Flow data need to be multiplied by 10 to show accurate gallon values.
    return (
      <Grid>
        {/* Header Row */}
        <Row style={styles.headerRow}>
          <Col style={styles.headerCell} size={15}>
            <Text style={styles.headerText}></Text>
          </Col>
          <Col style={styles.headerCell} size={15}>
            <Text style={styles.headerText}>Motor (hours)</Text>
          </Col>
          <Col style={styles.headerCell} size={15}>
            <Text style={styles.headerText}>Backwash (hours)</Text>
          </Col>
          <Col style={styles.headerCell} size={15}>
            <Text style={styles.headerText}>Treated Flow (Gallons)</Text>
          </Col>
          <Col style={styles.headerCell} size={15}>
            <Text style={styles.headerText}>Sewer Flow (Gallons)</Text>
          </Col>
        </Row>

        {/* Data Rows */}
        {['Mon.', 'Tues.', 'Wed.', 'Thur.', 'Fri.', 'Sat.', 'Sun.'].map(day => (
          <Row key={day} style={styles.dataRow}>
            <Col style={styles.cell} size={15}>
              <Text style={styles.cellText}>{day}</Text>
            </Col>
            <Col style={styles.cell} size={15}>
              {/* Motor Data Input */}
              {day === 'Mon.' && <Text style={styles.cellText}>{MON_P1_run_hours / 10}</Text>}
              {day === 'Tues.' && <Text style={styles.cellText}>{TUE_P1_run_hours / 10}</Text>}
              {day === 'Wed.' && <Text style={styles.cellText}>{WED_P1_run_hours / 10}</Text>}
              {day === 'Thur.' && <Text style={styles.cellText}>{THR_P1_run_hours / 10}</Text>}
              {day === 'Fri.' && <Text style={styles.cellText}>{FRI_P1_run_hours / 10}</Text>}
              {day === 'Sat.' && <Text style={styles.cellText}>{SAT_P1_run_hours / 10}</Text>}
              {day === 'Sun.' && <Text style={styles.cellText}>{SUN_P1_run_hours / 10}</Text>}
            </Col>
            <Col style={styles.cell} size={15}>
              {/* Backwash Data Input */}
              {day === 'Mon.' && <Text style={styles.cellText}>{MON_backwash_run_hours / 10}</Text>}
              {day === 'Tues.' && <Text style={styles.cellText}>{TUE_backwash_run_hours / 10}</Text>}
              {day === 'Wed.' && <Text style={styles.cellText}>{WED_backwash_run_hours / 10}</Text>}
              {day === 'Thur.' && <Text style={styles.cellText}>{THR_backwash_run_hours / 10}</Text>}
              {day === 'Fri.' && <Text style={styles.cellText}>{FRI_backwash_run_hours / 10}</Text>}
              {day === 'Sat.' && <Text style={styles.cellText}>{SAT_backwash_run_hours / 10}</Text>}
              {day === 'Sun.' && <Text style={styles.cellText}>{SUN_backwash_run_hours / 10}</Text>}
            </Col>
            <Col style={styles.cell} size={15}>
              {/* Treated Flow Data Input */}
              {day === 'Mon.' && <Text style={styles.cellText}>{MON_treated_flow * 10}</Text>}
              {day === 'Tues.' && <Text style={styles.cellText}>{TUE_treated_flow * 10}</Text>}
              {day === 'Wed.' && <Text style={styles.cellText}>{WED_treated_flow * 10}</Text>}
              {day === 'Thur.' && <Text style={styles.cellText}>{THR_treated_flow * 10}</Text>}
              {day === 'Fri.' && <Text style={styles.cellText}>{FRI_treated_flow * 10}</Text>}
              {day === 'Sat.' && <Text style={styles.cellText}>{SAT_treated_flow * 10}</Text>}
              {day === 'Sun.' && <Text style={styles.cellText}>{SUN_treated_flow * 10}</Text>}
            </Col>
            <Col style={styles.cell} size={15}>
              {/* Sewer Flow Data Input */}
              {day === 'Mon.' && <Text style={styles.cellText}>{MON_sewer_flow * 10}</Text>}
              {day === 'Tues.' && <Text style={styles.cellText}>{TUE_sewer_flow * 10}</Text>}
              {day === 'Wed.' && <Text style={styles.cellText}>{WED_sewer_flow * 10}</Text>}
              {day === 'Thur.' && <Text style={styles.cellText}>{THR_sewer_flow * 10}</Text>}
              {day === 'Fri.' && <Text style={styles.cellText}>{FRI_sewer_flow * 10}</Text>}
              {day === 'Sat.' && <Text style={styles.cellText}>{SAT_sewer_flow * 10}</Text>}
              {day === 'Sun.' && <Text style={styles.cellText}>{SUN_sewer_flow * 10}</Text>}
            </Col>
          </Row>
        ))}
        <Row>
          <Text> Panel Info.</Text>

        </Row>
        {
          <DiversionMiscVitals deviceId={deviceId} />
        }

      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    waterLevelDataNew: getWaterLevelForDevice(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
  };
};

CurrentDiversionMisc.propTypes = {
  deviceId: PropTypes.string.isRequired,
  executeCommand: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDiversionMisc);
