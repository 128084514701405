// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { saveUserDeviceCardSettings,} from '_redux/user/actions';
import { updateUserDeviceCardSettings,} from '_redux/dashboard/actions';
import { setSetPoints } from '_redux/iot/actions';

// Selectors
import { getIoTDeviceById } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById,} from '_redux/dashboard/selectors';
import { isSavingUserDeviceCardSettings,} from '_redux/user/selectors';

// Util
import { merge } from '_util';

// Components
import ButtonGroup from '_components/device_card/ButtonGroup.component';
import AlarmSettings from '_components/device_settings/AlarmSettings.component';
import HydroSettings from '_components/device_settings/HydroSettings.component';
import ThrottleSettings from '_components/device_settings/ThrottleSettings.component';
import ValveSettings from '_components/device_settings/ValveSettings.component';
import VitalsSettings from '_components/device_settings/VitalsSettings.component';
import WidgetSettings from '_components/device_settings/WidgetSettings.component';

// UI Framework
import { Button, Spinner, Text, View,} from 'native-base';
import { Grid, Col } from 'react-native-easy-grid';

// Style
import { styles } from '_components/device_settings/DeviceSettings.style';

class DeviceSettings extends Component {

  // constuctor of DeviceSettings.
  // we set initial values and state of our component here.
  constructor(props) {
    super(props);

    //we set our initial state
    this.state = {
      mode: 'THROTTLE',
      alteredSettings: {},
      alteredSetPoints: {},
      errorText: '',
      errorTextEnable: false,
    };


    //binding functions
    this._setMode = this._setMode.bind(this);
    this._settingsChanged = this._settingsChanged.bind(this);
    this._setPointsChanged = this._setPointsChanged.bind(this);
    this._saveSettings = this._saveSettings.bind(this);

    this._isDirty = this._isDirty.bind(this);
    this._setError = this._setError.bind(this);
  }

  _setMode(mode) {
    this.setState({
      mode,
    });
  }

  _settingsChanged(settings) {
    const { alteredSettings } = this.state;
    this.setState({
      alteredSettings: merge(alteredSettings, settings),
    });
  }

  _setPointsChanged(setPoints) {
    const { alteredSetPoints } = this.state;
    this.setState({
      alteredSetPoints: merge(alteredSetPoints, setPoints),
    });
  }

  _saveSettings() {
    const { alteredSettings, alteredSetPoints } = this.state;
    const {
      deviceId,
      deviceSettings,
      saveUserDeviceCardSettings,
      setSetPoints,
      updateUserDeviceCardSettings,
    } = this.props;

    const settingsToSave = {...deviceSettings, ...alteredSettings};

    console.log(settingsToSave);

    saveUserDeviceCardSettings(deviceId, settingsToSave);
    updateUserDeviceCardSettings(deviceId, settingsToSave);

    setSetPoints(deviceId, alteredSetPoints);

    this.setState({
      alteredSettings: {},
      alteredSetPoints: {},
    });
  }

  _setError(enable, text) {
    this.setState({ errorText: text, errorTextEnable: enable })
  }

  //double check what's going on here

  _isDirty() {
    const { alteredSetPoints, alteredSettings } = this.state;

    const dirtySetPoints = Object.keys(alteredSetPoints).length > 0;
    const dirtySettings = Object.keys(alteredSettings).length > 0;

    return dirtySetPoints || dirtySettings;
  }

  render() {
    const { deviceId, device, isSavingUserDeviceCardSettings } = this.props;
    const { mode, errorText, errorTextEnable } = this.state;

    const dirty = this._isDirty();

    const { telemetry } = device || {};

    const {
      espn,
      efmi,
    } = telemetry || {};

    let settingsScreen;

    switch (mode) {
      case 'THROTTLE':
        settingsScreen = (<ThrottleSettings deviceId={deviceId} settingsChanged={this._setPointsChanged} />);
        break;
      case 'WIDGET':
        settingsScreen = (<WidgetSettings deviceId={deviceId} settingsChanged={this._settingsChanged} />);
        break;
      case 'VALVES':
        settingsScreen = (<ValveSettings deviceId={deviceId} settingsChanged={this._settingsChanged} />);
        break;
      case 'VITALS':
        settingsScreen = (<VitalsSettings deviceId={deviceId} settingsChanged={this._settingsChanged} setError={this._setError}/>);
        break;
      case 'HYDRO':
        settingsScreen = (<HydroSettings deviceId={deviceId} settingsChanged={this._settingsChanged} />);
        break;
      case 'ALARM':
        settingsScreen = (<AlarmSettings espn={ '' } efmi={ '' } deviceId={deviceId} />);
        break;
    }

    let saveButton = null;

    if (mode !== 'ALARM') {
      saveButton = (
        !isSavingUserDeviceCardSettings ?
          <Button disabled={!dirty || errorTextEnable} onPress={this._saveSettings} style={[styles.saveButton]}>
            <Text style={[styles.saveButtonText, dirty ? styles.saveButtonTextEnabled : styles.saveButtonTextDisabled]}>
              APPLY AND SAVE
              </Text>
          </Button>
          :
          <Spinner style={[styles.savingSpinner]} color={'red'} />
      );
    }

    return (
      <View style={[styles.deviceSettings]}>
        <ButtonGroup width={312} fontSize={10} buttons={[
          { onPressCallback: () => this._setMode('THROTTLE'), name: 'THROTTLE', on: true},
          { onPressCallback: () => this._setMode('WIDGET'), name: 'WIDGET'},
          { onPressCallback: () => this._setMode('VALVES'), name: 'VALVES'},
          { onPressCallback: () => this._setMode('VITALS'), name: 'VITALS'},
          { onPressCallback: () => this._setMode('HYDRO'), name: 'HYDRO'},
          { onPressCallback: () => this._setMode('ALARM'), name: 'ALARM'},
        ]} />
        <Text style={[styles.errorText]}>{errorTextEnable ? errorText : ' '}</Text>
        <Grid>
          <Col style={[styles.deviceSettingsContainer]}>
            <View style={[styles.deviceSettingsContent]}>
              { settingsScreen }
            </View>
              { saveButton }
          </Col>
        </Grid>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    device: getIoTDeviceById(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isSavingUserDeviceCardSettings: isSavingUserDeviceCardSettings(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserDeviceCardSettings: (deviceId, settings) => dispatch(saveUserDeviceCardSettings(deviceId, settings)),
    updateUserDeviceCardSettings: (deviceId, settings) => dispatch(updateUserDeviceCardSettings(deviceId, settings)),
    setSetPoints: (deviceId, setPoints) => dispatch(setSetPoints(deviceId, setPoints)),
  };
};

DeviceSettings.propTypes = {
  deviceId: PropTypes.string.isRequired,
  device: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isSavingUserDeviceCardSettings: PropTypes.bool.isRequired,
  saveUserDeviceCardSettings: PropTypes.func.isRequired,
  updateUserDeviceCardSettings: PropTypes.func.isRequired,
  setSetPoints: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettings);
