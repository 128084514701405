// Redux
import { makeActionCreator } from '_redux/rootActions';

// Amplify
import { API, PubSub } from 'aws-amplify';
import { DEVICES_API_ID } from '_config/api-constants';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import amplifyConfig from '_config/_aws-exports.js';

// Utils / Selectors
import { getSubscriptionTopics, getCommandTopic } from '_redux/iot/utils';
import { getIoTDeviceById, iotDeviceIsConnected, isReconnecting } from '_redux/iot/selectors';
import { getUserDevices } from '_redux/user/actions';
import { getUserDeviceById } from '_redux/dashboard/selectors';
import { isEnginePresent, isCentralPresent, isDiversionPresent } from '_redux/iot/selectors';

import { getUserCognitoSub } from '_redux/user/utils';

const shortid = require('shortid');

// Types
import {
  CLEAR_STATE,
  CLEAR_DEVICE_TELEMETRY,

  CONNECT_DEVICE_REQUEST,
  CONNECT_DEVICE_FAILURE,

  DISCONNECT_DEVICE_REQUEST,
  DISCONNECT_DEVICE_SUCCESS,
  DISCONNECT_DEVICE_FAILURE,

  START_RECONNECT_DEVICE_REQUEST,
  START_RECONNECT_DEVICE_SUCCESS,
  START_RECONNECT_DEVICE_FAILURE,

  SET_RECONNECT_DEVICE_COMPLETE,

  SET_DEVICE_CONNECTED,
  SET_DEVICE_DISCONNECTED,

  GET_SET_POINTS_REQUEST,
  GET_SET_POINTS_FAILURE,

  SET_SET_POINTS_REQUEST,
  SET_SET_POINTS_FAILURE,

  UPDATE_SET_POINTS,

  EXECUTE_COMMAND_REQUEST,
  EXECUTE_COMMAND_FAILURE,

  UPDATE_DEVICE_NAME_REQUEST,
  UPDATE_DEVICE_NAME_SUCCESS,
  UPDATE_DEVICE_NAME_FAILURE,

  UPDATE_COMMAND_LOG,

  UPDATE_DEVICE_SUBSCRIPTION,

  UPDATE_TELEMETRY_DATA,
  UPDATE_WATER_LEVEL_DATA,

  CURRENT_ENGINES_ONLINE_REQUEST,
  CURRENT_ENGINES_ONLINE_SUCCESS,
  CURRENT_ENGINES_ONLINE_FAILURE,

  CURRENT_CENTRAL_ONLINE_REQUEST,
  CURRENT_CENTRAL_ONLINE_SUCCESS,
  CURRENT_CENTRAL_ONLINE_FAILURE,

  CURRENT_DIVERSION_ONLINE_REQUEST,
  CURRENT_DIVERSION_ONLINE_SUCCESS,
  CURRENT_DIVERSION_ONLINE_FAILURE,

} from '_redux/iot/types';

export const clearState = makeActionCreator(CLEAR_STATE);
export const clearDeviceTelemetry = makeActionCreator(CLEAR_DEVICE_TELEMETRY, 'deviceId');

// Keep track of whether we have already configured the
// Amplify PubSub IoT Provider.
let iotProviderConfigured = false;

/**
 * Configures the Amplify PubSub Module with the necessary
 * configuration details. We can't do this up front like with
 * the Authentication module because we need to know what the
 * Cognito ID is of the user that's currently logged in to use
 * as the MQTT client id.
 */
const configureAWSIoTProvider = (cognitoSub) => {
  return PubSub.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: amplifyConfig.PubSub.aws_pubsub_region,
    aws_pubsub_endpoint: amplifyConfig.PubSub.aws_pubsub_endpoint,
    clientId: `${cognitoSub}-${shortid.generate()}`, // allows for multiple devices connected at the same time for the same user.
    // clientId: cognitoSub, // disallows multiple devices connected at the same time for the same user.
  }))
    .then(() => {
      iotProviderConfigured = true;
    });
};

/**
 * Makes a call to the REST API to get the details about a
 * single device.
 *
 * @param {string} deviceId the device id.
 */
const getDeviceDetails = (deviceId) => {
  return API.get(DEVICES_API_ID, `/devices/${encodeURIComponent(deviceId)}`);
};

/**
 * Connect Device Action
 *
 * This will configure the AWS IoT PubSub Provider if it hasn't already
 * been configured, and then it will attempt to establish subscriptions
 * to all of the available topics for the current user for the device. E.g.
 * telemetry topics, command response topics, and shadow topics.
 *
 * @param {string} deviceId the unique id of the device to connect to.
 */
export const connectDevice = (deviceId) => {
  return async (dispatch, getState) => {

    if (iotDeviceIsConnected(getState(), deviceId)) {
      console.log('DEVICE IS ALREADY CONNECTED');
      return;
    }

    dispatch(connectDeviceRequest(deviceId));

    try {
      // Get the logged in user's cognito id.
      const cognitoSub = await getUserCognitoSub();



      // Always check to see if we've configured the AWSIoTProvider.
      if (!iotProviderConfigured) {
        await configureAWSIoTProvider(cognitoSub);
      }

      // Get the details for this single device, e.g. level id information, and
      // get an array of all topics to subscribe to.
      const deviceDetails = (await getDeviceDetails(deviceId))[0];
      const deviceSubscriptions = getSubscriptionTopics(deviceDetails, cognitoSub);

      // used in handleMessage to disconnect
      let disconnectTimer;

      // Handler function to take care of receiving data from any one of
      // the topics we've subscribed to.
      const handleMessage = (data) => {
        const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];

        console.log('received message:', data.value);

        if (topic.indexOf('setPoints/device_id') != null) {

          // we clear our disconnectTimer when we read a telemetry reading
          clearTimeout(disconnectTimer);

          // disconnect if we don't recieve a telemetry reading within 10 seconds
          disconnectTimer = setTimeout(() => {
            dispatch(setDeviceDisconnected(deviceId));
          }, 10000);

          if(topic.indexOf('water_level') !== -1){
            dispatch(updateWaterLevelData(deviceId, data.value));
          }else{
            dispatch(updateTelemetryData(deviceId, data.value));
          }

          dispatch(setDeviceConnected(deviceId));
        } else if (topic.indexOf('shadow') !== -1 && (!topic.endsWith('update') && !topic.endsWith('update/accepted') && !topic.endsWith('update/delta') && !topic.endsWith('rejected'))) {
          dispatch(updateSetPoints(deviceId, data.value));
        } else if (topic.indexOf('events/presence/disconnected') !== -1) {
          dispatch(setDeviceDisconnected(deviceId));
          dispatch(clearDeviceTelemetry(deviceId));
        } else {
          console.log(deviceId, data.value);
          dispatch(updateCommandLog(deviceId, data.value));
        }
      };

      // Handler function to take care of an error with our subscription.
      const handleError = (error) => {
        console.error(error);
        dispatch(connectDeviceFailure(deviceId));
        dispatch(reconnectDevice(deviceId));
      };

      // Handler function to take care of our subscriptions closing.
      const handleClose = () => {
        dispatch(connectDeviceFailure(deviceId));
      };

      // Establish the subscriptions.
      const deviceSubscription = PubSub.subscribe(deviceSubscriptions).subscribe({
        // Invoked when new data comes in on a topic we've subscribed to.
        next: handleMessage,
        // Invoked when there is an error with the MQTT connection to IoT Core.
        error: handleError,
        // Invoked when the connection with IoT Core is closed.
        close: handleClose,
      });

      // Update the device in the state to include the subscription.
      dispatch(updateDeviceSubscription(deviceId, deviceSubscription, deviceSubscriptions));

      // Request the set points from the device
      setTimeout(() => {
        dispatch(getSetPoints(deviceId));
      }, 2000);

      // If reconnecting, then indicate process is complete
      if (isReconnecting(getState(), deviceId) === true) {
        dispatch(setReconnectDeviceComplete(deviceId));
      }

    } catch (e) {
      console.log(e);
      dispatch(connectDeviceFailure(deviceId));

      // If reconnecting, the connection failed so retry again
      if (isReconnecting(getState(), deviceId) === true) {
        dispatch(reconnectDevice(deviceId));
      }
    }
  };
};
export const connectDeviceRequest = makeActionCreator(CONNECT_DEVICE_REQUEST, 'deviceId');
export const connectDeviceFailure = makeActionCreator(CONNECT_DEVICE_FAILURE, 'deviceId');
export const updateSetPoints = makeActionCreator(UPDATE_SET_POINTS, 'deviceId', 'setPoints');
export const updateTelemetryData = makeActionCreator(UPDATE_TELEMETRY_DATA, 'deviceId', 'telemetry');
export const updateWaterLevelData = makeActionCreator(UPDATE_WATER_LEVEL_DATA, 'deviceId', 'waterLevelData');
export const updateCommandLog = makeActionCreator(UPDATE_COMMAND_LOG, 'deviceId', 'logEntry');
export const updateDeviceSubscription = makeActionCreator(UPDATE_DEVICE_SUBSCRIPTION, 'deviceId', 'subscription', 'subscriptionList');
export const setDeviceConnected = makeActionCreator(SET_DEVICE_CONNECTED, 'deviceId');
export const setDeviceDisconnected = makeActionCreator(SET_DEVICE_DISCONNECTED, 'deviceId');
export const setReconnectDeviceComplete = makeActionCreator(SET_RECONNECT_DEVICE_COMPLETE, 'deviceId');

/**
 * Disconnect Device Action.
 *
 * This will use the subscription object for the device in the
 * state and use the unsubscribe() method to stop receiving
 * messages.
 *
 * @param {string} deviceId the id of the device to unsubscribe from all topics.
 */
export const disconnectDevice = (deviceId) => {
  return (dispatch, getState) => {
    dispatch(disconnectDeviceRequest(deviceId));

    try {
      // Grab the device information from the iot slice of the state.
      const device = getIoTDeviceById(getState(), deviceId);

      // Clear any reconnect timer
      clearTimeout(device.reconnectTimerId || 0);
      dispatch(setReconnectDeviceComplete(deviceId));

      // Unsubscribe from all of the topics.
      device.subscription.unsubscribe();

      // Success
      dispatch(disconnectDeviceSuccess(deviceId));
      dispatch(setDeviceDisconnected(deviceId));
      dispatch(clearDeviceTelemetry(deviceId));

    } catch (error) {
      // Failure
      dispatch(disconnectDeviceFailure(deviceId));
    }
  };
};
export const disconnectDeviceRequest = makeActionCreator(DISCONNECT_DEVICE_REQUEST, 'deviceId');
export const disconnectDeviceSuccess = makeActionCreator(DISCONNECT_DEVICE_SUCCESS, 'deviceId');
export const disconnectDeviceFailure = makeActionCreator(DISCONNECT_DEVICE_FAILURE, 'deviceId');

/**
 * Start Reconnect Device Action
 * This will start a reconnect process
 *
 * @param {string} deviceId the id of the device to reconnect
 *
 */

export const reconnectDevice = (deviceId) => {
  return (dispatch, getState) => {
    dispatch(startReconnectDeviceRequest(deviceId));

    try {
      // Start an timer to try to reconnect at 2 seconds
      // If the reconnection is unsuccessful the timer will restarted
      // in the connectDevice function

      const timerId = setTimeout(() => {
        dispatch(connectDevice(deviceId));
      }, 2000);

      dispatch(startReconnectDeviceSuccess(deviceId, timerId));

    } catch (error) {
      // Failure
      dispatch(startReconnectDeviceFailure(deviceId));
    }
  };
};

export const startReconnectDeviceRequest = makeActionCreator(START_RECONNECT_DEVICE_REQUEST, 'deviceId');
export const startReconnectDeviceSuccess = makeActionCreator(START_RECONNECT_DEVICE_SUCCESS, 'deviceId', 'timerId');
export const startReconnectDeviceFailure = makeActionCreator(START_RECONNECT_DEVICE_FAILURE, 'deviceId');

/**
 * This will publish a command to the device. This will also
 * make sure that the AWS IoT PubSub Provider is configured, which
 * it should have already been when subscriptions were established.
 *
 * @param {string} deviceId id of the device.
 * @param {string} command the command to execute.
 * @param {object} data any data that the command requires.
 */
export const executeCommand = (deviceId, command, data = {}) => {
  return async (dispatch, getState) => {
    dispatch(executeCommandRequest(deviceId, command));

    try {
      const cognitoSub = await getUserCognitoSub();

      // Always check to see if we've configured the AWSIoTProvider.
      if (!iotProviderConfigured) {
        await configureAWSIoTProvider(cognitoSub);
      }

      // Grab the detailed device information from the state,
      // which includes the level ids.
      const device = getUserDeviceById(getState(), deviceId);

      // Construct the appropriate command topic to publish to.
      const commandTopic = getCommandTopic(
        deviceId,
        device['level_1_id'],
        device['level_2_id'],
        device['level_3_id'],
        device['level_4_id'],
        command,
        true,
        cognitoSub,
      );

      console.log('executing command:', commandTopic);

      // Execute the command.
      await PubSub.publish(commandTopic, data);

    } catch (e) {
      console.error(e);
      dispatch(executeCommandFailure(deviceId, command));
    }

  };
};
export const executeCommandRequest = makeActionCreator(EXECUTE_COMMAND_REQUEST, 'deviceId', 'command');
export const executeCommandFailure = makeActionCreator(EXECUTE_COMMAND_FAILURE, 'deviceId', 'command');

/**
 * This request the current set points for a device
 *
 * @param {string} deviceId id of the device.
 * @param {string} command the command to execute.
 * @param {object} data any data that the command requires.
 */
export const getSetPoints = (deviceId) => {
  return async (dispatch) => {
    dispatch(getSetPointsRequest(deviceId));

    try {
      // Set Points are maintained in the shadow.
      const getShadowTopic = `$aws/things/${deviceId}/shadow/get`;

      // Publish the get shadow message.
      await PubSub.publish(getShadowTopic, null);

    } catch (e) {
      console.log(e);
      dispatch(getSetPointsFailure(deviceId));
    }
  };
};
export const getSetPointsRequest = makeActionCreator(GET_SET_POINTS_REQUEST, 'deviceId');
export const getSetPointsFailure = makeActionCreator(GET_SET_POINTS_FAILURE, 'deviceId');


/**
 * Set a single set point.
 *
 * @param {string} deviceId
 * @param {string} setPoint
 * @param {string | number} setPointValue
 */
export const setSetPoint = (deviceId, setPoint, setPointValue) => {
  return async (dispatch) => {
    dispatch(setSetPointRequest(deviceId));

    try {
      // Set Points are maintained in the shadow.
      const updateShadowTopic = `$aws/things/${deviceId}/shadow/update`;
      const updateRequest = {
        state: {
          desired: {
            [setPoint]: setPointValue,
          },
        },
      };

      // Publish the update shadow message.
      await PubSub.publish(updateShadowTopic, updateRequest);

    } catch (e) {
      console.log(e);
      dispatch(setSetPointFailure(deviceId));
    }
  };
};

/**
 * Set set points
 *
 * @param {string} deviceId id of the device
 * @param {*} setPoints set points to update
 */
export const setSetPoints = (deviceId, setPoints) => {
  return async (dispatch) => {
    dispatch(setSetPointRequest(deviceId));

    try {
      // Set Points are maintained in the shadow.
      const updateShadowTopic = `$aws/things/${deviceId}/shadow/update`;
      const updateRequest = {
        state: {
          desired: {
            setPoints,
          },
        },
      };

      // Publish the update shadow message.
      await PubSub.publish(updateShadowTopic, updateRequest);

    } catch (e) {
      console.log(e);
      dispatch(setSetPointFailure(deviceId));
    }
  };
};
export const setSetPointRequest = makeActionCreator(SET_SET_POINTS_REQUEST, 'deviceId');
export const setSetPointFailure = makeActionCreator(SET_SET_POINTS_FAILURE, 'deviceId');

/**
 * Update device name.
 *
 * @param {string} deviceId id of the device
 * @param {string} deviceName new name for the device
 */
export const updateDeviceName = (deviceId, deviceName) => {
  return async (dispatch) => {
    dispatch(updateDeviceNameRequest());

    try {
      const endpoint = `/devices/${encodeURIComponent(deviceId)}`;

      await API.put(DEVICES_API_ID, endpoint, { body: { deviceName } });

      dispatch(updateDeviceNameSuccess(deviceId, deviceName));
      dispatch(getUserDevices());

    } catch (e) {
      console.error(e);
      dispatch(updateDeviceNameFailure(deviceId, deviceName));
    }
  };
};
export const updateDeviceNameRequest = makeActionCreator(UPDATE_DEVICE_NAME_REQUEST);
export const updateDeviceNameSuccess = makeActionCreator(UPDATE_DEVICE_NAME_SUCCESS, 'deviceId', 'deviceName');
export const updateDeviceNameFailure = makeActionCreator(UPDATE_DEVICE_NAME_FAILURE, 'deviceId', 'deviceName');

/**
 * Current Station Engines Online.
 *
 * @param {string} deviceId id of the device
 * @param {string} deviceName new name for the device
 */
export const showConnectedEngines = (deviceId) => {
  return async (dispatch, getState) => {
    dispatch(showConnectedEnginesRequest(deviceId));
    try {
      const onlineEngineList = isEnginePresent(getState(), deviceId);
      dispatch(showConnectedEnginesSuccess(deviceId, onlineEngineList));
    } catch (e) {
      console.log(e);
      dispatch(showConnectedEnginesFailure(deviceId));
    }
  };
};
export const showConnectedEnginesRequest = makeActionCreator(CURRENT_ENGINES_ONLINE_REQUEST);
export const showConnectedEnginesSuccess = makeActionCreator(CURRENT_ENGINES_ONLINE_SUCCESS, 'deviceId', 'onlineEngineList');
export const showConnectedEnginesFailure = makeActionCreator(CURRENT_ENGINES_ONLINE_FAILURE, 'deviceId', 'onlineEngineList');

/**
 * Current Station Engines Online.
 *
 * @param {string} deviceId id of the device
 * @param {string} deviceName new name for the device
 */
export const showConnectedCentralControllers = (deviceId) => {
  return async (dispatch, getState) => {
    dispatch(showConnectedCentralControllersRequest(deviceId));
    try {
      const centralOnlineStatus = isCentralPresent(getState(), deviceId);
      dispatch(showConnectedCentralControllersSuccess(deviceId, centralOnlineStatus));
    } catch (e) {
      console.log(e);
      dispatch(showConnectedCentralControllersFailure(deviceId));
    }
  };
};
export const showConnectedCentralControllersRequest = makeActionCreator(CURRENT_CENTRAL_ONLINE_REQUEST);
export const showConnectedCentralControllersSuccess = makeActionCreator(CURRENT_CENTRAL_ONLINE_SUCCESS, 'deviceId', 'centralOnlineStatus');
export const showConnectedCentralControllersFailure = makeActionCreator(CURRENT_CENTRAL_ONLINE_FAILURE, 'deviceId', 'centralOnlineStatus');

/**
 * Current Diversion Controllers Online.
 *
 * @param {string} deviceId id of the device
 * @param {string} deviceName new name for the device
 */
export const showConnectedDiversionControllers = (deviceId) => {
  return async (dispatch, getState) => {
    dispatch(showConnectedDiversionControllersRequest(deviceId));
    try {
      const diversionOnlineStatus = isDiversionPresent(getState(), deviceId);
      dispatch(showConnectedDiversionControllersSuccess(deviceId, diversionOnlineStatus));
    } catch (e) {
      console.log(e);
      dispatch(showConnectedDiversionControllersFailure(deviceId));
    }
  };
};
export const showConnectedDiversionControllersRequest = makeActionCreator(CURRENT_DIVERSION_ONLINE_REQUEST);
export const showConnectedDiversionControllersSuccess = makeActionCreator(CURRENT_DIVERSION_ONLINE_SUCCESS, 'deviceId', 'diversionOnlineStatus');
export const showConnectedDiversionControllersFailure = makeActionCreator(CURRENT_DIVERSION_ONLINE_FAILURE, 'deviceId', 'diversionOnlineStatus');
