const actionPrefix = 'app/iot';

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;
export const CLEAR_DEVICE_TELEMETRY = `${actionPrefix}/CLEAR_DEVICE_TELEMETRY`;

export const CONNECT_DEVICE_REQUEST = `${actionPrefix}/CONNECT_DEVICE_REQUEST`;
export const CONNECT_DEVICE_SUCCESS = `${actionPrefix}/CONNECT_DEVICE_SUCCESS`;
export const CONNECT_DEVICE_FAILURE = `${actionPrefix}/CONNECT_DEVICE_FAILURE`;

export const DISCONNECT_DEVICE_REQUEST = `${actionPrefix}/DISCONNECT_DEVICE_REQUEST`;
export const DISCONNECT_DEVICE_SUCCESS = `${actionPrefix}/DISCONNECT_DEVICE_SUCCESS`;
export const DISCONNECT_DEVICE_FAILURE = `${actionPrefix}/DISCONNECT_DEVICE_FAILURE`;

export const START_RECONNECT_DEVICE_REQUEST = `${actionPrefix}/START_RECONNECT_DEVICE_REQUEST`;
export const START_RECONNECT_DEVICE_SUCCESS = `${actionPrefix}/START_RECONNECT_DEVICE_SUCCESS`;
export const START_RECONNECT_DEVICE_FAILURE = `${actionPrefix}/START_RECONNECT_DEVICE_FAILURE`;

export const SET_RECONNECT_DEVICE_COMPLETE = `${actionPrefix}/SET_RECONNECT_DEVICE_COMPLETE`;

export const SET_DEVICE_CONNECTED = `${actionPrefix}/SET_DEVICE_CONNECTED`;
export const SET_DEVICE_DISCONNECTED = `${actionPrefix}/SET_DEVICE_DISCONNECTED`;

export const GET_SET_POINTS_REQUEST = `${actionPrefix}/GET_SET_POINTS_REQUEST`;
export const GET_SET_POINTS_FAILURE = `${actionPrefix}/GET_SET_POINTS_FAILURE`;

export const SET_SET_POINTS_REQUEST = `${actionPrefix}/SET_SET_POINTS_REQUEST`;
export const SET_SET_POINTS_FAILURE = `${actionPrefix}/SET_SET_POINTS_FAILURE`;

export const UPDATE_SET_POINT = `${actionPrefix}/UPDATE_SET_POINT`;
export const UPDATE_SET_POINTS = `${actionPrefix}/UPDATE_SET_POINTS`;

export const EXECUTE_COMMAND_REQUEST = `${actionPrefix}/EXECUTE_COMMAND_REQUEST`;
export const EXECUTE_COMMAND_FAILURE = `${actionPrefix}/EXECUTE_COMMAND_FAILURE`;

export const UPDATE_DEVICE_NAME_REQUEST = `${actionPrefix}/UPDATE_DEVICE_NAME_REQUEST`;
export const UPDATE_DEVICE_NAME_SUCCESS = `${actionPrefix}/UPDATE_DEVICE_NAME_SUCCESS`;
export const UPDATE_DEVICE_NAME_FAILURE = `${actionPrefix}/UPDATE_DEVICE_NAME_FAILURE`;

export const UPDATE_COMMAND_LOG = `${actionPrefix}/UPDATE_COMMAND_LOG`;

export const UPDATE_TELEMETRY_DATA = `${actionPrefix}/UPDATE_TELEMETRY_DATA`;
export const UPDATE_WATER_LEVEL_DATA = `${actionPrefix}/UPDATE_WATER_LEVEL_DATA`;

export const UPDATE_DEVICE_SUBSCRIPTION = `${actionPrefix}/UPDATE_DEVICE_SUBSCRIPTION`;

export const CURRENT_ENGINES_ONLINE_REQUEST = `${actionPrefix}/CURRENT_ENGINES_ONLINE_REQUEST`;
export const CURRENT_ENGINES_ONLINE_SUCCESS = `${actionPrefix}/CURRENT_ENGINES_ONLINE_SUCCESS`;
export const CURRENT_ENGINES_ONLINE_FAILURE = `${actionPrefix}/CURRENT_ENGINES_ONLINE_FAILURE`;

export const CURRENT_CENTRAL_ONLINE_REQUEST = `${actionPrefix}/CURRENT_CENTRAL_ONLINE_REQUEST`;
export const CURRENT_CENTRAL_ONLINE_SUCCESS = `${actionPrefix}/CURRENT_CENTRAL_ONLINE_SUCCESS`;
export const CURRENT_CENTRAL_ONLINE_FAILURE = `${actionPrefix}/CURRENT_CENTRAL_ONLINE_FAILURE`;

export const CURRENT_DIVERSION_ONLINE_REQUEST = `${actionPrefix}/CURRENT_DIVERSION_ONLINE_REQUEST`;
export const CURRENT_DIVERSION_ONLINE_SUCCESS = `${actionPrefix}/CURRENT_DIVERSION_ONLINE_SUCCESS`;
export const CURRENT_DIVERSION_ONLINE_FAILURE = `${actionPrefix}/CURRENT_DIVERSION_ONLINE_FAILURE`;
