import { StyleSheet } from 'react-native';

export const mobileStyles = StyleSheet.create({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    size: 6,
  },

  buttonRow: {
    alignItems: 'center',
    width: '100%',
  },

  button: {
    marginLeft: 6,
    marginRight: 6,
    width: 90,
    height: 30,
  },

  firstButtonGroup: {
    marginLeft: 20,
    marginRight: 6,
    width: 90,
    height: 30,
  },

  buttonMinMax: {
    marginLeft: 6,
    marginRight: 6,
    width: 90,
    height: 30,
  },


  buttonText: {
    color: '#FAFAFA',
    fontSize: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stopAllButton: {
    backgroundColor: '#C82A32',
  },
  idleAllButton: {
    backgroundColor: '#EEB310',
  },
  runAllButton: {
    backgroundColor: '#006644',
  },

  maxAllButton: {
    backgroundColor: '#E7E6E6',
  },

  minAllButton: {
    backgroundColor: '#E7E6E6',
  },

  vitalsAllButton: {
    backgroundColor: '#616161',
  },

  valvesAllButton: {
    backgroundColor: '#616161',
  },

  maxMinbuttonText: {
    color: '#000000',
    fontSize: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  vitalButtonText: {
    color: '#E7E6E6',
    fontSize: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  valveButtonText: {
    color: '#E7E6E6',
    fontSize: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

});
