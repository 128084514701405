// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getTelemetryForDevice, getTelemetryForEngineBitPack, isEnginePresent } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import MiniTelemetryValue from '_components/device_card/MiniTelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/MiniEngineVitals.style';

import { defaultVitalsSettings } from '_components/device_settings/VitalsSettings.component';

const MAX_VITALS = 8;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <MiniTelemetryValue value={value} />
      <Text style={[styles.vitalsUnit]}>{unit}</Text>
    </Row>
  );
}


class MiniEngineVitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      deviceSettings,
      isUserFetchingDeviceCardSettings,
      engineData,
      centralData,
      REMOTE_DATA_TX,
      isEnginePresent,
      engineNumber,
    } = this.props;

    // const {
    //   e1_hrs_1,
    //   e1_hrs_2
    // } = REMOTE_DATA_TX

    let engineHours = 0;
    let rpmSpeed = 0;
    let engineBattery = 0;
    let engineState = "-";
    let mainSelector = "-";
    let throttleSelector = "-";



    if (engineNumber === 0) {
      const {
        e1_hrs_1,
        e1_hrs_2,
        e1_vdc,
        e1_rpm,
      } = REMOTE_DATA_TX
      const {
        e1s1BitPack,
        e1s3BitPack,
      } = engineData;
      const {
        b_eng_1_man_position,
        b_eng_1_off_position,
        b_eng_1_auto_position,
        b_eng_1_manual_throttle_control,
        b_eng_1_throttle_preset_position,
        b_eng_1_throttle_auto_position,
      } = e1s3BitPack; //for throttle and Main selector switches
      const {
        b_eng_1_engine_ready,
        b_eng_1_engine_at_load,
        b_eng_1_engine_fail_out,
        b_eng_1_emergency_start_active_status,
        b_eng_1_prelube_timing,
        b_eng_1_crank_timing,
        b_eng_1_rest_timing,
        b_eng_1_warmup_timing,
        b_eng_1_cooldown_timing,
      } = e1s1BitPack //for engine status
      engineHours = e1_hrs_1 + e1_hrs_2;
      engineBattery = e1_vdc;
      rpmSpeed = e1_rpm;

      if (b_eng_1_man_position) {
        mainSelector = "Manual"
      } else if (b_eng_1_off_position) {
        mainSelector = "Off"
      } else if (b_eng_1_auto_position) {
        mainSelector = "Auto"
      }

      if (b_eng_1_manual_throttle_control) {
        throttleSelector = "Manual"
      } else if (b_eng_1_throttle_preset_position) {
        throttleSelector = "Preset"
      } else if (b_eng_1_throttle_auto_position) {
        throttleSelector = "Auto"
      }

      if (b_eng_1_engine_at_load) {
        engineState = "At Load"
      } else if (b_eng_1_engine_fail_out) {
        engineState = "Failed"
      } else if (b_eng_1_emergency_start_active_status) {
        engineState = "Em. Operation"
      } else if (b_eng_1_prelube_timing) {
        engineState = "Prelube/Postlube"
      } else if (b_eng_1_crank_timing) {
        engineState = "Cranking"
      } else if (b_eng_1_rest_timing) {
        engineState = "Crank Rest"
      } else if (b_eng_1_warmup_timing) {
        engineState = "Warmup"
      } else if (b_eng_1_cooldown_timing) {
        engineState = "Cooldown"
      } else if (b_eng_1_engine_ready) {
        engineState = "Ready"
      }

    } else if (engineNumber === 1) {
      const {
        e2_hrs_1,
        e2_hrs_2,
        e2_vdc,
        e2_rpm,
      } = REMOTE_DATA_TX
      const {
        e2s1BitPack,
        e2s3BitPack,
      } = engineData;
      const {
        b_eng_1_man_position,
        b_eng_1_off_position,
        b_eng_1_auto_position,
        b_eng_1_manual_throttle_control,
        b_eng_1_throttle_preset_position,
        b_eng_1_throttle_auto_position,
      } = e2s3BitPack; //for throttle and Main selector switches
      const {
        b_eng_1_engine_ready,
        b_eng_1_engine_at_load,
        b_eng_1_engine_fail_out,
        b_eng_1_emergency_start_active_status,
        b_eng_1_prelube_timing,
        b_eng_1_crank_timing,
        b_eng_1_rest_timing,
        b_eng_1_warmup_timing,
        b_eng_1_cooldown_timing,
      } = e2s1BitPack //for engine status
      engineHours = e2_hrs_1 + e2_hrs_2;
      engineBattery = e2_vdc;
      rpmSpeed = e2_rpm;

      if (b_eng_1_man_position) {
        mainSelector = "Manual"
      } else if (b_eng_1_off_position) {
        mainSelector = "Off"
      } else if (b_eng_1_auto_position) {
        mainSelector = "Auto"
      }

      if (b_eng_1_manual_throttle_control) {
        throttleSelector = "Manual"
      } else if (b_eng_1_throttle_preset_position) {
        throttleSelector = "Preset"
      } else if (b_eng_1_throttle_auto_position) {
        throttleSelector = "Auto"
      }

      if (b_eng_1_engine_at_load) {
        engineState = "At Load"
      } else if (b_eng_1_engine_fail_out) {
        engineState = "Failed"
      } else if (b_eng_1_emergency_start_active_status) {
        engineState = "Em. Operation"
      } else if (b_eng_1_prelube_timing) {
        engineState = "Prelube/Postlube"
      } else if (b_eng_1_crank_timing) {
        engineState = "Cranking"
      } else if (b_eng_1_rest_timing) {
        engineState = "Crank Rest"
      } else if (b_eng_1_warmup_timing) {
        engineState = "Warmup"
      } else if (b_eng_1_cooldown_timing) {
        engineState = "Cooldown"
      } else if (b_eng_1_engine_ready) {
        engineState = "Ready"
      }

    } else if (engineNumber === 2) {
      const {
        e3_hrs_1,
        e3_hrs_2,
        e3_vdc,
        e3_rpm,
      } = REMOTE_DATA_TX
      const {
        e3s1BitPack,
        e3s3BitPack,
      } = engineData;
      const {
        b_eng_1_man_position,
        b_eng_1_off_position,
        b_eng_1_auto_position,
        b_eng_1_manual_throttle_control,
        b_eng_1_throttle_preset_position,
        b_eng_1_throttle_auto_position,
      } = e3s3BitPack; //for throttle and Main selector switches
      const {
        b_eng_1_engine_ready,
        b_eng_1_engine_at_load,
        b_eng_1_engine_fail_out,
        b_eng_1_emergency_start_active_status,
        b_eng_1_prelube_timing,
        b_eng_1_crank_timing,
        b_eng_1_rest_timing,
        b_eng_1_warmup_timing,
        b_eng_1_cooldown_timing,
      } = e3s1BitPack //for engine status
      engineHours = e3_hrs_1 + e3_hrs_2;
      engineBattery = e3_vdc;
      rpmSpeed = e3_rpm;

      if (b_eng_1_man_position) {
        mainSelector = "Manual"
      } else if (b_eng_1_off_position) {
        mainSelector = "Off"
      } else if (b_eng_1_auto_position) {
        mainSelector = "Auto"
      }

      if (b_eng_1_manual_throttle_control) {
        throttleSelector = "Manual"
      } else if (b_eng_1_throttle_preset_position) {
        throttleSelector = "Preset"
      } else if (b_eng_1_throttle_auto_position) {
        throttleSelector = "Auto"
      }

      if (b_eng_1_engine_at_load) {
        engineState = "At Load"
      } else if (b_eng_1_engine_fail_out) {
        engineState = "Failed"
      } else if (b_eng_1_emergency_start_active_status) {
        engineState = "Em. Operation"
      } else if (b_eng_1_prelube_timing) {
        engineState = "Prelube/Postlube"
      } else if (b_eng_1_crank_timing) {
        engineState = "Cranking"
      } else if (b_eng_1_rest_timing) {
        engineState = "Crank Rest"
      } else if (b_eng_1_warmup_timing) {
        engineState = "Warmup"
      } else if (b_eng_1_cooldown_timing) {
        engineState = "Cooldown"
      } else if (b_eng_1_engine_ready) {
        engineState = "Ready"
      }

    } else if (engineNumber === 3) {
      const {
        e4_hrs_1,
        e4_hrs_2,
        e4_vdc,
        e4_rpm,
      } = REMOTE_DATA_TX
      const {
        e4s1BitPack,
        e4s3BitPack,
      } = engineData;
      const {
        b_eng_1_man_position,
        b_eng_1_off_position,
        b_eng_1_auto_position,
        b_eng_1_manual_throttle_control,
        b_eng_1_throttle_preset_position,
        b_eng_1_throttle_auto_position,
      } = e4s3BitPack; //for throttle and Main selector switches
      const {
        b_eng_1_engine_ready,
        b_eng_1_engine_at_load,
        b_eng_1_engine_fail_out,
        b_eng_1_emergency_start_active_status,
        b_eng_1_prelube_timing,
        b_eng_1_crank_timing,
        b_eng_1_rest_timing,
        b_eng_1_warmup_timing,
        b_eng_1_cooldown_timing,
      } = e4s1BitPack //for engine status
      engineHours = e4_hrs_1 + e4_hrs_2;
      engineBattery = e4_vdc;
      rpmSpeed = e4_rpm;

      if (b_eng_1_man_position) {
        mainSelector = "Manual"
      } else if (b_eng_1_off_position) {
        mainSelector = "Off"
      } else if (b_eng_1_auto_position) {
        mainSelector = "Auto"
      }

      if (b_eng_1_manual_throttle_control) {
        throttleSelector = "Manual"
      } else if (b_eng_1_throttle_preset_position) {
        throttleSelector = "Preset"
      } else if (b_eng_1_throttle_auto_position) {
        throttleSelector = "Auto"
      }

      if (b_eng_1_engine_at_load) {
        engineState = "At Load"
      } else if (b_eng_1_engine_fail_out) {
        engineState = "Failed"
      } else if (b_eng_1_emergency_start_active_status) {
        engineState = "Em. Operation"
      } else if (b_eng_1_prelube_timing) {
        engineState = "Prelube/Postlube"
      } else if (b_eng_1_crank_timing) {
        engineState = "Cranking"
      } else if (b_eng_1_rest_timing) {
        engineState = "Crank Rest"
      } else if (b_eng_1_warmup_timing) {
        engineState = "Warmup"
      } else if (b_eng_1_cooldown_timing) {
        engineState = "Cooldown"
      } else if (b_eng_1_engine_ready) {
        engineState = "Ready"
      }

    } else if (engineNumber === 4) {
      const {
        e5_hrs_1,
        e5_hrs_2,
        e5_vdc,
        e5_rpm,
      } = REMOTE_DATA_TX
      const {
        e5s1BitPack,
        e5s3BitPack,
      } = engineData;
      const {
        b_eng_1_man_position,
        b_eng_1_off_position,
        b_eng_1_auto_position,
        b_eng_1_manual_throttle_control,
        b_eng_1_throttle_preset_position,
        b_eng_1_throttle_auto_position,
      } = e5s3BitPack; //for throttle and Main selector switches
      const {
        b_eng_1_engine_ready,
        b_eng_1_engine_at_load,
        b_eng_1_engine_fail_out,
        b_eng_1_emergency_start_active_status,
        b_eng_1_prelube_timing,
        b_eng_1_crank_timing,
        b_eng_1_rest_timing,
        b_eng_1_warmup_timing,
        b_eng_1_cooldown_timing,
      } = e5s1BitPack //for engine status
      engineHours = e5_hrs_1 + e5_hrs_2;
      engineBattery = e5_vdc;
      rpmSpeed = e5_rpm;

      if (b_eng_1_man_position) {
        mainSelector = "Manual"
        console.log("hi")
      } else if (b_eng_1_off_position) {
        mainSelector = "Off"
      } else if (b_eng_1_auto_position) {
        mainSelector = "Auto"
      }

      if (b_eng_1_manual_throttle_control) {
        throttleSelector = "Manual"
      } else if (b_eng_1_throttle_preset_position) {
        throttleSelector = "Preset"
      } else if (b_eng_1_throttle_auto_position) {
        throttleSelector = "Auto"
      }

      if (b_eng_1_engine_at_load) {
        engineState = "At Load"
      } else if (b_eng_1_engine_fail_out) {
        engineState = "Failed"
      } else if (b_eng_1_emergency_start_active_status) {
        engineState = "Em. Operation"
      } else if (b_eng_1_prelube_timing) {
        engineState = "Prelube/Postlube"
      } else if (b_eng_1_crank_timing) {
        engineState = "Cranking"
      } else if (b_eng_1_rest_timing) {
        engineState = "Crank Rest"
      } else if (b_eng_1_warmup_timing) {
        engineState = "Warmup"
      } else if (b_eng_1_cooldown_timing) {
        engineState = "Cooldown"
      } else if (b_eng_1_engine_ready) {
        engineState = "Ready"
      }

    }


    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;


    //Note: for the engine hours and water level it's divided by 10 to give the correct ammount. We may want to perform this or other calculations outside
    // of the "vitals" const.
    const vitals = [
      { id: 'engineBattery', label: 'Battery (VDC)', value: engineBattery / 10, unit: '' },
      { id: 'rpmSpeed', label: 'Speed (RPM)', value: rpmSpeed, unit: '' },
      { id: 'throttleSelector', label: 'Throttle', value: throttleSelector, unit: '' },
      { id: 'engineState', label: 'Eng. State', value: engineState, unit: '' },
      { id: 'mainSelector', label: 'Main', value: mainSelector, unit: '' },
      { id: 'engineHours', label: 'Eng. Hours', value: engineHours / 10, unit: '' },
      //Saved to show style of formating values from Bazooka code:
      //{ id: 'totalFlow', label: 'eng1battery', value: nFormatter('stf', 3), unit: 'GAL' },
    ];





    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value}
            unit={item.unit}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    REMOTE_DATA_TX: getTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    engineData: getTelemetryForEngineBitPack(state, ownProps.deviceId),
    isEnginePresent: isEnginePresent(state, ownProps.deviceId),
  };
};

MiniEngineVitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(MiniEngineVitals);
