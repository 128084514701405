// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Amplify
import { Auth } from 'aws-amplify';


// Redux
import { connect } from 'react-redux';
import { removeCrewMember } from '_redux/crewMembers/actions';
import { updateUserProfile } from '_redux/user/actions';
import { getUserGroups } from '_redux/user/selectors';
import { getUserCognitoSub } from '_redux/user/utils';

// Components
import AccessControl, { CREW_LEADER, CREW_MEMBER, GROUP_DISPLAY_NAMES } from '_components/auth/AccessControl.component';
import ConfirmDeleteModalContent from '_components/common/ConfirmDeleteModalContent.component';
import Modal from '_components/common/Modal.component';
import FormInput from '_components/common/FormInput.component';
import ChangePasswordModal from '_components/common/ChangePasswordModal.component';

import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT,
  PHONE_NUMBER_CONSTRAINT,
} from '_util/form-util';

import validation from 'validate.js';

// Native Base Components
import {
  Button,
  Icon,
  Spinner,
  Text,
  View,
} from 'native-base';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_components/user/UserProfileModalContent.style';

// Form Validation.
const constraints = {
  phoneNumber: PHONE_NUMBER_CONSTRAINT,
  email: EMAIL_CONSTRAINT,
};

/**
 *
 */
class UserProfileModalContent extends Component {

  constructor(props) {
    super(props);

    console.log('profileprops', props);
    this.state = {
      errorMessages: {},
      failedVerification: false,
      formData: props.formData,
      isVerifying: false,
      originalPhoneNumber: props.formData.phoneNumber,
      showConfirmLeaveCrewModal: false,
      showVerification: false,
      verificationCode: '',
      verificationErrorMessage: '',
      showChangePasswordModal: false,
    };

    this._onConfirm = this._onConfirm.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleVerificationCodeInput = this._handleVerificationCodeInput.bind(this);
    this._handleVerify = this._handleVerify.bind(this);
    this._logout = this._logout.bind(this);
    this._leaveCrew = this._leaveCrew.bind(this);
    this._showConfirmLeaveCrewModal = this._showConfirmLeaveCrewModal.bind(this);
    this._hideConfirmLeaveCrewModal = this._hideConfirmLeaveCrewModal.bind(this);
    this._showChangePasswordModal = this._showChangePasswordModal.bind(this);
    this._hideChangePasswordModal = this._hideChangePasswordModal.bind(this);
  }

  /*

   * Log the user out of the application globally.

  async _logout() {
    await Auth.signOut({global: true});
  }

  */

  //changed to not logout users globally

  async _logout() {
    try {
      await Auth.signOut();
      console.log("success signing out");
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }



  _showConfirmLeaveCrewModal() {
    this.setState({
      showConfirmLeaveCrewModal: true,
    });
  }

  _hideConfirmLeaveCrewModal() {
    this.setState({
      showConfirmLeaveCrewModal: false,
    });
  }

  _showChangePasswordModal() {
    this.setState({
      showChangePasswordModal: true,
    });
  }

  _hideChangePasswordModal() {
    this.setState({
      showChangePasswordModal: false,
    });
  }

  async _leaveCrew() {
    const { removeCrewMember } = this.props;

    // TODO: In the future this would need to get updated to specifiy
    // the crew, for now a user can only be apart of one crew so we just remove
    // whatever crew they're apart of.
    removeCrewMember(await getUserCognitoSub());
    this._hideConfirmLeaveCrewModal();
  }

  /**
   * Event handler for when the user presses the EDIT PROFILE button.
   */
  _onConfirm() {
    const { formData } = this.state;

    const errorMessages = validateFormData(formData, constraints);

    if (Object.keys(errorMessages).length === 0) {
      this.props.updateUserProfile(formData);
    } else {
      this.setState({
        errorMessages,
      });
    }
  }

  /**
   * Updates the verification code that the user inputs.
   *
   * @param {*} fieldId
   * @param {*} value
   */
  _handleVerificationCodeInput(fieldId, value) {
    this.setState({
      verificationCode: value,
    });
  }

  /**
   * Initiates a call to Cognito to verify the code the user
   * entered if they changed their email address.
   */
  async _handleVerify() {
    const { verificationCode } = this.state;
    this.setState({
      isVerifying: true,
    });

    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', verificationCode);

      this.setState({
        showVerification: false,
        isVerifying: false,
        failedVerification: false,
        verificationErrorMessage: '',
        verificationCode: '',
      });
    } catch (e) {
      console.log(e);
      this.setState({
        failedVerification: true,
        verificationErrorMessage: e.message,
      });
    } finally {
      this.setState({
        isVerifying: false,
      });
    }
  }

  /**
   * Event handler for when the form data changes
   *
   * @param {string} fieldId id of the field, e.g. phoneNumber
   * @param {*} value value of the field, e.g. 1112223333
   */
  _handleInputChange(fieldId, value) {
    const { errorMessages, formData } = this.state;

    if (fieldId === 'phoneNumber') {
      value = formatPhoneNumber(value);
    }

    // Validate the form field
    const validationResult = validation.validate(
      { [fieldId]: value },
      { [fieldId]: constraints[fieldId] }
    );
    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        formData: {
          ...formData,
          [fieldId]: value,
        },
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        }
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          [fieldId]: value,
        },
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  }

  // If the user updates their email address, we need to verify it.
  componentDidUpdate(prevProps) {
    const {
      isRemovingCrewMember,
      failedToRemoveCrewMember,
      isUpdatingUserProfile,
    } = this.props;
    const {
      isRemovingCrewMember: prevIsRemovingCrewMember,
      isUpdatingUserProfile: prevIsUpdatingUserProfile,
    } = prevProps;

    // Check if the user altered their phone number, which requires verification.
    if (prevIsUpdatingUserProfile && !isUpdatingUserProfile && this.state.originalPhoneNumber !== this.state.formData.phoneNumber) {
      this.setState({
        showVerification: true,
      });
    }

    // If the user successfully removes themself from their crew, force a signout.
    if (prevIsRemovingCrewMember && !isRemovingCrewMember && !failedToRemoveCrewMember) {
      this._logout();
    }
  }

  render() {
    const {
      errorMessages,
      failedVerification,
      formData,
      isRemovingCrewMember,
      isVerifying,
      showConfirmLeaveCrewModal,
      showVerification,
      verificationErrorMessage,
      showChangePasswordModal,
    } = this.state;

    const {
      isUpdatingUserProfile,
      userGroups,
    } = this.props;

    const userGroupDisplay = GROUP_DISPLAY_NAMES[userGroups[0]];

    return (
      <View style={[styles.userProfileModalContent]}>
        <Text style={[styles.description]}>
          Use the form below to edit your profile information.
        </Text>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'firstName'}
            placeholder={'First Name'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            defaultValue={formData.firstName}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'lastName'}
            placeholder={'Last Name'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            defaultValue={formData.lastName}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'email'}
            placeholder={'Email Address'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['email'] || ''}
            defaultValue={formData.email}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'phoneNumber'}
            placeholder={'Phone Number'}
            helpText={'###-###-####'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['phoneNumber'] || ''}
            defaultValue={formData.phoneNumber}
          />
        </View>
        {/* Show the verification input if the user changed their phone number */}
        {
          !showVerification ? null :
            <View style={[styles.formInput]}>
              <View style={[styles.verificationInputContainer]}>
                <View style={[styles.verificateCodeInput]}>
                  <FormInput
                    fieldId={'verificationCode'}
                    placeholder={'Verification code'}
                    onChange={this._handleVerificationCodeInput}
                    itemStyles={[global.item]}
                    inputStyles={[global.input]}
                    helpText={'6 digit code texted to your phone'}
                  />
                </View>
                <View styles={[styles.verificationCodeButton]}>
                  {
                    isVerifying ?
                      <Spinner color={'red'} />
                      :
                      <Button style={[global.confirmationButton]} onPress={this._handleVerify}>
                        <Text style={[global.confirmationButtonText]}>
                          Verify
                        </Text>
                      </Button>
                  }
                </View>
              </View>
              {
                !failedVerification ? null :
                  <Text style={[styles.verificationErrorMessage]}>
                    { verificationErrorMessage }
                  </Text>
              }
            </View>
        }
        <View style={[styles.formInput]}>
          <View style={[styles.roleTypeContainer]}>
            <Text style={[styles.labelText]}>
              Role Type:
            </Text>
            <View style={[styles.roleTypeInput]}>
              <FormInput
                fieldId={'roleType'}
                onChange={console.log}
                placeholder={'Role Type'}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                defaultValue={userGroupDisplay}
                disabled={true}
              />
            </View>
          </View>
        </View>

        <View style={[styles.actionButtonContainer]}>
          {
            isUpdatingUserProfile ?
              <Spinner color={'red'} />
              :
              <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._onConfirm}>
                <Text style={[global.confirmationButtonText]}>
                  SAVE PROFILE INFORMATION
                </Text>
              </Button>
          }
          <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._showChangePasswordModal}>
                <Text style={[global.confirmationButtonText]}>
                  CHANGE PASSWORD
                </Text>
          </Button>
          {/* <AccessControl allowedGroups={[CREW_MEMBER, CREW_LEADER]}>
            {
              isRemovingCrewMember ?
                <Spinner color={'red'} />
                :
                <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._showConfirmLeaveCrewModal}>
                  <Text style={[global.confirmationButtonText]}>
                    LEAVE CREW
                  </Text>
                </Button>
            }
          </AccessControl> */}
          <Button style={[global.cancelButton, { marginBottom: 15, marginTop: 25 }]} onPress={this._logout}>
            <View style={[styles.logoutTextContainer]}>
              <Icon style={[global.lighter_gray]} name='log-out' />
              <Text style={[styles.logoutText]}>
                LOGOUT
              </Text>
            </View>
          </Button>
        </View>

        <View>
          <Modal
            isVisible={showConfirmLeaveCrewModal}
            title={'REMOVE CREW MEMBER'}
            hideExitIcon={true}
            onClose={this._hideConfirmLeaveCrewModal}
            content={showConfirmLeaveCrewModal &&
              <ConfirmDeleteModalContent
                onConfirm={this._leaveCrew}
                onCancel={this._hideConfirmLeaveCrewModal}

                confirmationText={'Are you sure you want to leave your current crew? This cannot be reverted, and will require signing you out of your account.'}
                confirmButtonText={'LEAVE CREW'}
                cancelButtonText={'DO NOT LEAVE CREW'}
              />
            }
          />

        </View>
        <View>
          <Modal
            isVisible={showChangePasswordModal}
            title={'CHANGE PASSWORD'}
            hideExitIcon={true}
            onClose={this._hideChangePasswordModal}
            content={showChangePasswordModal &&
              <ChangePasswordModal
                onCancel={this._hideChangePasswordModal}


              />
              }
            />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isRemovingCrewMember: state.crewMembers.isRemovingCrewMember,
    failedToRemoveCrewMember: state.crewMembers.failedToRemoveCrewMember,
    isUpdatingUserProfile: state.user.isUpdatingUserProfile,
    userGroups: getUserGroups(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeCrewMember: (cognitoSub) => dispatch(removeCrewMember(cognitoSub)),
    updateUserProfile: (userProfile) => dispatch(updateUserProfile(userProfile)),
  };
};

UserProfileModalContent.propTypes = {
  failedToRemoveCrewMember: PropTypes.bool.isRequired,
  formData: PropTypes.any.isRequired,
  isRemovingCrewMember: PropTypes.bool.isRequired,
  isUpdatingUserProfile: PropTypes.bool.isRequired,
  removeCrewMember: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  userGroups: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileModalContent);


