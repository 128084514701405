// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getTelemetryForDiversionBitPack, getAlisoViejoTelemetryForDevice, getTelemetryForEngineBitPack, isEnginePresent } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import MiniTelemetryValue from '_components/device_card/MiniTelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/MiniDiversionStatusVitals.style';

import { defaultVitalsSettings } from '_components/device_settings/MiniDiversionSelectorStatusVitalsSettings.component';

const MAX_VITALS = 20;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <Text style={[styles.vitalsValue]}>{value}</Text>
    </Row>
  );
}


class MiniDiversionSelectorStatusVitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      deviceSettings,
      isUserFetchingDeviceCardSettings,
      engineData,
      centralData,
      REMOTE_DATA_TX,
      isEnginePresent,
      engineNumber,
      statusData,
    } = this.props;

    const {
      mbSystem4StatusBitpack,
    } = statusData;

    const {
      b_main_selector_OFF,
      b_main_selector_MAN,
      b_main_selector_AUTO,
      b_UA_selector_OFF,
      b_UA_selector_AUTO,
      b_UA_selector_ACTIVE,
      b_manual_start_input,
      b_in_range_start_input,
      b_ESTOP_fail,
      b_enable_analog_test,
    } = mbSystem4StatusBitpack;

    let mainSelectorOff = "-";
    if (b_main_selector_OFF) {
      mainSelectorOff = 'ON';
    } else {
      mainSelectorOff = 'OFF';
    }

    let mainSelectorManual = "-";
    if (b_main_selector_MAN) {
      mainSelectorManual = 'ON';
    } else {
      mainSelectorManual = 'OFF';
    }

    let mainSelectorAuto = "-";
    if (b_main_selector_AUTO) {
      mainSelectorAuto = 'ON';
    } else {
      mainSelectorAuto = 'OFF';
    }

    let ulraVioletSelectorOff = "-";
    if (b_UA_selector_OFF) {
      ulraVioletSelectorOff = 'ON';
    } else {
      ulraVioletSelectorOff = 'OFF';
    }

    let ultravioletSelectorAuto = "-";
    if (b_UA_selector_AUTO) {
      ultravioletSelectorAuto = 'ON';
    } else {
      ultravioletSelectorAuto = 'OFF';
    }

    let ultravioletSelectorActive = "-";
    if (b_UA_selector_ACTIVE) {
      ultravioletSelectorActive = 'ON';
    } else {
      ultravioletSelectorActive = 'OFF';
    }

    let manualStartPushbutton = "-";
    if (b_manual_start_input) {
      manualStartPushbutton = 'ON';
    } else {
      manualStartPushbutton = 'OFF';
    }

    let inRangeStartPushbutton = "-";
    if (b_in_range_start_input) {
      inRangeStartPushbutton = 'ON';
    } else {
      inRangeStartPushbutton = 'OFF';
    }

    let eStopPosition = "-";
    if (b_ESTOP_fail) {
      eStopPosition = 'ON';
    } else {
      eStopPosition = 'OFF';
    }


    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;


    //Note: for the engine hours and water level it's divided by 10 to give the correct ammount. We may want to perform this or other calculations outside
    // of the "vitals" const.
    //Lw Lvl Bub Timing
    //InRangeStr PushB
    const vitals = [
      { id: 'mainSelectorOff', label: 'Main Selector Off', value: mainSelectorOff, unit: '' },
      { id: 'mainSelectorManual', label: 'Main Selector Manual', value: mainSelectorManual, unit: '' },
      { id: 'mainSelectorAuto', label: 'Main Selector Auto', value: mainSelectorAuto, unit: '' },
      { id: 'ultraVioletSelectorOff', label: 'Ultraviolet Selector Off', value: ulraVioletSelectorOff, unit: '' },
      { id: 'ultravioletSelectorAuto', label: 'Ultraviolet Selector Auto', value: ultravioletSelectorAuto, unit: '' },
      { id: 'ultravioletSelectorActive', label: 'Ultraviolet Selector Active', value: ultravioletSelectorActive, unit: '' },
      { id: 'manualStartPushbutton', label: 'Manual Start Pushbutton', value: manualStartPushbutton, unit: '' },
      { id: 'inRangeStartPushbutton', label: 'In Range Strart Pushbutton', value: inRangeStartPushbutton, unit: '' },
      { id: 'eStopPosition', label: 'E-Stop Position', value: eStopPosition, unit: '' },
    ];


    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value}
            unit={item.unit}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    engineData: getTelemetryForEngineBitPack(state, ownProps.deviceId),
    isEnginePresent: isEnginePresent(state, ownProps.deviceId),
    statusData: getTelemetryForDiversionBitPack(state, ownProps.deviceId),
  };
};

MiniDiversionSelectorStatusVitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(MiniDiversionSelectorStatusVitals);
