import {
  JOBS_API_ID,
  DEVICES_API_ID,
  FEEDBACK_API_ID,
  USERS_API_ID,
  GROUPS_API_ID,
  CREW_MEMBERS_API_ID,
} from '_config/api-constants';

export const DISABLE_REDUX_LOGS = true;

export default {
  Auth: {
    // Federated Authentication - Amazon Cognito Identity Pool ID - municipal
    identityPoolId: 'us-east-2:66a1ec03-4884-4d6c-ac5a-f15f436aed96',

    // Amazon Cognito Region - prod-municipal
    region: 'us-east-2',

    // Amazon Cognito User Pool ID - prod-municipal
    userPoolId: 'us-east-2_tWlpscl0t',

    // Amazon Cognito Web Client ID (26-char alphanumeric string) -prod-municipal
    userPoolWebClientId: '23inh5lkudhqghbquqmb5bv9km',

    oauth: {
      domain: 'prod-municipal.auth.us-east-2.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://www.murcalmunicipal.com',
      redirectSignOut: 'https://www.murcalmunicipal.com',
      responseType: 'code'
    },
  },
  API: {
    endpoints: [
      {
        name: JOBS_API_ID,
        endpoint: "https://fyad8bwa5f.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: DEVICES_API_ID,
        endpoint: "https://fwywcvnxkk.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: FEEDBACK_API_ID,
        endpoint: "https://t7k9fwrda0.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: USERS_API_ID,
        endpoint: "https://ewlz196sl3.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: GROUPS_API_ID,
        endpoint: "https://hus2f2zp7g.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: CREW_MEMBERS_API_ID,
        endpoint: "https://75t54lcxol.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
    ]
  },
  PubSub: {
    aws_pubsub_region: "us-east-2",
    aws_pubsub_endpoint: "wss://albl5xa7bxju5-ats.iot.us-east-2.amazonaws.com/mqtt",
  },
};
