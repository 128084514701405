// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Routing
import { navigate } from "_routes/RootNavigation";

// Util
import { debounce } from "_util";

// Redux
import { connect } from "react-redux";
import { getFriendlyUsername } from "_redux/user/utils";
import { getUserParentId } from "_redux/user/selectors";
import {
  getGroup,
  getGroupChildren,
  getGroupUsers,
  inviteSystemUser,
  removeSystemUser,
  searchDevices,
  searchGroupChildren,
  searchGroupUsers,
} from "_redux/admin/actions";
import { getUserJobs } from "_redux/jobs/actions";
import { subscriptionSite } from "_redux/site/actions";

// Components
import { OWNER } from "_components/auth/AccessControl.component";
import AddEditAccountModalContent from "_components/accounts/AddEditAccountModalContent.component";
import CheckBox from "_components/common/CheckBox.component";
import CheckBoxModi from "_components/common/ModifiedCheckBox.component";
import ConfirmDeleteModalContent from "_components/common/ConfirmDeleteModalContent.component";
import CreateUserAccountModalContent from "_components/accounts/CreateUserAccountModalContent.component";
import EditButton from "_components/common/EditButton.component";
import FormInput from "_components/common/FormInput.component";
import Modal from "_components/common/Modal.component";
import Pagination from "_components/common/Pagination.component";
import ReassignDeviceModalContent from "_components/accounts/ReassignDeviceModalContent.component";
import Table from "_components/common/Table.component";
import TrashButton from "_components/common/TrashButton.component";
import ViewButton from "_components/accounts/ViewButton.component";
import ViewDeviceModalContent from "_components/accounts/ViewDeviceModalContent.component";
import BreadCrumbs from "_components/accounts/BreadCrumbs.component";

// UI Framework
import { Button, Spinner, Text, View } from "native-base";
import { Linking } from "react-native";

// Style
import { styles as global } from "_style/Global.style";
import { styles } from "_pages/Accounts.style";

const DEVICE_PAGE_SIZE = 10;

/**
 *
 */
class Accounts extends Component {
  /**
   * Constructor
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      deviceLoadList: [],
      breadcrumbs: [],
      createUserData: {},
      currentOwnerCognitoSub: "",
      deleteUserCognitoSub: "",
      editAccount: {},
      editMode: false,
      isDealerLevel: props.group ? Number(props.group.role_level) >= 2 : false,
      searchText: "",
      showCompanyProfileModal: false,
      showConfirmRemoveUserModal: false,
      showCreateNewAccountModal: false,
      showCreateNewOwnerAccountModal: false,
      showCreateUserAccountModal: false,
      showReassignDeviceModal: false,
      showViewDeviceModal: false,
      viewAccountDevices: false,
      viewAccountGroups: true,
      viewDeviceId: null,
      viewDeviceIndex: null,
      viewOwnerDevices: false,
      viewOwnerJobs: false,
      viewSystemUsers: false,
      subAccountId: "",
      isChecked: false,
    };

    // Open website
    this._openAccountWebsite = this._openAccountWebsite.bind(this);

    this._updateDeviceLoadList = this._updateDeviceLoadList.bind(this);
    this._loadSelecteDevices = this._loadSelecteDevices.bind(this);

    // Open / Close Modals
    this._closeCompanyProfileModal = this._closeCompanyProfileModal.bind(this);
    this._closeCompanyProfileModal = this._closeCompanyProfileModal.bind(this);
    this._closeConfirmRemoveUserModal =
      this._closeConfirmRemoveUserModal.bind(this);
    this._closeCreateNewClientAccountModal =
      this._closeCreateNewClientAccountModal.bind(this);
    this._closeCreateUserAccountModal =
      this._closeCreateUserAccountModal.bind(this);
    this._closeReassignDeviceModal = this._closeReassignDeviceModal.bind(this);
    this._closeViewDeviceModal = this._closeViewDeviceModal.bind(this);
    this._showConfirmRemoveUserModal =
      this._showConfirmRemoveUserModal.bind(this);
    this._showCreateNewClientAccountModal =
      this._showCreateNewClientAccountModal.bind(this);
    this._showCreateOwnerAccountModal =
      this._showCreateOwnerAccountModal.bind(this);
    this._showCreateUserAccountModal =
      this._showCreateUserAccountModal.bind(this);
    this._showReassignDeviceModal = this._showReassignDeviceModal.bind(this);
    this._showViewDeviceModal = this._showViewDeviceModal.bind(this);

    // Redux actions
    this._deleteSystemUser = this._deleteSystemUser.bind(this);
    this._editAccount = this._editAccount.bind(this);
    this._getDevicesPage = this._getDevicesPage.bind(this);
    this._getGroup = this._getGroup.bind(this);
    this._getGroupChildren = this._getGroupChildren.bind(this);
    this._searchGroupChildren = debounce(
      this._searchGroupChildren.bind(this),
      500
    );
    this._getGroupUsers = this._getGroupUsers.bind(this);
    this._searchGroupUsers = debounce(this._searchGroupUsers.bind(this), 500);
    this._searchDevices = debounce(this._searchDevices.bind(this), 500);
    this._viewAccount = this._viewAccount.bind(this);
    this._viewAccountGroups = this._viewAccountGroups.bind(this);
    this._viewDevices = this._viewDevices.bind(this);
    this._viewOwnerDevices = this._viewOwnerDevices.bind(this);
    this._viewSystemUser = this._viewSystemUser.bind(this);
    this._viewSystemUsers = this._viewSystemUsers.bind(this);

    // Content generation
    this._generateAccountDevicesTableData =
      this._generateAccountDevicesTableData.bind(this);
    this._generateAccountDevicesTableHeaders =
      this._generateAccountDevicesTableHeaders.bind(this);
    this._generateAccountTableData = this._generateAccountTableData.bind(this);
    this._generateAccountTableHeaders =
      this._generateAccountTableHeaders.bind(this);
    this._generateSystemUsersTableData =
      this._generateSystemUsersTableData.bind(this);
    this._generateSystemUsersTableHeaders =
      this._generateSystemUsersTableHeaders.bind(this);
    this._generateOwnerJobsTableData =
      this._generateOwnerJobsTableData.bind(this);
    this._generateOwnerJobsTableHeaders =
      this._generateOwnerJobsTableHeaders.bind(this);
    this._generateTableData = this._generateTableData.bind(this);
    this._generateTableHeaders = this._generateTableHeaders.bind(this);
    this._siteSubscription = this._siteSubscription.bind(this);

    // Breadcrumbs
    this._onCrumbPress = this._onCrumbPress.bind(this);
    this._onBackPress = this._onBackPress.bind(this);
  }

  /**
   * When the component mounts for the first time, check
   * to see if we already have a parent id for the current
   * user in the store. If so, get that user's group and group
   * children.
   */
  componentDidMount() {
    const { userParentId } = this.props;
    if (userParentId) {
      this._getGroup(userParentId);
      this._getGroupChildren(userParentId);
      this._getGroupUsers(userParentId);
    }
  }
  /**
   * Wait until we have an initial parent id for the logged in
   * user to seed the first account screen.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps, prevState) {
    // Props
    const { isAssigningDevice, group, userParentId } = this.props;
    const {
      isAssigningDevice: prevIsAssigningDevice,
      group: prevGroup,
      userParentId: prevUserParentId,
    } = prevProps;

    // State
    const { currentOwnerCognitoSub, searchText } = this.state;
    const { currentOwnerCognitoSub: prevCurrentOwnerCognitoSub } = prevState;

    // If the parent group id has changed, i.e. someone clicked
    // a View Account button, we need to query the backend
    // for that new groups information.
    if (prevUserParentId !== userParentId) {
      this._getGroup(userParentId);
      this._getGroupChildren(userParentId);
      this._getGroupUsers(userParentId);
    }
    if (prevProps.groupUsers !== this.props.groupUsers) {
      //   const { userParentId } = this.props;
      // if (userParentId) {
      //   this._getGroupUsers(userParentId);
      // }
    }

    // If we have a new group to show, i.e. we had a
    // successful query to the backend for group info,
    // we need to kick off the first search for devices.
    if (prevGroup.group_id !== group.group_id) {
      this._searchDevices("");

      // Check if we're down at the dealer level now based
      // on the current group and mark the state accordingly.
      if (Number(group.role_level) >= 2) {
        this.setState({
          isDealerLevel: true,
        });
      } else {
        this.setState({
          isDealerLevel: false,
        });
      }

      if (group.group_name) {
        this.setState({
          breadcrumbs: [
            ...this.state.breadcrumbs,
            { name: group.group_name, id: group.group_id },
          ],
        });
      }
    }

    // If we're down at the dealer level, and the user clicks on the view devices
    // for an owner, we should have a new ownerCognitoSub to search devices for.
    if (prevCurrentOwnerCognitoSub === "" && currentOwnerCognitoSub !== "") {
      this._searchDevices("");
    }
  }

  /**
   *
   * @param {*} deviceId
   * @param {*} checked
   */
  _updateDeviceLoadList(deviceId, checked) {
    let { deviceLoadList } = this.state;

    if (checked) {
      deviceLoadList.push(deviceId);
    } else {
      deviceLoadList = deviceLoadList.filter((id) => id !== deviceId);
    }

    this.setState({
      deviceLoadList,
    });
  }

  /**
   *
   */
  _loadSelecteDevices() {
    const { deviceLoadList } = this.state;

    navigate("Dashboard", { deviceIds: deviceLoadList });
  }

  /**
   *
   */
  _loadSelectedJobId(jobId) {
    navigate("Dashboard", { jobId: jobId });
  }

  /**
   * Triggers an async call in the store to fetch the group
   * with the given id.
   *
   * @param {string} groupId id of the group to fetch.
   */
  _getGroup(groupId) {
    this.props.getGroup(groupId);
  }

  /**
   * Triggers an async call in the store to fetch the immediate
   * children for the given group.
   *
   * @param {string} groupId the group to get children for.
   */
  _getGroupChildren(groupId) {
    this.props.getGroupChildren(groupId);
  }

  /**
   * Fire off a search request as the user is typing into the
   * search box.
   *
   * @param {*} searchText
   * @param {*} groupId
   */
  _searchGroupChildren(searchText, groupId, page = 0) {
    const { group, searchGroupChildren } = this.props;

    this.setState({
      searchText,
    });

    searchGroupChildren(searchText, group.group_id, DEVICE_PAGE_SIZE, page);
  }

  /**
   * Triggers an async call to the store to fetch the
   * system users for this group.
   *
   * @param {string} groupId the group to get users for
   */
  _getGroupUsers(groupId) {
    this.props.getGroupUsers(groupId);
  }

  /**
   *Fire off a search request as the user is typing into the
   * search box.
   *
   * @param {*} searchText
   * @param {string} groupId the group to get users for
   */
  _searchGroupUsers(searchText, groupId, page = 0) {
    const { group, searchGroupUsers } = this.props;

    this.setState({
      searchText,
    });

    searchGroupUsers(searchText, group.group_id, DEVICE_PAGE_SIZE, page);
  }

  /**
   * Opens the current account's website.
   */
  _openAccountWebsite() {
    const { group } = this.props;

    if (group && group.website) {
      Linking.openURL(group.website);
    }
  }

  /**
   * Open the company profile modal.
   */
  _openCompanyProfileModal() {
    this.setState({
      showCompanyProfileModal: true,
    });
  }

  /**
   * Close the company profile modal.
   */
  _closeCompanyProfileModal() {
    this.setState({
      showCompanyProfileModal: false,
    });
  }

  _showReassignDeviceModal() {
    this.setState({
      showViewDeviceModal: false,
      showReassignDeviceModal: true,
    });
  }

  _closeReassignDeviceModal() {
    this._searchDevices("");
    this.setState({
      showReassignDeviceModal: false,
    });
  }

  /**
   * Navigate to this account's devices page.
   */
  _viewDevices() {
    this.setState({
      currentOwner: {},
      currentOwnerCognitoSub: "",
      viewAccountDevices: true,
      viewAccountGroups: false,
      viewOwnerDevices: false,
      viewOwnerJobs: false,
      viewSystemUsers: false,
      showViewDeviceModal: false,
    });
  }

  /**
   * Toggle the main view from accounts to users.
   */
  _viewSystemUsers() {
    this.setState({
      currentOwner: {},
      currentOwnerCognitoSub: "",
      viewAccountDevices: false,
      viewAccountGroups: false,
      viewOwnerDevices: false,
      viewOwnerJobs: false,
      viewSystemUsers: true,
      showViewDeviceModal: false,
    });
  }
  /**
   * Toggle the main view from accounts to users.
   */
  _viewAccountGroups() {
    this.setState({
      currentOwner: {},
      currentOwnerCognitoSub: "",
      viewAccountDevices: false,
      viewAccountGroups: true,
      viewOwnerDevices: false,
      viewOwnerJobs: false,
      viewSystemUsers: false,
      showViewDeviceModal: false,
    });
  }

  /**
   * Special case at the bottom of the hierarchy to view the owner's devices.
   */
  _viewOwnerDevices(cognitoSub, ownerName) {
    const owner = this.props.groupUsers.find(
      (user) => user.cognito_sub === cognitoSub
    );
    const oname = getFriendlyUsername(owner);
    this.setState({
      currentOwner: owner || { username: ownerName },
      currentOwnerCognitoSub: cognitoSub,
      viewAccountDevices: false,
      viewAccountGroups: false,
      viewOwnerDevices: true,
      viewOwnerJobs: false,
      viewSystemUsers: false,
      showViewDeviceModal: false,
      breadcrumbs: [
        ...this.state.breadcrumbs,
        { name: getFriendlyUsername(owner), id: null },
      ],
    });
  }

  /**
   *
   * @param {*} cognitoSub
   */
  _viewSystemUser(cognitoSub) {
    console.log("TODO: view system user", cognitoSub);
  }

  /**
   *
   * @param {*} cognitoSub
   */
  _viewOwnerJobs(cognitoSub) {
    const { getUserJobs } = this.props;

    this.setState({
      viewAccountDevices: false,
      viewAccountGroups: false,
      viewOwnerDevices: false,
      viewOwnerJobs: true,
      viewSystemUsers: false,
      showViewDeviceModal: false,
    });

    getUserJobs(this.state.currentOwnerCognitoSub);
  }

  /**
   * Remove a system user. Reverting them back to a blank owner acccount.
   */
  _deleteSystemUser() {
    const { group, removeSystemUser } = this.props;
    const { deleteUserCognitoSub } = this.state;

    this._closeConfirmRemoveUserModal();

    removeSystemUser(group.group_id, deleteUserCognitoSub);
  }

  /**
   * Triggers the account id to be fetched, which effectively
   * moves the account screen one level down the hierarchy.
   *
   * @param {*} accountId id of the group account to fetch info for.
   */
  _viewAccount(accountId) {
    const { group } = this.props;
    if (!accountId) {
      accountId = group.group_id;
    }
    this.setState({ subAccountId: accountId });
    this._viewAccountGroups();
    this._getGroup(accountId);
    this._getGroupChildren(accountId);
    this._getGroupUsers(accountId);
  }

  /**
   * Put the add edit account modal into edit mode and set the state
   * with the account information to be edited.
   *
   * @param {*} accountId
   */
  _editAccount(accountId) {
    const { group, groupChildren } = this.props;
    // If there is no account id, assume we are editing
    // the current account.
    if (typeof accountId !== "string") {
      this.setState({
        showCreateNewAccountModal: true,
        editMode: true,
        editAccount: group,
      });
    } else {
      const editAccount = groupChildren.find(
        (group) => group.group_id === accountId
      );

      this.setState({
        showCreateNewAccountModal: true,
        editMode: true,
        editAccount,
      });
    }
  }

  _getDevicesPage(pageNumber) {
    const { searchText } = this.state;
    const { group, searchDevices } = this.props;

    searchDevices(searchText, group.group_id, DEVICE_PAGE_SIZE, pageNumber);
  }

  /**
   * Fire off a search request as the user is typing into the
   * search box.
   *
   * @param {*} searchText
   */
  _searchDevices(searchText, page = 0) {
    const { group, searchDevices } = this.props;
    const { currentOwnerCognitoSub } = this.state;

    this.setState({
      searchText,
      deviceLoadList: [],
    });

    // If we have a currentOwnerCognitoSub that means the user clicked
    // to view owner's devices.
    if (currentOwnerCognitoSub) {
      searchDevices(searchText, currentOwnerCognitoSub, DEVICE_PAGE_SIZE, page);
      // Otherwise if we have a group, search for that group's devices.
    } else if (group && group.group_id) {
      searchDevices(searchText, group.group_id, DEVICE_PAGE_SIZE, page);
    }
  }

  /**
   * Show the view device modal.
   *
   * @param {string} deviceId
   */
  _showViewDeviceModal(deviceId) {
    const { groupDevices } = this.props;

    const viewDeviceIndex = groupDevices.findIndex(
      (device) => device.device_id === deviceId
    );

    this.setState({
      showViewDeviceModal: true,
      viewDeviceId: deviceId,
      viewDeviceIndex,
    });
  }

  /**
   * Close the view device modal.
   */
  _closeViewDeviceModal() {
    this.setState({
      showViewDeviceModal: false,
      viewDeviceId: null,
      viewDeviceIndex: null,
    });
  }

  /**
   * Open the confirm remove user modal
   */
  _showConfirmRemoveUserModal(cognitoSub) {
    this.setState({
      showConfirmRemoveUserModal: true,
      deleteUserCognitoSub: cognitoSub,
    });
  }

  /**
   * Close the confirm remove user modal
   */
  _closeConfirmRemoveUserModal() {
    this.setState({
      showConfirmRemoveUserModal: false,
    });
  }

  /**
   *
   */
  _showCreateNewClientAccountModal() {
    this.setState({
      showCreateNewAccountModal: true,
    });
  }
  /**
   *
   */
  _closeCreateNewClientAccountModal() {
    this.setState({
      showCreateNewAccountModal: false,
      editMode: false,
      editAccount: {},
    });
  }

  /**
   *
   */
  _showCreateOwnerAccountModal() {
    const { group } = this.props;

    this.setState({
      showCreateUserAccountModal: true,
      createUserData: {
        groupId: group.group_id,
        title: "CREATE OWNER ACCOUNT",
        subTitle: `Fill out the information below to create new Owner under ${group.group_name}.`,
        confirmButtonLabel: "CREATE OWNER",
        isOwnerInvite: true,
      },
    });
  }

  /**
   *
   */
  _showCreateUserAccountModal() {
    const { group } = this.props;

    this.setState({
      showCreateUserAccountModal: true,
      createUserData: {
        groupId: group.group_id,
        title: "CREATE SYSTEM USER",
        subTitle: `Fill out the information below to create new ${group.group_name} system account`,
        confirmButtonLabel: "CREATE SYSTEM USER",
        isOwnerInvite: false,
      },
    });
  }

  /**
   *
   */
  _closeCreateUserAccountModal() {
    this.setState({
      showCreateUserAccountModal: false,
    });
  }

  /**
   * Generates the table header content for the current view. Either System
   * Users, Account Groups, or Devices.
   */
  _generateTableHeaders() {
    const {
      viewAccountDevices,
      viewAccountGroups,
      viewOwnerDevices,
      viewSystemUsers,
      viewOwnerJobs,
    } = this.state;

    if (viewAccountDevices || viewOwnerDevices) {
      return this._generateAccountDevicesTableHeaders();
    }

    if (viewAccountGroups) {
      return this._generateAccountTableHeaders();
    }

    if (viewSystemUsers) {
      return this._generateSystemUsersTableHeaders();
    }

    if (viewOwnerJobs) {
      return this._generateOwnerJobsTableHeaders();
    }
  }

  /**
   * Generate the table data for the current view. Either System Users,
   * Account Groups, or Devices.
   */
  _generateTableData() {
    const {
      viewAccountDevices,
      viewAccountGroups,
      viewOwnerDevices,
      viewSystemUsers,
      viewOwnerJobs,
    } = this.state;

    if (viewAccountDevices || viewOwnerDevices) {
      return this._generateAccountDevicesTableData();
    }

    if (viewAccountGroups) {
      return this._generateAccountTableData();
    }

    if (viewSystemUsers) {
      return this._generateSystemUsersTableData();
    }

    if (viewOwnerJobs) {
      return this._generateOwnerJobsTableData();
    }
  }

  /**
   * Generates the table header data for viewing devices.
   */
  _generateAccountDevicesTableHeaders() {
    const { group } = this.props;

    const { viewOwnerDevices } = this.state;

    const groupLevel = Number(group.role_level);
    const tableHeaders = [];

    // Device Checkbox
    if (viewOwnerDevices) {
      tableHeaders.push({
        value: "Select Device",
        flex: 0.5,
        align: "flex-start",
      });
    }

    tableHeaders.push({
      value: "Device Serial #",
      flex: 1,
      align: "flex-start",
    });

    if (groupLevel <= 0) {
      tableHeaders.push({ value: "Company", flex: 1, align: "flex-start" });
    }
    if (groupLevel <= 1) {
      tableHeaders.push({ value: "Dealer", flex: 1, align: "flex-start" });
    }
    if (groupLevel <= 2) {
      tableHeaders.push({ value: "Owner", flex: 1, align: "flex-start" });
    }

    tableHeaders.push({ value: "Date Created", flex: 1, align: "flex-start" });
    tableHeaders.push({ value: "", flex: 1, align: "center" });

    return tableHeaders;
  }

  /**
   * Generates the table data object for viewing devices.
   */
  _generateAccountDevicesTableData() {
    const { group, groupDevices } = this.props;
    const { viewOwnerDevices } = this.state;

    const groupLevel = Number(group.role_level);
    const emptyData = "......";

    const rowData = [];
    groupDevices.forEach((device) => {
      // Pick out device properties to display.
      const {
        device_id,
        device_name,
        created,
        level_1_name,
        level_2_name,
        username,
        first_name,
        last_name,
        is_suspended,
      } = device;

      const assignedTo =
        getFriendlyUsername({
          username: username || "",
          first_name,
          last_name,
        }) || emptyData;
      // Formatted device name in the cell
    let deviceName
    if(is_suspended){
      deviceName = (
        <View>
          <Text style={{ color: 'red'}}>{device_id + ' SUSPENDED'}</Text>
          <Text style={{ color:'red', fontSize: 12 }}>{device_name}</Text>
        </View>
      );
    }else{
      deviceName = (
        <View>
          <Text>{device_id}</Text>
          <Text style={{ fontSize: 12 }}>{device_name}</Text>
        </View>
      );
    }

      const viewDeviceButton = (
        <ViewButton
          data={device_id}
          onClick={this._showViewDeviceModal}
          label={"VIEW STATION"}
          width={150}
          fontSize={14}
        />
      );

      if (groupLevel <= 0) {
        // Add device row.
        rowData.push({
          0: { value: deviceName, flex: 1, align: "flex-start" },
          1: { value: level_1_name || emptyData, flex: 1, align: "flex-start" },
          2: { value: level_2_name || emptyData, flex: 1, align: "flex-start" },
          3: { value: assignedTo, flex: 1, align: "flex-start" },
          4: { value: created, flex: 1, align: "flex-start" },
          5: { value: viewDeviceButton, flex: 1, align: "center" },
        });
      } else if (groupLevel <= 1) {
        // Add device row.
        rowData.push({
          0: { value: deviceName, flex: 1, align: "flex-start" },
          1: { value: level_2_name || emptyData, flex: 1, align: "flex-start" },
          2: { value: assignedTo, flex: 1, align: "flex-start" },
          3: { value: created, flex: 1, align: "flex-start" },
          4: { value: viewDeviceButton, flex: 1, align: "center" },
        });
      } else if (groupLevel <= 2) {
        if (viewOwnerDevices) {
          const checkbox = (
            <CheckBox
              fieldId={device_id}
              onChange={this._updateDeviceLoadList}
            />
          );
          // Add device row.
          rowData.push({
            0: { value: checkbox, flex: 0.5, align: "flex-start" },
            1: { value: deviceName, flex: 1, align: "flex-start" },
            2: { value: assignedTo, flex: 1, align: "flex-start" },
            3: { value: created, flex: 1, align: "flex-start" },
            4: { value: viewDeviceButton, flex: 1, align: "center" },
          });
        } else {
          // Add device row.
          rowData.push({
            0: { value: deviceName, flex: 1, align: "flex-start" },
            1: { value: assignedTo, flex: 1, align: "flex-start" },
            2: { value: created, flex: 1, align: "flex-start" },
            3: { value: viewDeviceButton, flex: 1, align: "center" },
          });
        }
      }
    });

    return rowData;
  }

  /**
   * Generates the table header information for when looking at the
   * system user view of the page.
   */
  _generateSystemUsersTableHeaders() {
    return [
      { value: "User", flex: 1, align: "flex-start" },
      { value: "", flex: 1, align: "flex-start" },
      { value: "", flex: 1, align: "center" },
      { value: "", flex: 2, align: "center" },
    ];
  }

  /**
   * TODO: API call for system users.
   */
  _generateSystemUsersTableData() {
    const { groupUsers, isRemovingSystemUser, removingSystemUserCognitoSub } =
      this.props;

    const rowData = [];
    groupUsers.forEach((user, index) => {
      if (user.role_id === OWNER) {
        return;
      }
      const username = getFriendlyUsername(user);
      rowData.push({
        0: { value: username, flex: 1, align: "flex-start" },
        1: { value: user.role_type, flex: 1, align: "flex-start" },
        2: { value: "", flex: 1, align: "center" },
        3: {
          value: (
            <View key={index} style={[styles.actionColContainer]}>
              {isRemovingSystemUser &&
              user.cognito_sub === removingSystemUserCognitoSub ? (
                <Spinner color={"red"} style={[{ alignSelf: "center" }]} />
              ) : (
                <TrashButton
                  data={user.cognito_sub}
                  onClick={this._showConfirmRemoveUserModal}
                />
              )}
            </View>
          ),
          flex: 2,
          align: "center",
        },
      });
    });

    return rowData;
  }

  /**
   * Generates the table header information for when looking at the
   * account view of the page.
   */

  _siteSubscription(cognito_sub, checked) {
    const { subscriptionSite } = this.props;
    if (checked) {
      subscriptionSite(cognito_sub, true);
    } else {
      subscriptionSite(cognito_sub, false);
    }
  }
  _generateAccountTableHeaders() {
    const { isDealerLevel } = this.state;

    if(!isDealerLevel){
      return [
        {
          value: "Company Name",
          flex: 3,
          align: "flex-start",
        },
        { value: "# of Devices", flex: 2, align: "center" },
        {
          value:"# of Users",
          flex: 2,
          align: "center",
        },
        { value: "", flex: 4, align: "center" },
      ];
    }else{
      return [
        {
          value: "Owner",
          flex: 2,
          align: "flex-start",
        },
        {
          value: "Username",
          flex: 2,
          align: "left",
        },
        {
          value: "Email",
          flex: 2,
          align: "left",
        },
        {
          value: "Phone",
          flex: 2,
          align: "left",
        },
        { value: "Devices", flex: 1, align: "left" },
        // {
        //   value:"Mapping",
        //   flex: 1,
        //   align: "left",
        // },
        { value: "", flex: 3, align: "flex-end" },
      ];
    }
  }

  /**
   * Generates the table data structure from the group children to pass
   * into the generic <Table> component.
   */
  _generateAccountTableData() {
    const {
      groupChildren,
      groupUsers,
      group,
      userInformation,
      siteSubscriptionFail,
    } = this.props;

    const { isDealerLevel } = this.state;

    const rowData = [];

    if (!isDealerLevel) {
      groupChildren.forEach((account, index) => {
        rowData.push({
          // Group Name
          0: { value: account.group_name, flex: 3, align: "flex-start" },
          // # Devices
          1: { value: account.device_count || 0, flex: 2, align: "center" },
          // # System Users
          2: { value: account.user_count || 0, flex: 2, align: "center" },
          // Action Group (View / Edit)
          3: {
            value: (
              <View key={index} style={[styles.actionColContainer]}>
                <ViewButton
                  data={account.group_id}
                  onClick={this._viewAccount}
                  label={"VIEW ACCOUNT"}
                />
                <EditButton
                  data={account.group_id}
                  onClick={this._editAccount}
                />
              </View>
            ),
            flex: 4,
            align: "center",
          },
        });
      });
    } else {
      groupUsers.forEach((user, index) => {
        if (user.role_id !== OWNER) {
          return;
        }
        rowData.push({
          // Owner Name
          0: { value: getFriendlyUsername(user), flex: 2, align: "flex-start" },
          // # Devices
          1: { value: user.username || 0, flex: 2, align: "left" },
          // # Devices
          2: { value: user.email || 0, flex: 2, align: "left" },
          // # Devices
          3: { value: user.phone_number || 0, flex: 2, align: "left" },
          // # Devices
          4: { value: user.device_count || 0, flex: 1, align: "center" },
          // # Crew Size (either/or mapping feature?) - todo: update this comment w/ correct info.
          // 5: {
          //   value: (
          //     <View>
          //       <CheckBoxModi
          //         disabled={userInformation.role_id === "dealer" ? true : false}
          //         fieldId={user.cognito_sub}
          //         onChange={this._siteSubscription}
          //         checked={user.is_subscribed == 1 ? true : false}
          //         bgColor={
          //           userInformation.role_id === "dealer" ? "#2C2B2B" : "#424242"
          //         }
          //         //label={user.is_subscribed==1?"ON":"OFF"}
          //         imgSrc={
          //           userInformation.role_id === "dealer"
          //             ? require("_assets/images/ticdisable.svg")
          //             : require("_assets/images/tic.svg")
          //         }
          //       />
          //     </View>
          //   ),
          //   flex: 1,
          //   align: "center",
          // },
          // Action Group (View / Edit)
          6: {
            value: (
              <View key={index} style={[styles.actionColContainer]}>
                <ViewButton
                  data={user.cognito_sub}
                  onClick={this._viewOwnerDevices}
                  label={"   VIEW\nDEVICES"}
                  width="auto"
                  height="auto"
                />
              </View>
            ),
            flex: 3,
            align: "flex-end",
          },
        });
      });
    }

    return rowData;
  }

  /**
   * Generates the table header information for when looking at the
   * owner jobs view of the page.
   */
  _generateOwnerJobsTableHeaders() {
    return [
      { value: "Job Name", flex: 3, align: "flex-start" },
      { value: "# of Devices", flex: 2, align: "center" },
      { value: "# of Crew", flex: 2, align: "center" },
      { value: "", flex: 4, align: "flex-start" },
    ];
  }

  /**
   * Generates the table data structure from jobs to pass
   * into the generic <Table> component.
   */
  _generateOwnerJobsTableData() {
    const { jobs } = this.props;
    const rowData = [];

    jobs.forEach((job, index) => {
      rowData.push({
        0: { value: job.job_name, flex: 3, align: "flex-start" },
        1: { value: job.devices.length || 0, flex: 2, align: "center" },
        2: { value: job.crew_members.length || 0, flex: 2, align: "center" },
        3: {
          value: (
            <View key={index} style={[styles.actionColContainer]}>
              <ViewButton
                data={job.job_id}
                onClick={this._loadSelectedJobId}
                label={"VIEW DASHBOARD"}
                width={200}
                fontSize={14}
              />
            </View>
          ),
          flex: 4,
          align: "center",
        },
      });
    });

    return rowData;
  }

  /**
   * Loads an account to view after a breadcrumb is clicked
   * @param {*} id - Group ID of account to view
   * @param {*} idx - Index in breadcrumb array
   */
  _onCrumbPress(id, idx) {
    if (id) {
      // Load the account
      this._viewAccount(id);

      // Drop all the crumbs after the viewed account
      this.setState({ breadcrumbs: this.state.breadcrumbs.slice(0, idx) });
    }
  }

  /**
   * Loads the previous account on breadcrumb
   */
  _onBackPress() {
    const { breadcrumbs } = this.state;
    if (breadcrumbs.length > 1) {
      breadcrumbs.pop(); // This is the current account
      const info = breadcrumbs.pop(); // This is the account to load

      // Load the account
      this._viewAccount(info.id);

      // Update the state with the previous account removed
      this.setState({ breadcrumbs: breadcrumbs });
    }
  }

  /**
   * Component Render
   */
  render() {
    const {
      group,
      groupDevices,
      isFetchingGroup,
      isFetchingGroupChildren,
      isFetchingGroupDevices,
      isFetchingGroupUsers,
      jobs,
      isGettingUserJobs,

      inviteSystemUser,
      isInvitingSystemUser,
      inviteSystemUserFailed,
      inviteSystemUserErrorMessage,

      searchCount,
    } = this.props;

    const {
      createUserData,
      currentOwner,
      deviceLoadList,
      editAccount,
      editMode,
      isDealerLevel,
      showConfirmRemoveUserModal,
      showCreateNewAccountModal,
      showCreateUserAccountModal,
      showReassignDeviceModal,
      showViewDeviceModal,
      viewAccountDevices,
      viewAccountGroups,
      viewDeviceId,
      viewDeviceIndex,
      viewOwnerDevices,
      viewOwnerJobs,
      viewSystemUsers,
    } = this.state;

    const tableIsLoading =
      (viewOwnerDevices && isFetchingGroupDevices) ||
      (viewOwnerJobs && isGettingUserJobs) ||
      (viewAccountDevices && isFetchingGroupDevices) ||
      (viewAccountGroups &&
        (isFetchingGroupChildren || isFetchingGroupUsers)) ||
      (viewSystemUsers && isFetchingGroupUsers);

    // Setup default text for top level Account View (either MurCal or Bazooka)
    let headerRightTopText = "System Users";
    let headerRightTopButtonText = "VIEW SYSTEM USERS";
    let headerRightBottomText = "Total number of devices";
    let headerRightBottomButtonText = "VIEW STATIONS";
    let headerRightTopCount = group.user_count;

    let headerTopButtonPressEvent = this._viewSystemUsers;
    let headerBottomButtonPressEvent = this._viewDevices;

    if (!viewAccountGroups) {
      if (isDealerLevel) {
        headerRightTopText = "Owners";
        headerRightTopButtonText = "VIEW OWNERS";
        headerRightTopCount = group.account_count;
        headerTopButtonPressEvent = this._viewAccountGroups;
      } else {
        headerRightTopText = "Account Groups";
        headerRightTopButtonText = "VIEW ACCOUNT GROUPS";
        headerRightTopCount = group.account_count;
        headerTopButtonPressEvent = this._viewAccountGroups;
      }
    }

    // In Owner Account View - Show view jobs option
    if (!viewAccountGroups && (viewOwnerDevices || viewOwnerJobs)) {
      headerRightTopText = "Jobs";
      headerRightTopButtonText = "VIEW ASSIGNMENTS";
      headerTopButtonPressEvent = () =>
        this._viewOwnerJobs(currentOwner.cognito_sub);
      headerBottomButtonPressEvent = () =>
        this._viewOwnerDevices(currentOwner.cognito_sub);
    }

    return (
      <View style={[styles.main]}>
        <View style={[styles.content]}>
          {/* Bread Crumbs */}
          <BreadCrumbs
            isUpdating={isFetchingGroup}
            breadcrumbs={this.state.breadcrumbs}
            onCrumbPress={this._onCrumbPress}
            onBackPress={this._onBackPress}
          />
          {/* Header */}
          <View style={[styles.headerRow, styles.card, global.dropShadow]}>
            {isFetchingGroup ? (
              <Spinner color={"red"} style={[{ alignSelf: "center" }]} />
            ) : (
              <>
                {/* Left Col Header */}
                {
                  <View style={[styles.headerLeft]}>
                    <Text style={[styles.companyTitle]}>
                      {viewOwnerDevices || viewOwnerJobs
                        ? getFriendlyUsername(currentOwner)
                        : group.group_name}
                    </Text>
                    {!(viewOwnerDevices || viewOwnerJobs) ? (
                      <>
                        <Text
                          style={[styles.hyperlink]}
                          onPress={this._openAccountWebsite}
                        >
                          {group.website}
                        </Text>
                        <Button
                          style={[styles.headerButton, global.dropShadow]}
                          onPress={this._editAccount}
                        >
                          <Text style={[styles.headerButtonText]}>
                            VIEW COMPANY PROFILE
                          </Text>
                        </Button>
                      </>
                    ) : null}
                  </View>
                }
                {/* Right Col Header */}
                {
                  <View style={[styles.headerRight]}>
                    <View style={[styles.headerRightRow]}>
                      <Text style={[styles.headerText]}>
                        {headerRightTopText} : {headerRightTopCount || 0}
                      </Text>
                      <Button
                        style={[styles.headerButton, global.dropShadow]}
                        onPress={headerTopButtonPressEvent}
                      >
                        <Text style={[styles.headerButtonText]}>
                          {headerRightTopButtonText}
                        </Text>
                      </Button>
                    </View>
                    <View style={[styles.separator]} />
                    <View style={[styles.headerRightRow]}>
                      <Text style={[styles.headerText]}>
                        {headerRightBottomText} : {group.device_count || 0}
                      </Text>
                      <Button
                        style={[styles.headerButton, global.dropShadow]}
                        onPress={headerBottomButtonPressEvent}
                      >
                        <Text style={[styles.headerButtonText]}>
                          {headerRightBottomButtonText}
                        </Text>
                      </Button>
                    </View>
                  </View>
                }
              </>
            )}
          </View>
          {/* Table */}
          <View style={[styles.mainRow, styles.card, global.dropShadow]}>
            {viewAccountGroups && (
              <View style={[styles.accountsTitleRow]}>
                <Text style={[styles.tableTitle]}>
                  {isDealerLevel ? "OWNERS" : "ACCOUNTS"} - (
                  {group.account_count || 0})
                </Text>
                <View>
                  <FormInput
                    fieldId={"searchText"}
                    placeholder={
                      isDealerLevel ? "Search owners..." : "Search accounts..."
                    }
                    onChange={console.log}
                    onInput={
                      isDealerLevel
                        ? this._searchGroupUsers
                        : this._searchGroupChildren
                    }
                    itemStyles={[global.item]}
                    inputStyles={[global.input]}
                  />
                </View>
              </View>
            )}
            {viewSystemUsers && (
              <Text style={[styles.tableTitle]}>
                SYSTEM USERS - ({group.user_count || 0})
              </Text>
            )}
            {(viewAccountDevices || viewOwnerDevices) && (
              <View style={[styles.devicesTitleRow]}>
                <Text style={[styles.tableTitle]}>
                  STATIONS - ({group.device_count || 0})
                </Text>
                <View>
                  <FormInput
                    fieldId={"searchText"}
                    placeholder={"Search devices..."}
                    onChange={console.log}
                    onInput={this._searchDevices}
                    itemStyles={[global.item]}
                    inputStyles={[global.input]}
                  />
                </View>
              </View>
            )}
            {viewOwnerJobs && (
              <Text style={[styles.tableTitle]}>
                JOBS - ({jobs.length || 0})
              </Text>
            )}
            <Table
              headers={this._generateTableHeaders()}
              rowData={this._generateTableData()}
              isLoading={tableIsLoading}
            />
          </View>
          {/* Breadcrums / Pagination */}
          {(viewAccountDevices || viewOwnerDevices) && (
            <View
              style={[styles.breadcrumbRow, styles.card, global.dropShadow]}
            >
              <Pagination
                total={searchCount}
                pageSize={DEVICE_PAGE_SIZE}
                onNav={this._getDevicesPage}
              />
            </View>
          )}
        </View>

        {/* MODALS */}
        <View>
          {/* CREATE NEW CLIENT ACCOUNT */}
          <Modal
            isVisible={showCreateNewAccountModal}
            title={editMode ? "EDIT ACCOUNT" : "CREATE NEW CLIENT ACCOUNT"}
            hideExitIcon={true}
            content={
              !showCreateNewAccountModal ? null : (
                <AddEditAccountModalContent
                  onCancel={this._closeCreateNewClientAccountModal}
                  parentGroup={group}
                  editMode={editMode}
                  formData={editAccount}
                />
              )
            }
          />

          {/* CREATE SYSTEM USER */}
          <Modal
            isVisible={showCreateUserAccountModal}
            hideTitle={true}
            hideExitIcon={true}
            content={
              !showCreateUserAccountModal ? null : (
                <CreateUserAccountModalContent
                  onCancel={this._closeCreateUserAccountModal}
                  groupId={createUserData.groupId}
                  create={inviteSystemUser}
                  createInProgress={isInvitingSystemUser}
                  createFailed={inviteSystemUserFailed}
                  createError={inviteSystemUserErrorMessage}
                  confirmButtonLabel={createUserData.confirmButtonLabel}
                  title={createUserData.title}
                  subTitle={createUserData.subTitle}
                  isOwnerInvite={createUserData.isOwnerInvite}
                />
              )
            }
          />

          {/* REMOVE SYSTEM USER */}
          <Modal
            isVisible={showConfirmRemoveUserModal}
            title={"REMOVE USER"}
            hideExitIcon={true}
            content={
              !showConfirmRemoveUserModal ? null : (
                <ConfirmDeleteModalContent
                  onCancel={this._closeConfirmRemoveUserModal}
                  onConfirm={this._deleteSystemUser}
                  confirmationText={
                    "Removing this user will remove their ability to perform administrative actions for this group."
                  }
                  confirmButtonText={"REMOVE USER"}
                  cancelButtonText={"DO NOT REMOVE USER"}
                />
              )
            }
          />

          {/* VIEW DEVICE */}
          <Modal
            isVisible={showViewDeviceModal}
            title={viewDeviceId || ""}
            onClose={this._closeViewDeviceModal}
            content={
              !showViewDeviceModal ? null : (
                <ViewDeviceModalContent
                  device={groupDevices[viewDeviceIndex]}
                  group={group}
                  onCancel={this._closeViewDeviceModal}
                  onNavigateToGroup={this._viewAccount}
                  onNavigateToOwner={this._viewOwnerDevices}
                  onReassignDevice={this._showReassignDeviceModal}
                />
              )
            }
          />

          {/* REASSIGN DEVICE */}
          <Modal
            isVisible={showReassignDeviceModal}
            title={"REASSIGN DEVICE"}
            subTitle={
              "Click the edit button next to the group you'd like to change"
            }
            onClose={this._closeReassignDeviceModal}
            hideExitIcon={true}
            content={
              !showReassignDeviceModal ? null : (
                <ReassignDeviceModalContent
                  group={group}
                  device={groupDevices[viewDeviceIndex]}
                  onCancel={this._closeReassignDeviceModal}
                />
              )
            }
          />
        </View>

        {/*  FOOTER */}
        <View style={[styles.footer]}>
          {/* Account Groups */}
          {viewAccountGroups && (
            <>
              {isDealerLevel ? (
                <Button
                  style={[global.confirmationButton, { height: 70 }]}
                  onPress={this._showCreateOwnerAccountModal}
                >
                  <Text
                    style={[global.confirmationButtonText, { fontSize: 24 }]}
                  >
                    CREATE NEW OWNER ACCOUNT
                  </Text>
                </Button>
              ) : (
                <Button
                  style={[global.confirmationButton, { height: 70 }]}
                  onPress={this._showCreateNewClientAccountModal}
                >
                  <Text
                    style={[global.confirmationButtonText, { fontSize: 24 }]}
                  >
                    CREATE NEW CLIENT ACCOUNT
                  </Text>
                </Button>
              )}
            </>
          )}
          {/* System Users */}
          {viewSystemUsers && (
            <Button
              style={[global.confirmationButton, { height: 70 }]}
              onPress={this._showCreateUserAccountModal}
            >
              <Text style={[global.confirmationButtonText, { fontSize: 24 }]}>
                CREATE SYSTEM USER
              </Text>
            </Button>
          )}
          {viewOwnerDevices && deviceLoadList.length >= 1 && (
            <Button
              style={[global.confirmationButton, { height: 70 }]}
              onPress={this._loadSelecteDevices}
            >
              <Text style={[global.confirmationButtonText, { fontSize: 24 }]}>
                LOAD SELECTED DEVICES IN DASHBOARD
              </Text>
            </Button>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userParentId: getUserParentId(state),
    group: state.admin.group,
    groupChildren: state.admin.groupChildren,
    groupUsers: state.admin.groupUsers,

    isFetchingGroup: state.admin.isFetchingGroup,
    fetchGroupFailed: state.admin.fetchGroupFailed,
    fetchGroupErrorMessage: state.admin.fetchGroupErrorMessage,

    isFetchingGroupChildren: state.admin.isFetchingGroupChildren,
    fetchGroupChildrenFailed: state.admin.fetchGroupChildrenFailed,
    fetchGroupChildrenErrorMessage: state.admin.fetchGroupChildrenErrorMessage,

    isFetchingGroupUsers: state.admin.isFetchingGroupUsers,
    fetchGroupUsersFailed: state.admin.fetchGroupUsersFailed,
    fetchGroupUsersErrorMessage: state.admin.fetchGroupUsersErrorMessage,

    isInvitingSystemUser: state.admin.isInvitingSystemUser,
    inviteSystemUserFailed: state.admin.inviteSystemUserFailed,
    inviteSystemUserErrorMessage: state.admin.inviteSystemUserErrorMessage,

    isRemovingSystemUser: state.admin.isRemovingSystemUser,
    removeSystemUserFailed: state.admin.removeSystemUserFailed,
    removeSystemUserErrorMessage: state.admin.removeSystemUserErrorMessage,
    removingSystemUserCognitoSub: state.admin.removingSystemUserCognitoSub,

    isFetchingGroupDevices: state.admin.isFetchingGroupDevices,
    fetchGroupDevicesFailed: state.admin.fetchGroupDevicesFailed,
    fetchGroupDevicesErrorMessage: state.admin.fetchGroupDevicesErrorMessage,
    groupDevices: state.admin.groupDevices,
    searchCount: state.admin.searchCount,

    isAssigningDevice: state.admin.isAssigningDevice,

    isGettingUserJobs: state.job.isGettingUserJobs,
    jobs: state.job.jobs,
    siteSubscriptionSuccess: state.siteDetails.siteSubscriptionSuccess,
    siteSubscriptionFail: state.siteDetails.siteSubscriptionFail,
    userInformation: state.user.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroup: (groupId) => dispatch(getGroup(groupId)),
    getGroupChildren: (groupId) => dispatch(getGroupChildren(groupId)),
    getGroupUsers: (groupId) => dispatch(getGroupUsers(groupId)),
    inviteSystemUser: (systemUserInfo, groupId) =>
      dispatch(inviteSystemUser(systemUserInfo, groupId)),
    removeSystemUser: (groupId, cognitoSub) =>
      dispatch(removeSystemUser(groupId, cognitoSub)),
    searchDevices: (searchText, groupId, limit, offset) =>
      dispatch(searchDevices(searchText, groupId, limit, offset)),
    searchGroupChildren: (searchText, groupId, limit, offset) =>
      dispatch(searchGroupChildren(searchText, groupId, limit, offset)),
    searchGroupUsers: (searchText, groupId, limit, offset) =>
      dispatch(searchGroupUsers(searchText, groupId, limit, offset)),
    getUserJobs: (cognitoSub) => dispatch(getUserJobs(cognitoSub)),
    subscriptionSite: (cognitoSub, isSubscribed) =>
      dispatch(subscriptionSite(cognitoSub, isSubscribed)),
  };
};

Accounts.propTypes = {
  userParentId: PropTypes.string.isRequired,

  getGroup: PropTypes.func.isRequired,
  isFetchingGroup: PropTypes.bool.isRequired,
  fetchGroupFailed: PropTypes.bool.isRequired,
  fetchGroupErrorMessage: PropTypes.string.isRequired,
  group: PropTypes.any.isRequired,

  getGroupChildren: PropTypes.func.isRequired,
  searchGroupChildren: PropTypes.func.isRequired,
  isFetchingGroupChildren: PropTypes.bool.isRequired,
  fetchGroupChildrenFailed: PropTypes.bool.isRequired,
  fetchGroupChildrenErrorMessage: PropTypes.string.isRequired,
  groupChildren: PropTypes.array.isRequired,

  getGroupUsers: PropTypes.func.isRequired,
  searchGroupUsers: PropTypes.func.isRequired,
  isFetchingGroupUsers: PropTypes.bool.isRequired,
  fetchGroupUsersFailed: PropTypes.bool.isRequired,
  fetchGroupUsersErrorMessage: PropTypes.any.isRequired,
  groupUsers: PropTypes.array.isRequired,

  inviteSystemUser: PropTypes.func.isRequired,
  isInvitingSystemUser: PropTypes.bool.isRequired,
  inviteSystemUserFailed: PropTypes.bool.isRequired,
  inviteSystemUserErrorMessage: PropTypes.any.isRequired,

  removeSystemUser: PropTypes.func.isRequired,
  isRemovingSystemUser: PropTypes.bool.isRequired,
  removeSystemUserFailed: PropTypes.bool.isRequired,
  removeSystemUserErrorMessage: PropTypes.any.isRequired,
  removingSystemUserCognitoSub: PropTypes.string.isRequired,

  searchDevices: PropTypes.func.isRequired,
  isFetchingGroupDevices: PropTypes.bool.isRequired,
  fetchGroupDevicesFailed: PropTypes.bool.isRequired,
  fetchGroupDevicesErrorMessage: PropTypes.any.isRequired,
  groupDevices: PropTypes.array.isRequired,
  searchCount: PropTypes.number.isRequired,

  isAssigningDevice: PropTypes.bool.isRequired,

  getUserJobs: PropTypes.func.isRequired,
  isGettingUserJobs: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
