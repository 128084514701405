import { StyleSheet } from 'react-native';
import { roboto, textLight, yellow } from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    height: '100%',
  },
  appHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#1A1A1A',
    width: '100%',
    height: '100%',
  },
  leftHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
  },
  drawerMenuButton: {
    marginRight: 0,
  },
  jobNameText: {
    fontFamily: roboto,
    fontSize: 24,
    color: yellow,
    letterSpacing: 1.6,
  },
  rightHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 15,
  },
  usernameText: {
    fontSize: 18,
    color: textLight,
    fontFamily: roboto,
    letterSpacing: 0.8,
  },
});
