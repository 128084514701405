import { StyleSheet } from 'react-native';
import {
  robotoCondensed,
  textLight,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  leftColumn: {
    height: '100%',
  },
  rightColumn: {
    height: 525,
  },
  powerButtonContainer: {
    marginRight: 8,
    width: 60,
  },
  powerButton: {
    height: 60,
    width: 60,
  },
  powerIcon: {
    color: '#424242',
  },
  settingsButtonContainer: {
    alignItems: 'flex-end',
  },
  speedPressureContainer: {
    flex: 1,
  },
  powerSpeedPressureContainer: {
    alignItems: 'center',
    paddingBottom: 10,
  },
  engineStateContainer: {
    alignItems: 'center',
  },
  engineSpeedLabel: {
    fontSize: 18,
    marginLeft: 15,
    marginTop: 1,
  },

  engineIcon: {
    height: 62,
    width: 75,
    marginLeft: 15,
    marginRight: 15,
  },
  warningErrorContainer: {
    alignItems: 'center',
    marginTop: 7,
  },

  inletOutletContainer: {
    marginTop: 6,
    marginRight: 25,
    alignItems: 'center',
  },
  inletContainer: {
    alignItems: 'center',
  },
  outletContainer: {
    alignItems: 'center',
  },
  inletOutletTitle: {
    fontSize: 14,
    fontFamily: robotoCondensed,
    color: textLight,
  },
  inletOutletUnit: {
    fontSize: 18,
    fontFamily: robotoCondensed,
    color: textLight,
    marginLeft: 15,
    marginTop: 2,
  },

  inletOutletValues: {
    alignItems: 'center',
  },
  inletOutletValue: {
    alignItems: 'center',
    marginRight: 20,
  },
  vitalsValvesContainer: {
    borderTopWidth: 1,
    borderColor: '#777777',
    marginTop: 5,
    paddingTop: 5,
    width: 208,
  },
  container: {
    borderWidth: 1,
    borderColor: 'black',
  },
  headerRow: {
    borderBottomWidth: 1,
    borderColor: 'black',
  },
  headerCell: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    borderWidth: 1,
    borderColor: 'black',
  },
  headerText: {
    fontSize: 12,
  },
  dataRow: {
    borderBottomWidth: 1,
    borderColor: 'black',
  },
  cell: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    borderWidth: 1,
    borderColor: 'black',
  },
  cellText: {
    fontSize: 12,
  },
});
