// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getTelemetryForDiversionBitPack, getAlisoViejoTelemetryForDevice, getTelemetryForEngineBitPack, isEnginePresent } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import MiniTelemetryValue from '_components/device_card/MiniTelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/MiniDiversionStatusVitals.style';

import { defaultVitalsSettings } from '_components/device_settings/MiniDiversionAlarmStatusVitalsSettings.component';

const MAX_VITALS = 20;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <Text style={[styles.vitalsValue]}>{value}</Text>
    </Row>
  );
}

class MiniDiversionAlarmStatusVitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      deviceSettings,
      isUserFetchingDeviceCardSettings,
      engineData,
      centralData,
      REMOTE_DATA_TX,
      isEnginePresent,
      engineNumber,
      statusData,
    } = this.props;

    const {
      alarmShutdownRegister1BitPack,
    } = statusData;

    const {
      b_high_system_var_1_alarm,
      b_hi_hi_float_level_active,
      b_low_system_var_1_alarm,
      b_lo_lo_float_level_active,
      b_back_wash_active,
      b_level_transducer_fail_active,
      b_high_voltage_alarm_active,
      b_low_voltage_alarm_active,
      b_pump_1_summary_fail,
      b_Controller_FAILURE,
    } = alarmShutdownRegister1BitPack;

    let highLevel = "-";
    if(b_high_system_var_1_alarm){
      highLevel = 'ON';
    }else{
      highLevel = 'OFF';
    }

    let highLevelSwitch = "-";
    if(b_hi_hi_float_level_active){
      highLevelSwitch = 'ON';
    }else{
      highLevelSwitch = 'OFF';
    }

    let lowLevel = "-";
    if(b_low_system_var_1_alarm){
      lowLevel = 'ON';
    }else{
      lowLevel = 'OFF';
    }

    let lowLevelSwitch = "-";
    if(b_lo_lo_float_level_active){
      lowLevelSwitch = 'ON';
    }else{
      lowLevelSwitch = 'OFF';
    }

    let backwash = "-";
    if(b_back_wash_active){
      backwash = 'ON';
    }else{
      backwash = 'OFF';
    }

    let transducerFailure = "-";
    if(b_level_transducer_fail_active){
      transducerFailure = 'ON';
    }else{
      transducerFailure = 'OFF';
    }

    let highVoltage = "-";
    if(b_high_voltage_alarm_active){
      highVoltage = 'ON';
    }else{
      highVoltage = 'OFF';
    }

    let lowVoltage = "-";
    if(b_low_voltage_alarm_active){
      lowVoltage = 'ON';
    }else{
      lowVoltage = 'OFF';
    }

    let pumpRunFail = "-";
    if(b_pump_1_summary_fail){
      pumpRunFail = 'ON';
    }else{
      pumpRunFail = 'OFF';
    }

    let controllerFault = "-";
    if(b_Controller_FAILURE){
      controllerFault = 'ON';
    }else{
      controllerFault = 'OFF';
    }


    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;


    //Note: for the engine hours and water level it's divided by 10 to give the correct ammount. We may want to perform this or other calculations outside
    // of the "vitals" const.
    const vitals = [
      { id: 'highLevel', label: 'High Level', value: highLevel, unit: '' },
      { id: 'highLevelSwitch', label: 'High Level Switch', value: highLevelSwitch, unit: '' },
      { id: 'lowLevel', label: 'Low Level', value: lowLevel, unit: '' },
      { id: 'lowLevelSwitch', label: 'Low Level Switch', value: lowLevelSwitch, unit: '' },
      { id: 'backwash', label: 'Backwash', value: backwash, unit: '' },
      { id: 'transducerFailure', label: 'Transducer Failure', value: transducerFailure, unit: '' },
      { id: 'highVoltage', label: 'High Voltage', value: highVoltage, unit: '' },
      { id: 'lowVoltage', label: 'Low Voltage', value: lowVoltage, unit: '' },
      { id: 'pumpRunFail', label: 'Pump Run/Fail', value: pumpRunFail, unit: '' },
      { id: 'controllerFault', label: 'Controller Fault', value: controllerFault, unit: '' },
    ];


    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value}
            unit={item.unit}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    engineData: getTelemetryForEngineBitPack(state, ownProps.deviceId),
    isEnginePresent: isEnginePresent(state, ownProps.deviceId),
    statusData: getTelemetryForDiversionBitPack(state, ownProps.deviceId),
  };
};

MiniDiversionAlarmStatusVitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(MiniDiversionAlarmStatusVitals);
