import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    size: 6,
  },
  buttonRow: {
    alignItems: 'center',
    width: '100%',
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    width: 180,
    height: 48,
  },
  
  firstButtonGroup: {
    marginLeft: 40,
    marginRight: 10,
    width: 180,
    height: 48,
  },

  buttonText: {
    color: '#FAFAFA',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stopAllButton: {
    backgroundColor: '#C82A32',
  },
  idleAllButton: {
    backgroundColor: '#EEB310',
  },
  runAllButton: {
    backgroundColor: '#006644',
  },

  maxAllButton: {
    backgroundColor: '#E7E6E6',
  },

  minAllButton: {
    backgroundColor: '#E7E6E6',
  },

  valvesAllButton: {
    backgroundColor: '#616161',
  },

  vitalsAllButton: {
    backgroundColor: '#616161',
  },

  maxMinbuttonText: {
    color: '#000000',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  vitalButtonText: {
    color: '#E7E6E6',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  valveButtonText: {
    color: '#E7E6E6',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

});
