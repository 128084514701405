// React Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Components
import
  AccessControl,
  { ADMIN, MANUFACTURER, CREW_LEADER, CREW_MEMBER, DEALER, OWNER }
from '_components/auth/AccessControl.component';
import AppVersion from '_components/common/AppVersion.component';

// Routing
import { navigate, getActiveRouteName } from '_routes/RootNavigation';


// UI Framework
import { Dimensions, Image } from 'react-native';
import {
  List,
  ListItem,
  Spinner,
  Text,
  View,
} from 'native-base';

// Style
import { styles } from '_components/common/SideBar.style';
import { } from '../auth/AccessControl.component';

// Show / Hide side bar text depending on window size.
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

class SideBarItem extends Component {

  constructor(props) {
    super(props);

    this._navigate = this._navigate.bind(this);
  }

  /**
   * Navigate to the appropriate page.
   */
  _navigate() {
    navigate(this.props.route);
  }

  render() {
    const { activeRoute, route, iconOn, iconOff, iconOnly } = this.props;
    const isActive = activeRoute === route;

    const icon = isActive ? iconOn : iconOff;

    return (
      <ListItem
        button
        selected={false}
        style={[styles.sideBarItem, isActive ? styles.sideBarItemActive : {}] }
        activeOpacity={1}
        onPress={this._navigate}
      >
        <View style={[styles.iconContainer]}>
          { icon }
        </View>
        {
          !iconOnly &&
            <View style={[styles.textContainer]}>
              <Text style={[ isActive ? styles.sideBarItemTextActive : styles.sideBarItemText]}>
                { route }
              </Text>
            </View>
        }
      </ListItem>
    );
  }
}

SideBarItem.propTypes = {
  activeRoute: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  iconOn: PropTypes.any.isRequired,
  iconOff: PropTypes.any.isRequired,
  iconOnly: PropTypes.bool.isRequired,
};

class SideBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dimensions: {
        window,
        screen,
      },
    };

    this._onDimensionChange = this._onDimensionChange.bind(this);
  }

  /**
   * Event handler for when the dimensions of the window/screen change. This
   * can happen if a user rotates their device or changes the size of their
   * browser.
   */
  _onDimensionChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  };

  /**
   * When the component mounts, add an event listener to handle dimension
   * changes.
   */
  componentDidMount() {
    Dimensions.addEventListener('change', this._onDimensionChange);
  }

  /**
   * Clean up the dimension change event handler when the component
   * unmounts.
   */
  componentWillUnmount() {
    Dimensions.removeEventListener('change', this._onDimensionChange);
  }

  render() {
    const { state, isFetchingUserInfo } = this.props;
    const { dimensions } = this.state;
    const route = getActiveRouteName(state);

    const width = dimensions.window.width;
    const iconOnly = width <= 1024;

    const routes = [
      {
        activeRoute: route,
        iconOnly,
        route: 'Dashboard',
        iconOn: (<Image style={{ width: 30, height: 30 }} source={require('_assets/images/dashboard-icon-on.png')} />),
        iconOff: (<Image style={{ width: 30, height: 30 }} source={require('_assets/images/dashboard-icon-off.png')} />),
        allowedGroups: [OWNER, CREW_LEADER, CREW_MEMBER],
      },
      {
        activeRoute: route,
        iconOnly,
        route: 'Assignments',
        iconOn: (<Image style={{ width: 23, height: 30 }} source={require('_assets/images/jobs-icon-on.png')} />),
        iconOff: (<Image style={{ width: 23, height: 30 }} source={require('_assets/images/jobs-icon-off.png')} />),
        allowedGroups: [OWNER, CREW_LEADER, CREW_MEMBER],
      },
      {
        activeRoute: route,
        iconOnly,
        route: 'Crew Members',
        iconOn: (<Image style={{ width: 28, height: 26 }} source={require('_assets/images/crew-members-icon-on.png')} />),
        iconOff: (<Image style={{ width: 28, height: 26 }} source={require('_assets/images/crew-members-icon-off.png')} />),
        allowedGroups: [OWNER],
      },
      {
        activeRoute: route,
        iconOnly,
        route: 'Stations',
        iconOn: (<Image style={{ width: 33, height: 33 }} source={require('_assets/images/devices-icon-on.png')} />),
        iconOff: (<Image style={{ width: 33, height: 33 }} source={require('_assets/images/devices-icon-off.png')} />),
        allowedGroups: [OWNER],
      },
      {
        activeRoute: route,
        iconOnly,
        route: 'Notifications',
        iconOn: (<Image style={{ width: 32, height: 32 }} source={require('_assets/images/notifications-icon-on.png')} />),
        iconOff: (<Image style={{ width: 32, height: 32 }} source={require('_assets/images/notifications-icon-off.png')} />),
        allowedGroups: [ADMIN, MANUFACTURER, DEALER, OWNER, CREW_LEADER],
      },
      {
        activeRoute: route,
        iconOnly,
        route: 'Accounts',
        iconOn: (<Image style={{ width: 33, height: 33 }} source={require('_assets/images/accounts-icon-on.png')} />),
        iconOff: (<Image style={{ width: 33, height: 33 }} source={require('_assets/images/accounts-icon-off.png')} />),
        allowedGroups: [ADMIN, MANUFACTURER, DEALER],
      },
      // {
      //   activeRoute: route,
      //   iconOnly,
      //   route: 'Sites',
      //   iconOn: (<Image style={{ width: 38, height: 38 }} source={require('_assets/images/site-icon-on.png')} />),
      //   iconOff: (<Image style={{ width: 38, height: 38 }} source={require('_assets/images/site-icon-off.png')} />),
      //   allowedGroups: [ADMIN, MANUFACTURER, OWNER, CREW_LEADER, CREW_MEMBER],
      // },
      // {
      //   activeRoute: route,
      //   iconOnly,
      //   route: 'Feedback',
      //   iconOn: (<Image style={{ width: 30, height: 27 }} source={require('_assets/images/feedback-icon-on.png')} />),
      //   iconOff: (<Image style={{ width: 30, height: 27 }} source={require('_assets/images/feedback-icon-off.png')} />),
      //   allowedGroups: [ADMIN, MANUFACTURER, DEALER, OWNER, CREW_LEADER],
      // },
      {
        activeRoute: route,
        iconOnly,
        route: 'Home',
        iconOn: (<Image style={{ width: 26, height: 24 }} source={require('_assets/images/home-icon-on.png')} />),
        iconOff: (<Image style={{ width: 26, height: 24 }} source={require('_assets/images/home-icon-off.png')} />),
        allowedGroups: [ADMIN, MANUFACTURER, DEALER, OWNER, CREW_LEADER],
      },
    ];

    const navItems = [];
    routes.forEach((route, index) => {
      navItems.push((
        <AccessControl key={index} allowedGroups={route.allowedGroups}>
          <SideBarItem
            key={route.route}
            activeRoute={route.activeRoute}
            route={route.route}
            iconOn={route.iconOn}
            iconOff={route.iconOff}
            iconOnly={route.iconOnly}
          />
        </AccessControl>
      ));
    });

    return (
      <View style={ [styles.sideBar] }>
        <List>
          {
            isFetchingUserInfo ?
              <Spinner color={'red'} />
              :
              <>
                { navItems }
              </>
          }
        </List>
        <View style={ [styles.versionContainer] }>
          <AppVersion />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingUserInfo: state.user.isFetchingUserInfo,
  };
};

SideBar.propTypes = {
  state: PropTypes.any.isRequired,
  isFetchingUserInfo: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(SideBar);

