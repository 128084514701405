// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonGroup from '_components/device_card/ButtonGroup.component';
import EngineState from '_components/device_card/EngineState.component';
import Indicator from '_components/device_card/Indicator.component';
import PressureControl from '_components/device_card/PressureControl.component';
import MiniPressureControl from '_components/device_card/MiniPressureControl.component';
import SpeedControl from '_components/device_card/SpeedControl.component';
import MiniSpeedControl from '_components/device_card/MiniSpeedControl.component';

import TelemetryValue from '_components/device_card/TelemetryValue.component';
import MiniEngineVitals from '_components/device_card/MiniEngineVitals.component';
import Valves from '_components/device_card/Valves.component';

// Redux
import { connect } from 'react-redux';
import { executeCommand } from '_redux/iot/actions';
import { getTelemetryForDevice, isWarningOnForDevice, isAlarmOnForDevice, isEngineRunningForDevice } from '_redux/iot/selectors';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { TELEMETRY_DEFAULT_VALUE } from '_redux/iot/selectors';

// UI Framework
import { Text, View } from 'native-base';
import { Image, TouchableOpacity } from 'react-native';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { RadialGauge } from 'react-canvas-gauges';

// Styling
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/MiniCurrentEngine.style';

class MiniCurrentEngine extends Component {

  constructor(props) {
    super(props);

    this.state = {
      powerButtonOn: false,
      vitalsMode: true,
    };


    this._handlePowerOff = this._handlePowerOff.bind(this);
    this._handlePowerOn = this._handlePowerOn.bind(this);

    this._handlePressureModePress = this._handlePressureModePress.bind(this);
    this._handleSpeedModePress = this._handleSpeedModePress.bind(this);

    this._handleVitalsModePress = this._handleVitalsModePress.bind(this);
    this._handleValvesModePress = this._handleValvesModePress.bind(this);
  }

  /**
   *
   */
  _handlePowerOn() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.START_ENGINE);

    this.setState({
      powerButtonOn: true,
    });
  }
  /**
 *
 */
  _handlePowerOff() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.STOP_ENGINE);

    this.setState({
      powerButtonOn: false
    });
  }

  /**
   *
   */
  _handleSpeedModePress() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.SET_THROTTLE_MODE, {
      data: {
      },
    });
  }

  /**
   *
   */
  _handlePressureModePress() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.SET_THROTTLE_MODE, {
      data: {
      },
    });
  }

  /**
   *
   */
  _handleVitalsModePress() {
    this.setState({
      vitalsMode: true,
    });
  }

  /**
   *
   */
  _handleValvesModePress() {
    this.setState({
      vitalsMode: false,
    });
  }

  render() {
    const {
      deviceId,
      telemetry,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      engineNumber,
      REMOTE_DATA_TX,
    } = this.props;

    const {
      vitalsMode,
    } = this.state;

    let rpmSpeed = 0;

    if (engineNumber === 0) {
      const {
        e1_rpm
      } = REMOTE_DATA_TX
      rpmSpeed = e1_rpm;
    } else if (engineNumber === 1) {
      const {
        e2_rpm
      } = REMOTE_DATA_TX
      rpmSpeed = e2_rpm;
    } else if (engineNumber === 2) {
      const {
        e3_rpm
      } = REMOTE_DATA_TX
      rpmSpeed = e3_rpm;
    } else if (engineNumber === 3) {
      const {
        e4_rpm
      } = REMOTE_DATA_TX
      rpmSpeed = e4_rpm;
    } else if (engineNumber === 4) {
      const {
        e5_rpm
      } = REMOTE_DATA_TX
      rpmSpeed = e5_rpm;
    }

    let engineIcon = require('_assets/images/engine-icon.png');
    if (isAlarmOnForDevice) {
      engineIcon = require('_assets/images/engine-icon-error.png');
    } else if (isWarningOnForDevice) {
      engineIcon = require('_assets/images/engine-icon-warn.png');
    }

    return (
      <Grid>
        {/* Left Column */}
        <Col style={[styles.leftColumn]}>
          {
            <MiniEngineVitals engineNumber={engineNumber} deviceId={deviceId} />
          }
          <RadialGauge
            width={150}
            height={150}
            startAngle={90}
            ticksAngle={180}
            units={false}
            title={rpmSpeed + ' RPM'}
            value={rpmSpeed}
            minValue={0}
            maxValue={3000}
            majorTicks={['0', '500', '1500', '2000', '2500', '3000']}
            minorTicks={5}
            highlights={[{ from: "6", to: "8", color: "rgba(0, 0, 0, 0)" }]}
            needleStart={75}
            needleEnd={99}
            needleWidth={5}
            needleType='line'
            borders={false}
            needleCircleOuter={false}
            needleCircleInner={false}
            valueBox={false}
            valueText={false}
            colorPlate={'rgba(0,0,0,0)'}
            colorMinorTicks={'rgba(255,255,255,1)'}
            colorMajorTicks={'rgba(255,255,255,1)'}
            colorUnits={'rgba(255,255,255,1)'}
            colorTitle={'rgba(255,255,255,1)'}
            colorNumbers={'rgba(255,255,255,1)'}
            animationRule='linear'
            animationDuration='1500'
          ></RadialGauge>
        </Col>

      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    telemetry: getTelemetryForDevice(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
    REMOTE_DATA_TX: getTelemetryForDevice(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
  };
};

MiniCurrentEngine.propTypes = {
  deviceId: PropTypes.string.isRequired,
  executeCommand: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniCurrentEngine);
