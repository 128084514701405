// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonGroup from '_components/device_card/ButtonGroup.component';
import EngineState from '_components/device_card/EngineState.component';
import Indicator from '_components/device_card/Indicator.component';
import PressureControl from '_components/device_card/PressureControl.component';
import SpeedControl from '_components/device_card/SpeedControl.component';
import TelemetryValue from '_components/device_card/TelemetryValue.component';
import DiversionSewerFlowrateVitals from '_components/device_card/DiversionSewerFlowrateVitals.component';
import DiversionTreatedFlowrateVitals from '_components/device_card/DiversionTreatedFlowrateVitals.component';
import Valves from '_components/device_card/Valves.component';

// Redux
import { connect } from 'react-redux';
import { executeCommand } from '_redux/iot/actions';
import { getAlisoViejoTelemetryForDevice, isWarningOnForDevice, isAlarmOnForDevice, isEngineRunningForDevice, getWaterLevelForDevice } from '_redux/iot/selectors';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { TELEMETRY_DEFAULT_VALUE } from '_redux/iot/selectors';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Image, TouchableOpacity } from 'react-native';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { LinearGauge } from 'react-canvas-gauges';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { RadialGauge } from 'react-canvas-gauges';


// Styling
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/CurrentDiversionFlowrate.style';

class CurrentDiversionFlowrate extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  convertEpochToLocalTime(epochTimestamp) {
    const date = new Date(epochTimestamp);

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };

    const localTime = date.toLocaleTimeString([], options); // Convert to formatted local time string
    return localTime;
  }


  render() {
    const {
      deviceId,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      engineNumber,
      REMOTE_DATA_TX,
      waterLevelDataNew,
    } = this.props;


    const {
      treated_flow_total_hi,
      treated_flow_total_low,
      sewer_flow_total_hi,
      sewer_flow_total_low,
      converted_analog_input_3: sewerFlowrateGPM,
      converted_analog_input_4: treatedFlowrateGPM,
    } = REMOTE_DATA_TX

    const {
      vitalsMode,
    } = this.state;



    return (
      <Grid>
        {/* Left Column */}
        <Col size={100} style={[styles.leftColumn]}>
          <Text>Treated</Text>
          <RadialGauge
            width={200}
            height={200}
            startAngle={90}
            ticksAngle={180}
            units={false}
            title={'GPM x 1000'}
            value={treatedFlowrateGPM}
            minValue={0}
            maxValue={3000}
            majorTicks={['0', '5', '15', '20', '25', '30']}
            minorTicks={5}
            highlights={[{ from: "6", to: "8", color: "rgba(0, 0, 0, 0)" }]}
            needleStart={75}
            needleEnd={99}
            needleWidth={5}
            needleType='line'
            borders={false}
            needleCircleOuter={false}
            needleCircleInner={false}
            valueBox={false}
            valueText={false}
            colorPlate={'rgba(0,0,0,0)'}
            colorMinorTicks={'rgba(255,255,255,1)'}
            colorMajorTicks={'rgba(255,255,255,1)'}
            colorUnits={'rgba(255,255,255,1)'}
            colorTitle={'rgba(255,255,255,1)'}
            colorNumbers={'rgba(255,255,255,1)'}
            animationRule='linear'
            animationDuration='1500'
          ></RadialGauge>
          <View style={styles.diversionFlowrateVitalsPosition}>
            <DiversionTreatedFlowrateVitals deviceId={deviceId} />
          </View>
          <Text>Sewer</Text>
          <RadialGauge
            width={200}
            height={200}
            startAngle={90}
            ticksAngle={180}
            units={false}
            title={'GPM x 1000'}
            value={sewerFlowrateGPM}
            minValue={0}
            maxValue={70000}
            majorTicks={['0', '10', '20', '30', '40', '50', '60', '70']}
            minorTicks={5}
            highlights={[{ from: "6", to: "8", color: "rgba(0, 0, 0, 0)" }]}
            needleStart={75}
            needleEnd={99}
            needleWidth={5}
            needleType='line'
            borders={false}
            needleCircleOuter={false}
            needleCircleInner={false}
            valueBox={false}
            valueText={false}
            colorPlate={'rgba(0,0,0,0)'}
            colorMinorTicks={'rgba(255,255,255,1)'}
            colorMajorTicks={'rgba(255,255,255,1)'}
            colorUnits={'rgba(255,255,255,1)'}
            colorTitle={'rgba(255,255,255,1)'}
            colorNumbers={'rgba(255,255,255,1)'}
            animationRule='linear'
            animationDuration='1500'
          ></RadialGauge>
          <View style={styles.diversionFlowrateVitalsPosition}>
            <DiversionSewerFlowrateVitals deviceId={deviceId} />
          </View>

        </Col>

      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    waterLevelDataNew: getWaterLevelForDevice(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
  };
};

CurrentDiversionFlowrate.propTypes = {
  deviceId: PropTypes.string.isRequired,
  executeCommand: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDiversionFlowrate);
