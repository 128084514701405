// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { executeCommand, setSetPoints } from '_redux/iot/actions';
import { CONTROL_POINTS, SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { getTelemetryForDevice } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';

// Components
import JobTimer from '_components/jobs/JobTimer.component';

// Native Base Components
import {
  Button,
  Text,
} from 'native-base';

// Layout
import { Row, Grid } from 'react-native-easy-grid';

// Style
import { styles } from '_components/device_card/DashboardFooter.style';
import { mobileStyles } from '_components/device_card/DashboardFooterMobile.style';

class DashboardFooter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 1730
    }

    this._runAll = this._runAll.bind(this);
    this._idleAll = this._idleAll.bind(this);
    this._stopAll = this._stopAll.bind(this);
    this.updateIsMobile = this.updateIsMobile.bind(this);
  }

  updateIsMobile() {
    if (window.innerWidth < 1730) {
      this.setState({ isMobile: true })
    }
    else {
      this.setState({ isMobile: false })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile);
}

componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile);
}

  /**
   * Event handler for the Run All button. This will execute a
   * set maintain speed command and update the commanded engine speed
   * set point.
   */
  _runAll() {
    const { deviceIds, deviceSettings, deviceTelemetry, executeCommand, setSetPoints } = this.props;

    deviceIds.forEach((deviceId) => {
      const telemetry = deviceTelemetry[deviceId];

      if(telemetry.mode === 0) {
        const settings = deviceSettings[deviceId];

        executeCommand(
          deviceId,
          SUPPORTED_COMMANDS.SET_MAINTAIN_SPEED,
          {
            data: {
              speed: settings.runRPM,
            },
          },
        );

        setSetPoints(deviceId, {
          C: {
            [CONTROL_POINTS.COMMANDED_ENGINE_SPEED]: settings.runRPM,
            },
          },
        )

      } else if (telemetry.mode === 1) {

        executeCommand(
          deviceId,
          SUPPORTED_COMMANDS.SET_IDLE,
          {
            data: {
              action: "run",
            }
          },
        );
      };
    });
  }

  /**
   *
   */
  _idleAll() {
    const { deviceIds, deviceSettings, deviceTelemetry, executeCommand, setSetPoints } = this.props;

    console.log(this.props);

     deviceIds.forEach((deviceId) => {
      const telemetry = deviceTelemetry[deviceId];

      if(telemetry.mode === 0) {
        const settings = deviceSettings[deviceId];

        executeCommand(
          deviceId,
          SUPPORTED_COMMANDS.SET_MAINTAIN_SPEED,
          {
            data: {
              speed: settings.idleRPM,
            },
          },
        );

        setSetPoints(deviceId, {
          C: {
            [CONTROL_POINTS.COMMANDED_ENGINE_SPEED]: settings.idleRPM,
            },
          },
        )

      } else if (telemetry.mode === 1) {

        executeCommand(
          deviceId,
          SUPPORTED_COMMANDS.SET_IDLE,
          {
            data: {
              action: "idle",
            }
          },
        );
      };
    });
  }

  /**
   *
   */
  _stopAll() {
    const { deviceIds, executeCommand } = this.props;

    deviceIds.forEach((deviceId) => {
      executeCommand(deviceId, SUPPORTED_COMMANDS.STOP_ENGINE);
    });
  }

  maxAll = () => {
    // Call the parent callback function
    this.props.parentCallback(false);
  }

  miniAll = () => {
    this.props.parentCallback(true);
  }

  valvesAll = () => {
    this.props.dashboardCallbackValvesAll();
  }

  vitalsAll = () => {
    this.props.dashboardCallbackVitalsAll();
  }

  render() {

    if (this.state.isMobile) {
      return (
        <Grid style={ styles.footer }>
          <Row style={ mobileStyles.buttonRow }>
            {/* <Button style={[mobileStyles.firstButtonGroup, mobileStyles.stopAllButton]} onPress={this._stopAll}>
              <Text style={[mobileStyles.buttonText]}>
                STOP ALL
              </Text>
            </Button> */}
            {/* <Button style={[mobileStyles.button, mobileStyles.idleAllButton]} onPress={this._idleAll}>
              <Text style={[mobileStyles.buttonText]}>
                IDLE ALL
              </Text>
            </Button> */}
            {/* <Button style={[mobileStyles.button, mobileStyles.runAllButton]} onPress={this._runAll}>
              <Text style={[mobileStyles.buttonText]}>
                RUN ALL
              </Text>
            </Button> */}
            <Button style={[mobileStyles.firstButtonGroup, mobileStyles.minAllButton]} onPress={this.miniAll}>
              <Text style={[mobileStyles.maxMinbuttonText]}>
                View Min
              </Text>
            </Button>
            <Button style={[mobileStyles.button, mobileStyles.maxAllButton]} onPress={this.maxAll}>
              <Text style={[mobileStyles.maxMinbuttonText]}>
                View Max
              </Text>
            </Button>
            {/* <Button style={[mobileStyles.firstButtonGroup, mobileStyles.valvesAllButton]} onPress={this.valvesAll}>
              <Text style={[mobileStyles.valveButtonText]}>
                Valves All
              </Text>
            </Button> */}
            {/* <Button style={[mobileStyles.button, mobileStyles.vitalsAllButton]} onPress={this.vitalsAll}>
              <Text style={[mobileStyles.vitalButtonText]}>
                Vitals All
              </Text>
            </Button> */}

            {/* <JobTimer elapsedTime={5000} onTimeElapsed={console.log} /> */}
          </Row>
        </Grid>
      );

    }
    else {
      return (
        <Grid style={ styles.footer }>
        <Row style={ styles.buttonRow }>
          {/* <Button style={[styles.firstButtonGroup, styles.stopAllButton]} onPress={this._stopAll}>
            <Text style={[styles.buttonText]}>
              STOP ALL
            </Text>
          </Button> */}
          {/* <Button style={[styles.button, styles.idleAllButton]} onPress={this._idleAll}>
            <Text style={[styles.buttonText]}>
              IDLE ALL
            </Text>
          </Button> */}
          {/* <Button style={[styles.button, styles.runAllButton]} onPress={this._runAll}>
            <Text style={[styles.buttonText]}>
              RUN ALL
            </Text>
          </Button> */}
          <Button style={[styles.firstButtonGroup, styles.minAllButton]} onPress={this.miniAll}>
            <Text style={[styles.maxMinbuttonText]}>
              View Min
            </Text>
          </Button>
          <Button style={[styles.button, styles.maxAllButton]} onPress={this.maxAll}>
            <Text style={[styles.maxMinbuttonText]}>
              View Max
            </Text>
          </Button>
          {/* <Button style={[styles.firstButtonGroup, styles.valvesAllButton]} onPress={this.valvesAll}>
            <Text style={[styles.valveButtonText]}>
              Valves All
            </Text>
          </Button> */}
          {/* <Button style={[styles.button, styles.vitalsAllButton]} onPress={this.vitalsAll}>
            <Text style={[styles.vitalButtonText]}>
              Vitals All
            </Text>
          </Button> */}

          {/* <JobTimer elapsedTime={5000} onTimeElapsed={console.log} /> */}
        </Row>
      </Grid>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const deviceSettings = {};
  const deviceTelemetry = {};

  ownProps.deviceIds.forEach((deviceId) =>{
    deviceSettings[deviceId] = getUserDeviceCardSettingsById(state, deviceId);
    deviceTelemetry[deviceId] = getTelemetryForDevice(state, deviceId);
  });

  return {
    deviceSettings,
    deviceTelemetry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
    setSetPoints: (deviceId, setPoints) => dispatch(setSetPoints(deviceId, setPoints)),
  };
};

DashboardFooter.propTypes = {
  deviceIds: PropTypes.array.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  deviceTelemetry: PropTypes.any.isRequired,
  executeCommand: PropTypes.func.isRequired,
  setSetPoints: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFooter);
