// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getSetPointsForDevice } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';

// Components
import ShowHideGroup from '_components/device_settings/ShowHideGroup.component';

// UI Framework
import {Text, View,} from 'native-base';

// Styles 
import { styles } from '_components/device_settings/ValveSettings.style';

// Dropdown Menu 
import Dropdown from "./Dropdown.component";

// default valve settings that are initially uploaded to database
export const defaultValveSettings = {
  valve1: { label: "VALVE 1", show: true, order: 0 },
  valve2: { label: "VALVE 2", show: true, order: 1 },
  valve3: { label: "VALVE 3", show: true, order: 2 },
  valve4: { label: "VALVE 4", show: true, order: 3 },
  valve5: { label: "VALVE 5", show: true, order: 4 },
  aux: { label: "AUX", show: true, order: 5 },
};

class ValveSettings extends Component {

  constructor(props) {
    super(props);

    // we get the device settings from redux 
    const { deviceSettings } = props;

    // we let valvesSetting either be defaultValveSettings above or let it equal the value deviceSettings returned by redux
    let { valvesSettings = defaultValveSettings } = deviceSettings;
    
    // in our initial state we let currentValves = valvesSettings
    this.state = {
      currentValves: valvesSettings,
    }

    // function bindings 
    this._show = this._show.bind(this);
    this._hide = this._hide.bind(this);
    this._modifyValveName = this._modifyValveName.bind(this);
  }

  // function is called when we change the value in the dropdown menu
  _modifyValveName(valve) {

    // turn currentValves object into array
    const currentValvesArray = Object.values(this.state.currentValves);

    // function to compare the order value of valves 
    function compare( a, b ) {
      if ( a.order < b.order ){
        return -1;
      }
      if ( a.order > b.order ){
        return 1;
      }
      return 0;
    }

    // sort array by order value
    currentValvesArray.sort( compare );

    // check to see if settings changed 
    const { settingsChanged } = this.props;

    // create a new object with the changes 
    const newValvesSettings = { ...this.state.currentValves, ...{ [valve.id]: { order: valve.order, show: currentValvesArray[valve.order].show, label: valve.label } } };

    // make changes in redux
    settingsChanged({ valvesSettings: newValvesSettings });

    // update the state
    this.setState({ currentValves: newValvesSettings });

  }

  // function is called when we click "show" or "hide" buttons
  _modifyShowHide(valve, show) {

    // check to see if settings changed 
    const { settingsChanged } = this.props;

    // create a new object with the changes 
    const newValvesSettings = { ...this.state.currentValves, ...{ [valve.id]: { order: valve.order, show: show, label: valve.label } } };
 
    // make changes in redux
    settingsChanged({ valvesSettings: newValvesSettings });

    // update the state
    this.setState({ currentValves: newValvesSettings });

  }

  // called when show button is clicked
  _show(valve) {

    this._modifyShowHide(valve, true);

  }

  // called when hide button is clicked 
  _hide(valve) {

    this._modifyShowHide(valve, false);

  }

  // removed show hide functions
  // these functions were used to change I values and thus hide valves
  // will most likely be brought back eventually
  // DO NOT ERASE

  /*

    _show(valve) {
    const { settingsChanged } = this.props;

    settingsChanged({
      I: {
        [valve]: 1,
      },
    });


  }

  _hide(valve) {
    const { settingsChanged } = this.props;

    settingsChanged({
      I: {
        [valve]: 0,
      },
    });
  }

  */

  render() {

    // get currentValves from state
    const { currentValves } = this.state;

    // options for valve dropdown menu
    const optionsValve = [
      { value: "INLET", label: "INLET" },
      { value: "OUTLET", label: "OUTLET" },
      { value: "PIG", label: "PIG" },
      { value: "BYPASS", label: "BYPASS" },
      { value: "VACUUM", label: "VACUUM" },
      { value: "CLUTCH", label: "CLUTCH" },
      { value: "AUX", label: "AUX" },
    ];

    // options for aux dropdown menu
    const optionsAux = [
      { value: "PUMP", label: "PUMP" },
      { value: "LIGHTS", label: "LIGHTS" },
    ];

    // was used before to get show / hide setPoints
    // will most likely be brought back eventually
    // DO NOT ERASE
    const { setPoints } = this.props;
    const {
      i87,
      i88,
      i89,
      i90,
      i91,
      i92,
    } = setPoints.I || {};

    // was used before to set valve defaults 
    // will most likely be brought back eventually
    // DO NOT ERASE
    const valves = [
      { valve: 'i87', label: 'VALVE 1:', show: i87 === 1},
      { valve: 'i88', label: 'VALVE 2:', show: i88 === 1},
      { valve: 'i89', label: 'VALVE 3:', show: i89 === 1},
      { valve: 'i90', label: 'VALVE 4:', show: i90 === 1},
      { valve: 'i91', label: 'VALVE 5:', show: i91 === 1},
      { valve: 'i92', label: 'AUX:', show: i92 === 1},
    ];

    // was used before to push view componenet into valveComponents array for each valve
    // will most likely be brought back eventually
    // DO NOT ERASE
    const valveComponents = [];
    valves.forEach((valve) => {
      valveComponents.push((
        <View key={valve.valve} style={[styles.valveRow]}>
          <Text style={[styles.valveLabel]}>{ valve.label }</Text>
          <ShowHideGroup
            onShow={() => this._show(valve.valve)}
            onHide={() => this._hide(valve.valve)}
            show={valve.show}
          />
        </View>
      ));

    });

    return (

      // should refractor more to be created dynamically similar to above but not needed 

      <View  style={[styles.valveContainer]}>

          <View key={'valve1'} style={[styles.valveRow, {zIndex:100}]}>
            <Dropdown placeHolder={[currentValves.valve1.label]} options={optionsValve} onChange={(value) => this._modifyValveName({id: 'valve1', order: currentValves.valve1.order, label: value.label})}/>
            <ShowHideGroup
              onShow={() => this._show({ id: 'valve1', order: currentValves.valve1.order, label: currentValves.valve1.label })}
              onHide={() => this._hide({ id: 'valve1', order: currentValves.valve1.order, label: currentValves.valve1.label })}
              show={currentValves.valve1.show}
          />
        </View>

        <View key={'valve2'} style={[styles.valveRow, {zIndex:98}]}>
            <Dropdown placeHolder={[currentValves.valve2.label]} options={optionsValve} onChange={(value) => this._modifyValveName({id: 'valve2', order: currentValves.valve2.order, label: value.label})}/>
            <ShowHideGroup
             onShow={() => this._show({ id: 'valve2', order: currentValves.valve2.order, label: currentValves.valve2.label })}
             onHide={() => this._hide({ id: 'valve2', order: currentValves.valve2.order, label: currentValves.valve2.label })}
             show={currentValves.valve2.show}
          />
        </View>

        <View key={'valve3'} style={[styles.valveRow, {zIndex:97}]}>
            <Dropdown placeHolder={[currentValves.valve3.label]} options={optionsValve} onChange={(value) => this._modifyValveName({id: 'valve3', order: currentValves.valve3.order, label: value.label})}/>
            <ShowHideGroup
             onShow={() => this._show({ id: 'valve3', order: currentValves.valve3.order, label: currentValves.valve3.label })}
             onHide={() => this._hide({ id: 'valve3', order: currentValves.valve3.order, label: currentValves.valve3.label })}
             show={currentValves.valve3.show}
          />
        </View>

        <View key={'valve4'} style={[styles.valveRow, {zIndex:96}]}>
            <Dropdown placeHolder={[currentValves.valve4.label]} options={optionsValve} onChange={(value) => this._modifyValveName({id: 'valve4', order: currentValves.valve4.order, label: value.label})}/>
            <ShowHideGroup
            onShow={() => this._show({ id: 'valve4', order: currentValves.valve4.order, label: currentValves.valve4.label })}
            onHide={() => this._hide({ id: 'valve4', order: currentValves.valve4.order, label: currentValves.valve4.label })}
            show={currentValves.valve4.show}
          />
        </View>

        <View key={'valve5'} style={[styles.valveRow, {zIndex:95}]}>
            <Dropdown placeHolder={[currentValves.valve5.label]} options={optionsValve} onChange={(value) => this._modifyValveName({id: 'valve5', order: currentValves.valve5.order, label: value.label})}/>
            <ShowHideGroup
             onShow={() => this._show({ id: 'valve5', order: currentValves.valve5.order, label: currentValves.valve5.label })}
             onHide={() => this._hide({ id: 'valve5', order: currentValves.valve5.order, label: currentValves.valve5.label })}
             show={currentValves.valve5.show}
          />
        </View>

        <View key={'aux'} style={[styles.valveRow, {zIndex:94}]}>
            <Dropdown placeHolder={[currentValves.aux.label]} options={optionsAux} onChange={(value) => this._modifyValveName({id: 'aux', order: currentValves.aux.order, label: value.label})}/>
            <ShowHideGroup
             onShow={() => this._show({ id: 'aux', order: currentValves.aux.order, label: currentValves.aux.label })}
             onHide={() => this._hide({ id: 'aux', order: currentValves.aux.order, label: currentValves.aux.label })}
             show={currentValves.aux.show}
          />
        </View>

      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // not used anymore as we do not need setPoints
    setPoints: getSetPointsForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),

  };
};

ValveSettings.propTypes = {
  deviceId: PropTypes.string.isRequired,
  setPoints: PropTypes.any.isRequired,
  settingsChanged: PropTypes.func.isRequired,
  deviceSettings: PropTypes.any.isRequired,

};

export default connect(mapStateToProps, null)(ValveSettings);