// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { clearState, submitFeedback } from '_redux/feedback/actions';

// Components
import DropDown from '_components/common/DropDown.component';
import FormInput from '_components/common/FormInput.component';

// UI Framework
import { Button, Spinner, Text, View } from 'native-base';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_pages/Feedback.style';

import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from '_util/form-util';
import validation from 'validate.js';

// Category drop down values
const categoryData = [
  'General',
  'Suggested Improvement',
  'Issue - Panel',
  'Issue - Dashboard',
];

// Default component state (form values / error messages)
const defaultState = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  controller: '',
  category: '',
  message: '',
  errorMessage: '',
  errorMessages: {},
};

// Form Validation
const constraints = {
  first_name: REQUIRED_CONSTRAINT('First name'),
  last_name: REQUIRED_CONSTRAINT('Last name'),
  phone_number: PHONE_NUMBER_CONSTRAINT,
  email: EMAIL_CONSTRAINT,
  controller: REQUIRED_CONSTRAINT('Controller or serial #'),
  category: REQUIRED_CONSTRAINT('Category'),
  message: REQUIRED_CONSTRAINT('Message'),
};

/**
 * The Feedback page hosts a form that allows a user to submit feedback
 * about the application or about controller / serial number.
 */
class Feedback extends Component {

  constructor(props) {
    super(props);

    this.state = defaultState;

    this._clearState = this._clearState.bind(this);

    this._handleFormInput = this._handleFormInput.bind(this);
    this._submitFeedback = this._submitFeedback.bind(this);
  }

  /**
   * Utility function to reset the state back to the default.
   */
  _clearState() {
    this.setState(defaultState);
  }

  /**
   * Validates all of the form fields. If valid, will submit the feedback
   * to the backend for database storage and to email the appropriate
   * users.
   */
  _submitFeedback() {
    const { submitFeedback } = this.props;

    const errorMessages = validateFormData(this.state, constraints);

    // If there are error messages, prevent form submission
    // and add a global error message to the form.
    if (Object.keys(errorMessages).length >= 1) {
      this.setState({
        errorMessage: 'One or more errors on the form.',
        errorMessages: errorMessages,
      });
    } else {
      submitFeedback(this.state);
    }
  }

  /**
   * Triggered when any of the form fields value changes.
   *
   * @param {*} fieldId id of the form field
   * @param {*} value current value of the form field.
   */
  _handleFormInput(fieldId, value) {

    const { errorMessages } = this.state;

    if (fieldId === 'phone_number') {
      value = formatPhoneNumber(value);
    }

    // Always set the value of the form field to whatever the
    // user typed.
    this.setState({
      [fieldId]: value,
    });

    // Validate the form field
    const validationResult = validation.validate(
      { [fieldId]: value },
      { [fieldId]: constraints[fieldId] }
    );

    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        }
      });
    } else {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  /**
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { submitFeedbackSuccess } = this.props;
    const { submitFeedbackSuccess: prevSubmitFeedbackSuccess } = prevProps;

    if(!prevSubmitFeedbackSuccess && submitFeedbackSuccess) {
      this._clearState();
    }
  }

  render() {
    const { isSubmittingFeedback, submitFeedbackSuccess } = this.props;
    const { errorMessage, errorMessages } = this.state;

    return (

      <View style={[styles.feedback]} scrollEnabled={false}>
        <Text style={[global.pageTitle]}>
          FEEDBACK
        </Text>

        <View style={[styles.formContainer, global.dropShadow]}>
          {
            submitFeedbackSuccess ?
              <Text style={[styles.feedbackSuccessMessage]}>
                Feedback Recieved!
              </Text>
              :
              <>
                <Text style={[styles.formTitle]}>
                  Send Us a Message
                </Text>
                <Text style={[styles.formSubtitle]}>
                  Fill out the form below and we will get back with you as soon as possible.
                </Text>

                <View style={[styles.mainForm]}>
                  <View style={[styles.formRow]}>
                    <View style={[styles.formItem]}>
                      <FormInput
                        fieldId={'first_name'}
                        onChange={this._handleFormInput}
                        placeholder={'First Name'}
                        floatingLabel={'FIRST NAME'}
                        itemStyles={[global.item]}
                        inputStyles={[global.input]}
                        errorMessage={errorMessages['first_name'] || ''}
                      />
                    </View>
                    <View style={[styles.formItem]}>
                      <FormInput
                        fieldId={'last_name'}
                        onChange={this._handleFormInput}
                        placeholder={'Last Name'}
                        floatingLabel={'LAST NAME'}
                        itemStyles={[global.item]}
                        inputStyles={[global.input]}
                        errorMessage={errorMessages['last_name'] || ''}
                      />
                    </View>
                  </View>
                  <View style={[styles.formRow]}>
                    <View style={[styles.formItem]}>
                      <FormInput
                        fieldId={'phone_number'}
                        onChange={this._handleFormInput}
                        placeholder={'Phone Number'}
                        floatingLabel={'PHONE NUMBER'}
                        helpText={'###-###-####'}
                        itemStyles={[global.item]}
                        inputStyles={[global.input]}
                        errorMessage={errorMessages['phone_number'] || ''}
                      />
                    </View>
                    <View style={[styles.formItem]}>
                      <FormInput
                        fieldId={'email'}
                        onChange={this._handleFormInput}
                        placeholder={'Email'}
                        floatingLabel={'EMAIL'}
                        itemStyles={[global.item]}
                        inputStyles={[global.input]}
                        errorMessage={errorMessages['email'] || ''}
                      />
                    </View>
                  </View>
                  <View style={[styles.formRow, {zIndex: 1}]}>
                    <View style={[styles.formItem]}>
                      <FormInput
                        fieldId={'controller'}
                        onChange={this._handleFormInput}
                        placeholder={'Controller Name or Serial #'}
                        floatingLabel={'CONTROLLER'}
                        itemStyles={[global.item]}
                        inputStyles={[global.input]}
                        errorMessage={errorMessages['controller'] || ''}
                      />
                    </View>
                    <View style={[styles.formItem]}>
                      <DropDown
                        fieldId={'category'}
                        onChange={this._handleFormInput}
                        values={categoryData}
                        floatingLabel={'CATEGORY'}
                        errorMessage={errorMessages['category'] || ''}
                      />
                    </View>
                  </View>
                  <View style={[styles.formRow]}>
                    <View style={[styles.formItem]}>
                      <FormInput
                        fieldId={'message'}
                        onChange={this._handleFormInput}
                        placeholder={'Your Message...'}
                        floatingLabel={'YOUR MESSAGE'}
                        itemStyles={[global.item]}
                        inputStyles={[global.input]}
                        multiline={true}
                        errorMessage={errorMessages['message'] || ''}
                      />
                    </View>
                  </View>
                </View>

                <Text style={[styles.errorMessage]}>{ errorMessage }</Text>
              </>
          }
        </View>

        <View style={[styles.submitFeedbackButtonContainer]}>
          {
            !isSubmittingFeedback && !submitFeedbackSuccess ?
              <Button style={[global.confirmationButton, styles.submitFeedbackButton]} onPress={this._submitFeedback}>
                <Text style={[global.confirmationButtonText, styles.submitFeedbackButtonText]}>
                  SUBMIT YOUR FEEDBACK
                </Text>
              </Button>
              :
              ( !submitFeedbackSuccess ? <Spinner color={'red'} /> : null )
          }
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSubmittingFeedback: state.feedback.isSubmittingFeedback,
    submitFeedbackSuccess: state.feedback.submitFeedbackSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearState: () => dispatch(clearState()),
    submitFeedback: (feedback) => dispatch(submitFeedback(feedback)),
  };
};

Feedback.propTypes = {
  isSubmittingFeedback: PropTypes.bool.isRequired,
  submitFeedback: PropTypes.func.isRequired,
  submitFeedbackSuccess: PropTypes.bool.isRequired,
  clearState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);


