// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getAlisoViejoTelemetryForDevice, getTelemetryForCentralBitPack, isEnginePresent } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import TelemetryValue from '_components/device_card/TelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/DiversionStationVitals.style';

import { defaultVitalsSettings } from '_components/device_settings/DiversionStationVitalsSettings.component';

const MAX_VITALS = 8;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <TelemetryValue value={value} />
      <Text style={[styles.vitalsUnit]}>{unit}</Text>
    </Row>
  );
}


class DiversionStationVitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      deviceSettings,
      isUserFetchingDeviceCardSettings,
      centralData,
      REMOTE_DATA_TX,
      isEnginePresent,
    } = this.props;

    const {
      ss1BitPack,
      ss2BitPack,
      ss3BitPack,
      ss4BitPack,
      ss5BitPack,
      ss6BitPack,
      ss7BitPack,
      sp1_sBitPack,
      sp2_sBitPack,
      sp3_sBitPack,
    } = centralData

    const {
      converted_analog_input_5: station_lvl,
      first_stage_start,
      first_stage_stop,
    } = REMOTE_DATA_TX;

    const {
      b_intrusion_alarm_active,
    } = ss1BitPack

    const {
      b_bypass_intrusion_alarm
    } = ss3BitPack


    let intrusionAlarm = "-"
    if (!b_intrusion_alarm_active && b_bypass_intrusion_alarm) {
      intrusionAlarm = "Disarmed"
    } else if (!b_intrusion_alarm_active && !b_bypass_intrusion_alarm) {
      intrusionAlarm = "Armed"
    } else if (b_intrusion_alarm_active && !b_bypass_intrusion_alarm) {
      intrusionAlarm = "Intrusion Detected"
    }

    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;


    //Note: for the engine hours and water level it's divided by 10 to give the correct ammount. We may want to perform this or other calculations outside
    // of the "vitals" const.
    const vitals = [
      { id: 'waterCurrentLevel', label: 'Water Level', value: station_lvl / 10, unit: 'FT' },
      { id: 'waterStartLevel', label: 'Water Start Level', value: first_stage_start / 10, unit: 'FT' },
      { id: 'waterStopLevel', label: 'Water Stop Level', value: first_stage_stop / 10, unit: 'FT' },
    ];


    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value}
            unit={item.unit}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    centralData: getTelemetryForCentralBitPack(state, ownProps.deviceId),
    isEnginePresent: isEnginePresent(state, ownProps.deviceId),
  };
};

DiversionStationVitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(DiversionStationVitals);
