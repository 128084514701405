// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getAlisoViejoTelemetryForDevice, getTelemetryForCentralBitPack, isEnginePresent } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import TelemetryValue from '_components/device_card/TelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/DiversionMiscVitals.style';

import { defaultVitalsSettings } from '_components/device_settings/DiversionMiscVitalsSettings.component';

const MAX_VITALS = 8;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <TelemetryValue value={value} />
      <Text style={[styles.vitalsUnit]}>{unit}</Text>
    </Row>
  );
}


class DiversionMiscVitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      deviceSettings,
      isUserFetchingDeviceCardSettings,
      REMOTE_DATA_TX,
    } = this.props;



    const {
      ver_major,
      ver_minor,
      ver_build,
      system_voltage,
      P1_run_1000_hours,
      P1_run_hours_tenths,
      back_wash_run_1000_hours,
      back_wash_run_hours_tenths,
    } = REMOTE_DATA_TX;






    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;


    //Note: for the engine hours and water level it's divided by 10 to give the correct amount. We may want to perform this or other calculations outside
    // of the "vitals" const.
    const vitals = [
      { id: 'motorBattery', label: 'Battery', value: system_voltage/10, unit: 'VDC' },
      { id: 'motorHours', label: 'Motor Hours', value: (P1_run_1000_hours * 1000)+(P1_run_hours_tenths/10), unit: 'HR' },
      { id: 'backwashHours', label: 'Backwash Hours', value: (back_wash_run_1000_hours * 1000)+(back_wash_run_hours_tenths/10), unit: 'HR' },
      { id: 's800PanelVersion', label: 'Version', value: ver_major + '.' + ver_minor + '.' + ver_build, unit: '' },
    ];


    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value}
            unit={item.unit}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    centralData: getTelemetryForCentralBitPack(state, ownProps.deviceId),
    isEnginePresent: isEnginePresent(state, ownProps.deviceId),
  };
};

DiversionMiscVitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(DiversionMiscVitals);
