import { trimTrailing } from '_util';

// All commands that are supported by the solution.
export const SUPPORTED_COMMANDS = {
  GET_TELEMETRY: 'get-telemetry',
  GET_SET_POINTS: 'get-set-points',
  SET_SET_POINTS: 'set-set-points',
  START_ENGINE: 'start-engine',
  STOP_ENGINE: 'stop-engine',
  IMMEDIATE_STOP: 'immediate-stop',
  SET_MAINTAIN_SPEED: 'set-maintain-speed',
  SET_MAINTAIN_PRESSURES: 'set-maintain-pressures',
  SET_HYDROSTAT_SPEED: 'hydrostat',
  HYDRAULIC_VALVE_OPEN: 'hydraulic-valve-open',
  HYDRAULIC_VALVE_CLOSE: 'hydraulic-valve-close',
  SET_IDLE: 'set-idle',
  SET_THROTTLE_MODE: 'set-throttle-mode',
};

export const CONTROL_POINTS = {
  COMMANDED_ENGINE_SPEED: 'c1',
  COMMANDED_HYDROSTAT_SPEED: 'c2',
  COMMANDED_PUMP_OUTLET_PRESSURE: 'c3',
  COMMANDED_PUMP_INLET_PRESSURE: 'c4',
};

// special 'none' id for levels in the hierarchy that are not yet assigned.
const NONE_ENTITY = 'none';

/**
 * Constructs a command topic.
 *
 * @param {String} thingName the name of this thing.
 * @param {String} command the name of the command.
 * @param {String} isResponse true if this is a response to a command, false otherwise.
 * @param {String} cognitoId  the cognito user id to append to the topic.
 *
 * @returns {string}
 */
export const getCommandTopic = (thingName, level1Id, level2Id, level3Id, level4Id, command, isRequest, cognitoId) => {
  // Construct baseline topic string.
  let topic = `${level1Id}/${level2Id}/${level3Id}/${level4Id}`;

  // Remove any child level /none paths from the string.
  topic = trimTrailing(topic, `/${NONE_ENTITY}`);

  if (topic === `${NONE_ENTITY}`) {
    topic = '';
  }

  // Finish off the topic concatenation with the end of the string.
  if (isRequest) {
    topic = `${topic}/${thingName}/${command}/request/${cognitoId}`;
  } else {
    topic = `${topic}/${thingName}/${command}/response/${cognitoId}`;
  }

  // Remove leading slashes.
  return topic.replace(/^\/+/g, '');
};

/**
 * This method will return an array of topics that should be subscribed
 * to for a single device.
 *
 * @param {*} deviceDetails api response from /devices/{deviceId}
 *
 * @returns {Array<string>}
 */
export const getSubscriptionTopics = (deviceDetails, cognitoId) => {

  // Complete list of topics to subscribe to.
  const topics = [];

  // Device details
  const deviceId = deviceDetails['device_id'];
  const level1Id = deviceDetails['level_1_id'];
  const level2Id = deviceDetails['level_2_id'];
  const level3Id = deviceDetails['level_3_id'];
  const level4Id = deviceDetails['level_4_id'];

  // // Telemetry Topic(s)
  // let telemetryTopic = `telemetry/${level1Id}/${level2Id}/${level3Id}/${level4Id}`;
  // telemetryTopic = trimTrailing(telemetryTopic, `/${NONE_ENTITY}`);

  // const allTelemetry = `${telemetryTopic}/${deviceId}/#`;

  // topics.push(allTelemetry);

  // // Shadow (set points)
  // topics.push(`$aws/things/${deviceId}/shadow/#`);
  // Shadow (set points)
  topics.push(`$aws/things/${deviceId}/shadow/update/documents`);
  topics.push(`$aws/things/${deviceId}/shadow/update/documents/water_level`);
  // // Events (connect/disconnect)
  // topics.push(`$aws/events/presence/disconnected/${deviceId}`);
  // topics.push(`$aws/events/presence/connected/${deviceId}`);

  // // Command Topics
  // Object.values(SUPPORTED_COMMANDS).forEach((command) => {
  //   topics.push(getCommandTopic(
  //     deviceId,
  //     level1Id,
  //     level2Id,
  //     level3Id,
  //     level4Id,
  //     command,
  //     false,
  //     cognitoId,
  //   ));
  // });

  return topics;
};

const KPA_CONVERSION_MULTIPLIER = 0.1450377;
const LPH_CONVERSION_MULTIPLIER = 0.2641721;

const convertKPAToPSIRaw = (value) => KPA_CONVERSION_MULTIPLIER * value;
const convertPSIToKPARaw = (value) => value / KPA_CONVERSION_MULTIPLIER;

export const convertKPAToPSI = (value, round = true) => round ? Math.round(convertKPAToPSIRaw(value)) : convertKPAToPSIRaw(value);
export const convertPSIToKPA = (value, round = true) => round ? Math.round(convertPSIToKPARaw(value)) : convertPSIToKPARaw(value);

export const convertCelsiustoFahrenheit = (value) => Math.round((value * 1.8) + 32);
export const convertPitDepth = (value) => (value / 10).toFixed(1);

export const convertLPHToGPH = (value) => Math.round(value * LPH_CONVERSION_MULTIPLIER);

export const dec2bin = (dec) => {
  return (dec >>> 0).toString(2);
}

// Function to iterate through each bit in a binary number
export const iterateBinary = (binaryString, telemetryObj) => {
  let reversedBinaryString = binaryString.split('').reverse().join('');
  for (let i = 0; i < reversedBinaryString.length; i++) {
    let bitTrueOrFalse = false;
    if (reversedBinaryString[i] === '1') {
      bitTrueOrFalse = true;
    }
    const key = Object.keys(telemetryObj)[i];
    telemetryObj[key] = bitTrueOrFalse
  }
  return telemetryObj;
}
