//NOTE: The "Jobs" name has been refactored to "Assignments" for the s800 iot web app.
//The coding may not refrence the refactoring very much but the front-end will should
//show up as "Jobs" instead of "Assignments".

// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Routing
import { navigate } from '_routes/RootNavigation';

// Util
import { debounce } from '_util';

// Redux
import { connect } from 'react-redux';
import {
  addJob,
  clearState,
  deleteJob,
  editJob,
  getJobs,
  setDefaultJob,
  updateJobDeviceOrder,
} from '_redux/jobs/actions';
import { getCrewMembers } from '_redux/crewMembers/actions';
import { getUserDevices } from '_redux/user/actions';
import { getUserRoleId, getUserParentId } from '_redux/user/selectors';
import { getFriendlyUsername } from '_redux/user/utils';

// Components
import
  AccessControl,
  { OWNER, CREW_LEADER }
from '_components/auth/AccessControl.component';
import AddEditJobModalContent from '_components/jobs/AddEditJobModalContent.component';
import ArrowButton from '_components/common/ArrowButton.component';
import ConfirmDeleteModalContent from '_components/common/ConfirmDeleteModalContent.component';
import EditButton from '_components/common/EditButton.component';
import JobListItem from '_components/jobs/JobListItem.component';
import Modal from '_components/common/Modal.component';

// UI Framework
import {
  Image,
  TouchableOpacity,
} from 'react-native';
import {
  Button,
  Spinner,
  Text,
  View,
} from 'native-base';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_pages/Jobs.style';

/**
 * Jobs component
 */
class Jobs extends Component {

  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      selectedJobId: '',
      selectedJobOwnerId: '',
      selectedJobName: '',
      selectedJobDevices: [],
      selectedJobCrewMembers: [],

      showAddJobModal: false,
      showConfirmDeleteModal: false,
      showEditJobModal: false,
      showLoadJobDashboardModal: false,

      formData: {},
    };

    this._closeAddJobModal = this._closeAddJobModal.bind(this);
    this._closeConfirmDeleteModal = this._closeConfirmDeleteModal.bind(this);
    this._closeEditJobModal = this._closeEditJobModal.bind(this);
    this._showAddJobModal = this._showAddJobModal.bind(this);
    this._showConfirmDeleteModal = this._showConfirmDeleteModal.bind(this);
    this._showEditJobModal = this._showEditJobModal.bind(this);
    this._showJobDetails = this._showJobDetails.bind(this);

    this._handleCreateJob = this._handleCreateJob.bind(this);
    this._handleDeleteJob = this._handleDeleteJob.bind(this);
    this._handleEditJob = this._handleEditJob.bind(this);
    this._handleFormInput = this._handleFormInput.bind(this);

    this._isUpdating = this._isUpdating.bind(this);
    this._navigateToDashboard = this._navigateToDashboard.bind(this);
    this._setDefaultJob = this._setDefaultJob.bind(this);
    this._moveDeviceUp = this._moveDeviceUp.bind(this);
    this._moveDeviceDown = this._moveDeviceDown.bind(this);
    this._handleUpdateUpdateJobDeviceOrder = debounce(this._handleUpdateUpdateJobDeviceOrder.bind(this), 500);
  }

  /**
   * When the jobs page unmounts, be sure to clear the redux state
   * so if the user comes back to the jobs page they will
   * see any updates that were made.
   */
  async componentWillUnmount() {

    this.props.clearState();
  }

  /**
   * Once the Jobs component mounts, fetch the jobs, devices, and crew members
   * that are available to work with for the jobs.
   */
  async componentDidMount() {
    const {
      getCrewMembers,
      getJobs,
      getUserDevices,
      userParentId,
      userRoleId,
    } = this.props;

    getJobs();

    if (userRoleId !== OWNER) {
      getCrewMembers(userParentId);
      getUserDevices(userParentId);
    } else {
      getCrewMembers();
      getUserDevices();
    }
  }

  /**
   * Show the Add Job Modal
   */
  _showAddJobModal() {
    this.setState({
      showAddJobModal: true,
    });
  }

  /**
   * Determine the job details to show by default. This can change if this is
   * the first time the Jobs component is loaded, or if some action takes place that
   * removes the default job or a new default job is chosen.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { selectedJobId } = this.state;
    const { jobs, isGettingJobs } = this.props;
    const { jobs: prevJobs, isGettingJobs: prevIsGettingJobs } = prevProps;

    if ((prevJobs.length !== jobs.length) && jobs.length > 0) {
      const defaultJobIndex = jobs.findIndex((job) => job.is_default === 1);
      this._showJobDetails(jobs[defaultJobIndex !== -1 ? defaultJobIndex : 0]);

    } else if (selectedJobId && jobs.length > 0 && !isGettingJobs && prevIsGettingJobs) {
      const currentJobIndex = jobs.findIndex((job) => job.job_id === selectedJobId);
      this._showJobDetails(jobs[currentJobIndex !== -1 ? currentJobIndex : 0]);
    }
  }

  _moveDeviceDown(deviceId) {
    const {
      selectedJob,
    } = this.state;

    // Find the index of the device we're moving.
    const devices = selectedJob.devices;
    const deviceIndex = devices.findIndex((device) => device.device_id === deviceId);

    // Swap the device we're moving with the device in the next index.
    const nextDevice = devices[deviceIndex + 1];
    devices[deviceIndex + 1] = devices[deviceIndex];
    devices[deviceIndex] = nextDevice;

    // Create a new array object so React will update the state
    // and re-render the component.
    const newDeviceList = devices.slice();
    selectedJob.devices = newDeviceList;

    this.setState({
      selectedJob,
    });

    this._handleUpdateUpdateJobDeviceOrder();
  }

  _moveDeviceUp(deviceId) {
    const {
      selectedJob,
    } = this.state;

    // Find the index of the device we're moving.
    const devices = selectedJob.devices;
    const deviceIndex = devices.findIndex((device) => device.device_id === deviceId);

    // Swap the device we're moving with the device in the previous index.
    const prevDevice = devices[deviceIndex - 1];
    devices[deviceIndex - 1] = devices[deviceIndex];
    devices[deviceIndex] = prevDevice;

    // Create a new array object so React will update the state
    // and re-render the component.
    const newDeviceList = devices.slice();
    selectedJob.devices = newDeviceList;

    this.setState({
      selectedJob,
    });

    this._handleUpdateUpdateJobDeviceOrder();
  }

  _handleUpdateUpdateJobDeviceOrder() {
    const {
      updateJobDeviceOrder,
    } = this.props;
    const {
      selectedJob,
    } = this.state;

    const deviceIds = selectedJob.devices.map((device) => device.device_id);

    updateJobDeviceOrder(selectedJob.job_id, deviceIds);
  }

  /**
   * Navigate to the dashboard, passing the job id along
   * so the dashboard knows what devices to fetch.
   */
  _navigateToDashboard() {
    const { selectedJob } = this.state;

    navigate('Dashboard', { jobId: selectedJob.job_id });
  }

  /**
   * Returns true if there is an asynchronous action happening
   * related to the jobs.
   */
  _isUpdating() {
    const {
      isDeletingJob,
      isGettingJobs,
      isEditingJob,
      isAddingJob,
    } = this.props;

    return isDeletingJob || isGettingJobs || isEditingJob || isAddingJob;
  }

  /**
   * Show the edit job modal. Pre-populate the form data
   * to pass into the modal so it knows what to display and keep track of
   * since this is not a new job.
   */
  _showEditJobModal() {
    const { selectedJob } = this.state;

    // Construct the selected devices object. Selected
    // devices are the devices that are for this job, and
    // not the total list of devices available.
    let selectedDevices = {};
    selectedJob.devices.forEach((device) => {
      selectedDevices[device.device_id] = true;
    });

    // Construct the selected crew members object. Selected
    // crew members are the crew members that are for this
    // job, and not the total list of crew members available.
    let selectedCrewMembers = {};
    selectedJob.crew_members.forEach((member) => {
      selectedCrewMembers[member.cognito_sub] = true;
    });

    // Pre-populated form data.
    const formData = {
      jobName: selectedJob.job_name,
      selectedDevices,
      selectedCrewMembers,
      isDefault: selectedJob.is_default === 1 ? true : false,
    };

    this.setState({
      showEditJobModal: true,
      formData,
    });
  }

  /**
   * Show the confirm delete job modal.
   */
  _showConfirmDeleteModal() {
    this.setState({
      showConfirmDeleteModal: true,
    });
  }

  /**
   * Hide the add job modal.
   */
  _closeAddJobModal() {
    this.setState({
      showAddJobModal: false,
      formData: {},
    });
  }

  /**
   * Hide the edit job modal.
   */
  _closeEditJobModal() {
    this.setState({
      showEditJobModal: false,
      formData: {},
    });
  }

  /**
   * Hide the confirm delete job modal.
   */
  _closeConfirmDeleteModal() {
    this.setState({
      showConfirmDeleteModal: false,
    });
  }

  /**
   * Sets the selected job to be the default job for this owner.
   */
  _setDefaultJob() {
    const { owner_id, job_id } = this.state.selectedJob;
    const { setDefaultJob } = this.props;

    // Async call to the API.
    setDefaultJob(owner_id, job_id);
  }

  /**
   * Triggered when the user clicks a job from the list on the left.
   * Sets the appropriate state values so that the details pane knows
   * what to render.
   *
   * @param {number} jobId unique id of the job.
   */
  _showJobDetails(job) {
    const {
      crew_members,
      devices,
      job_id,
      job_name,
      owner_id,
    } = job;

    this.setState({
      selectedJob: job,
      selectedJobId: job_id,
      selectedJobOwnerId: owner_id,
      selectedJobName: job_name,
      selectedJobDevices: devices,
      selectedJobCrewMembers: crew_members,
    });
  }

  /**
   * Dispatches a delete job action to the store.
   */
  _handleDeleteJob() {
    const { jobs, deleteJob } = this.props;
    const { selectedJob } = this.state;

    // Async call to the API.
    deleteJob(selectedJob.job_id);

    // Default to showing the first job in the list.
    if (jobs.length > 1) {
      this._showJobDetails(jobs[0]);
    } else {
      this.setState({
        selectedJob: null,
      });
    }
    this._closeConfirmDeleteModal();
  }

  /**
   * Handles creating a job from the form data the user entered.
   */
  _handleCreateJob() {
    const {
      jobName,
      selectedDevices,
      selectedCrewMembers,
      isDefault,
      userParentId,
      userRoleId,
    } = this.state.formData;

    const { addJob, jobs } = this.props;

    // Construct simple arrays of ids to tell the backend what crew members and
    // devices to add to the job.
    const devices = Object.keys(selectedDevices || {}).filter((key) => selectedDevices[key]);
    const crewMembers = Object.keys(selectedCrewMembers || {}).filter((key) => selectedCrewMembers[key]);
    const setDefault = jobs.length === 0 || isDefault;

    // Async call to the API.
    addJob(jobName, devices, crewMembers, setDefault ? 1 : 0, userRoleId === OWNER ? null : userParentId);

    this._closeAddJobModal();
  }

  /**
   * Handles editing an existing job from the form data the user entered.
   */
  _handleEditJob() {
    const { selectedJobId } = this.state;
    const {
      jobName,
      selectedDevices,
      selectedCrewMembers,
      isDefault,
    } = this.state.formData;
    const { editJob } = this.props;

    const devices = Object.keys(selectedDevices || {}).filter((key) => selectedDevices[key]);
    const crewMembers = Object.keys(selectedCrewMembers || {}).filter((key) => selectedCrewMembers[key]);

    // Async call to the API.
    editJob(selectedJobId, jobName, devices, crewMembers, isDefault);

    this._closeEditJobModal();
  }

  /**
   * Triggered when the user changes the value on the add or edit
   * job modal form.
   *
   * @param {string} fieldId id of the field
   * @param {*} value new value of the field.
   */
  _handleFormInput(fieldId, value) {
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        [fieldId]: value,
      },
    });
  }

  /**
   * Component Render.
   */
  render() {
    const {
      formData,
      selectedJob,
      showAddJobModal,
      showConfirmDeleteModal,
      showEditJobModal,
    } = this.state;

    const {
      crew_members: selectedJobCrewMembers = [],
      devices: selectedJobDevices = [],
      job_id: selectedJobId,
      job_name: selectedJobName,
    } = selectedJob || {};

    const {
      crewMembers,
      devices,
      isUpdatingDeviceOrder,
      isSettingDefaultJob,
      jobs,
    } = this.props;

    const jobsList = jobs.map((job, index) => {
      const { job_id } = job;
      return (
        <JobListItem key={index} job={job} onPress={this._showJobDetails} isSelected={job_id === selectedJobId} />
      );
    });

    // Selected Job Devices
    const jobDevices = [];
    selectedJobDevices.forEach((device, index) => {
      const { device_id, device_name } = device;
      const even = index % 2 === 0;
      jobDevices.push((
        <View key={device_id} style={[styles.jobDetailsDeviceRow, even ? { backgroundColor: '#212121' } : {}]}>
          <Text
            style={[styles.jobDetailsDevice]}
          >
            { device_name || device_id }
          </Text>
          {
            isUpdatingDeviceOrder &&
              <Spinner color={'red'} />
          }
          {
            !isUpdatingDeviceOrder && selectedJobDevices.length > 1 &&
            <>
            {
              index < selectedJobDevices.length - 1 &&
              <AccessControl allowedGroups={[OWNER, CREW_LEADER]}>
                <View style={[styles.arrowButtonContainer]}>
                  <ArrowButton up={false} onClick={this._moveDeviceDown} data={device.device_id}/>
                </View>
              </AccessControl>
            }
            {
              index > 0 &&
              <AccessControl allowedGroups={[OWNER, CREW_LEADER]}>
                <View style={[styles.arrowButtonContainer]}>
                  <ArrowButton up={true} onClick={this._moveDeviceUp} data={device.device_id}/>
                </View>
              </AccessControl>
            }
            </>
          }
         </View>
      ));
    });

    // Selected Job Crew Members
    const jobCrewMembers = [];
    selectedJobCrewMembers.forEach((member, index) => {
      const { is_leader } = member;
      const even = index % 2 === 0;
      jobCrewMembers.push((
        <Text
          key={index}
          style={[is_leader === 0 ? styles.jobDetailsCrewMember : styles.jobDetailsCrewLeader, even ? { backgroundColor: '#212121' } : {}]}
        >
          { getFriendlyUsername(member) }
        </Text>
      ));
    });

    return (
      <View style={[styles.main]}>
        <Text style={[global.pageTitle]}>
          ASSIGNMENTS
        </Text>
        <View style={[styles.content]}>

          {/* Job List */}
          <View style={[global.contentContainer, styles.jobListContainer, global.dropShadow]}>
            <View style={[styles.jobListItemsContainer]}>
              {
                this._isUpdating() ?
                  <View style={{ alignSelf: 'center' }}>
                    <Spinner color={'red'}/>
                  </View>
                  :
                  jobsList
              }
            </View>
            <AccessControl allowedGroups={[CREW_LEADER, OWNER]}>
              <View style={[styles.defaultButtonContainer]}>
                {
                  isSettingDefaultJob ?
                    <View style={{ alignSelf: 'center' }}>
                      <Spinner color={'red'}/>
                    </View>
                    : !selectedJobId || this._isUpdating() ? null :
                      <Button style={[styles.defaultButton]} onPress={this._setDefaultJob} disabled={selectedJobId === ''}>

                          <Image source={require('_assets/images/default-job-star.png')} style={styles.defaultButtonImage} />
                          <Text style={[styles.defaultButtonText]}>
                            DEFAULT
                          </Text>

                      </Button>
                }
              </View>
            </AccessControl>
          </View>

          {/* Job Details */}
          {
            this._isUpdating() ?
              <View style={[global.contentContainer, styles.jobDetailsContainer, global.dropShadow]}>
                <Spinner color={'red'} />
              </View>
              : jobs.length > 0 &&
              <View style={[global.contentContainer, styles.jobDetailsContainer, global.dropShadow]}>
                {/* Header */}
                <View style={[styles.jobDetailsHeaderRow]}>

                  {/* Job Name */}
                  <Text style={[styles.jobDetailsTitle]}>
                    { selectedJobName }
                  </Text>

                  <AccessControl allowedGroups={[OWNER, CREW_LEADER]}>
                    <View style={[styles.jobDetailsActionButtons]}>

                      {/* Delete Job */}
                      <TouchableOpacity onPress={this._showConfirmDeleteModal} style={{height: 'auto'}}>
                        <Image source={require('_assets/images/trash-icon.png')} style={[styles.trashIcon]} />
                      </TouchableOpacity>

                      {/* Edit Job */}
                      <View style={[styles.editButtonContainer]}>
                        <EditButton data={{}} onClick={this._showEditJobModal} />
                      </View>

                    </View>
                  </AccessControl>
                </View>

                <View style={[styles.jobDetailsContentRow]}>
                  {/* Devices */}
                  <View style={[styles.jobDetailsDevicesContainer]}>
                    <Text style={[styles.jobDetailsSubTitle]}>
                      STATIONS
                    </Text>
                    { jobDevices }
                  </View>

                  {/* Crew Members */}
                  <View style={[styles.jobDetailsCrewMembersContainer]}>
                    <Text style={[styles.jobDetailsSubTitle]}>
                      Crew Members
                    </Text>
                    { jobCrewMembers }
                  </View>
                </View>

                {/* View Job Dashboard */}
                <View style={[styles.jobDetailsButtonRow]}>
                  <Button style={[global.confirmationButton, styles.viewDashboardButton]} onPress={this._navigateToDashboard}>
                    <Text style={[global.confirmationButtonText, styles.viewDashboardButtonText]}>
                      VIEW ASSIGNMENT DASHBOARD
                    </Text>
                  </Button>
                </View>
              </View>
          }

        </View>
        {/* Add Job */}
        {
          <AccessControl allowedGroups={[OWNER, CREW_LEADER]}>
            <View style={[styles.addJobButtonContainer]}>
              <Button style={[global.confirmationButton, styles.addJobButton]} onPress={this._showAddJobModal}>
                <Text style={[global.confirmationButtonText, styles.addJobButtonText]}>
                  ADD ASSIGNMENT
                </Text>
              </Button>
            </View>
          </AccessControl>
        }

        {/* Confirm Delete */}
        <Modal
          isVisible={showConfirmDeleteModal}
          title={'DELETE ASSIGNMENT'}
          hideExitIcon={true}
          onClose={this._closeConfirmDeleteModal}
          content={
            <ConfirmDeleteModalContent
              onConfirm={this._handleDeleteJob}
              onCancel={this._closeConfirmDeleteModal}

              confirmationText={'Would you like to continue delete this job from your jobs list and all its settings?'}
              confirmButtonText={'DELETE ASSIGNMENT'}
              cancelButtonText={'DO NOT DELETE ASSIGNMENT'}
            />
          }
        />

        {/* Add Job */}
        <Modal
          isVisible={showAddJobModal}
          title={'ADD AN ASSIGNMENT'}
          hideExitIcon={true}
          onClose={this._closeAddJobModal}
          content={!showAddJobModal ? null : // Forces the content to re-render on open. (clears the form)
            <AddEditJobModalContent
              editMode={false}
              onConfirm={this._handleCreateJob}
              onCancel={this._closeAddJobModal}
              onChange={this._handleFormInput}
              devices={devices}
              crewMembers={crewMembers}
            />
          }
        />

        {/* Edit Job */}
        <Modal
          isVisible={showEditJobModal}
          title={'EDIT ASSIGNMENT'}
          hideExitIcon={true}
          onClose={this._closeEditJobModal}
          content={showEditJobModal && // Forces the content to re-render on open. (clears the form)
            <AddEditJobModalContent
              editMode={true}
              formData={formData}
              onConfirm={this._handleEditJob}
              onCancel={this._closeEditJobModal}
              onChange={this._handleFormInput}
              devices={devices}
              crewMembers={crewMembers}
            />
          }
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAddingJob: state.job.isAddingJob,
    isDeletingJob: state.job.isDeletingJob,
    isEditingJob: state.job.isEditingJob,
    isGettingJobs: state.job.isGettingJobs,
    isSettingDefaultJob: state.job.isSettingDefaultJob,

    isFetchingDevices: state.user.isFetchingDevices,
    isGettingCrewMembers: state.crewMembers.isGettingCrewMembers,

    jobs: state.job.jobs,
    devices: state.user.devices,
    crewMembers: state.crewMembers.crewMembers,

    isUpdatingDeviceOrder: state.job.isUpdatingDeviceOrder,
    updateDeviceOrderFailed: state.job.updateDeviceOrderFailed,
    updateDeviceOrderError: state.job.updateDeviceOrderError,

    userRoleId: getUserRoleId(state),
    userParentId: getUserParentId(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addJob: (jobName, deviceIds, crewMemberIds, isDefault, cognitoSub) => dispatch(addJob(jobName, deviceIds, crewMemberIds, isDefault, cognitoSub)),
    deleteJob: (jobId) => dispatch(deleteJob(jobId)),
    editJob: (jobId, jobName, devices, crewMembers, isDefault, cognitoSub) => dispatch(editJob(jobId, jobName, devices, crewMembers, isDefault, cognitoSub)),
    getJobs: () => dispatch(getJobs()),
    setDefaultJob: (ownerId, jobId) => dispatch(setDefaultJob(ownerId, jobId)),

    getUserDevices: (cognitoSub) => dispatch(getUserDevices(cognitoSub)),
    getCrewMembers: (cognitoSub) => dispatch(getCrewMembers(cognitoSub)),

    clearState: () => dispatch(clearState()),

    updateJobDeviceOrder: (jobId, deviceIds) => dispatch(updateJobDeviceOrder(jobId, deviceIds)),
  };
};

Jobs.propTypes = {
  clearState: PropTypes.func.isRequired,

  addJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  editJob: PropTypes.func.isRequired,
  getJobs: PropTypes.func.isRequired,
  setDefaultJob: PropTypes.func.isRequired,
  isAddingJob: PropTypes.bool.isRequired,
  isGettingJobs: PropTypes.bool.isRequired,
  isDeletingJob: PropTypes.bool.isRequired,
  isEditingJob: PropTypes.bool.isRequired,
  isSettingDefaultJob: PropTypes.bool.isRequired,

  userRoleId: PropTypes.string.isRequired,
  userParentId: PropTypes.string.isRequired,

  getUserDevices: PropTypes.func.isRequired,
  isFetchingDevices: PropTypes.bool.isRequired,

  getCrewMembers: PropTypes.func.isRequired,
  isGettingCrewMembers: PropTypes.bool.isRequired,

  jobs: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  crewMembers: PropTypes.array.isRequired,

  navigation: PropTypes.any.isRequired,
  route: PropTypes.any.isRequired,

  updateJobDeviceOrder: PropTypes.func.isRequired,
  isUpdatingDeviceOrder: PropTypes.bool.isRequired,
  updateDeviceOrderFailed: PropTypes.bool.isRequired,
  updateDeviceOrderError: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);


