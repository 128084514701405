//NOTE: The "Devices" name has been refactored to "Stations" for the s800 iot web app.
//The coding may not refrence the refactoring very much but the front-end will should
//show up as "Stations" instead of "Devices".

// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import {
  clearRegisterState,
  getUserDevices,
  registerDevice,
  unregisterDevice,
} from '_redux/user/actions';
import { updateDeviceName } from '_redux/iot/actions';

// Components
import EditButton from '_components/common/EditButton.component';
import Modal from '_components/common/Modal.component';
import FormInput from '_components/common/FormInput.component';
import TrashButton from '_components/common/TrashButton.component';

// Native Base Components
import {
  Button,
  Item,
  Input,
  Spinner,
  Text,
  View,
} from 'native-base';

import { styles as global } from '_style/Global.style';
import { styles } from '_pages/Devices.style';

/**
 * Devices component is responsible for letting a user register
 * an owned device to their account.
 */
class Devices extends Component {
  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      showConfirmRemoveModal: false,
      removeDeviceId: '',

      showAddDeviceModal: false,
      deviceCode: '',
      deviceLabel: '',

      showEditDeviceModal: false,
      editDeviceId: '',
      editDeviceName: '',
      newDeviceName: '',
    };

    // Bind functions
    this._handleGetUserDevices = this._handleGetUserDevices.bind(this);
    this._handleRegisterDevice = this._handleRegisterDevice.bind(this);
    this._handleUnregisterDevice = this._handleUnregisterDevice.bind(this);
    this._handleCodeChange = this._handleCodeChange.bind(this);
    this._handleDeviceNameChange = this._handleDeviceNameChange.bind(this);
    this._handleEditDevice = this._handleEditDevice.bind(this);
    this._handleDeviceNameInput = this._handleDeviceNameInput.bind(this);

    this._closeConfirmRemoveModal = this._closeConfirmRemoveModal.bind(this);
    this._showConfirmRemoveModal = this._showConfirmRemoveModal.bind(this);

    this._closeAddDeviceModal = this._closeAddDeviceModal.bind(this);
    this._showAddDeviceModal = this._showAddDeviceModal.bind(this);

    this._closeEditDeviceModal = this._closeEditDeviceModal.bind(this);
    this._showEditDeviceModal = this._showEditDeviceModal.bind(this);

    this._generateConfirmRemovalContent = this._generateConfirmRemovalContent.bind(this);
    this._generateAddDeviceContent = this._generateAddDeviceContent.bind(this);
    this._generateEditDeviceContent = this._generateEditDeviceContent.bind(this);
  }

  /**
   * Called when the component mounts, gets all the users devices.
   */
  async componentDidMount() {
    this._handleGetUserDevices();
  }

  /**
   * Check if we've successfully registered a device, if so, close
   * the add device modal.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const {
      isRegisteringDevice,
      registerDeviceFailed,
    } = this.props;
    const {
      isRegisteringDevice: prevIsRegisteringDevice,
    } = prevProps;

    if (prevIsRegisteringDevice && !isRegisteringDevice && !registerDeviceFailed) {
      this._closeAddDeviceModal();
    }
  }

  /**
   * Event handler for when the device code changes.
   *
   * @param {string} deviceCode
   */
  _handleCodeChange(deviceCode) {
    this.setState({
      deviceCode,
    });
  }

  /**
   * Event handler for when the device name changes.
   *
   * @param {string} deviceLabel
   */
  _handleDeviceNameChange(deviceLabel) {
    this.setState({
      deviceLabel
    });
  }

  /**
   * Close the Edit Device Modal
   */
  _closeEditDeviceModal() {
    this.setState({
      showEditDeviceModal: false,
      editDeviceId: '',
    });
  }

  /**
   * Show the Edit Device Modal
   */
  _showEditDeviceModal(device) {
    const { deviceName, deviceId } = device;

    this.setState({
      showEditDeviceModal: true,
      editDeviceId: deviceId,
      editDeviceName: deviceName,
    });
  }

  /**
   * Close the Confirm Remove Device Modal
   */
  _closeConfirmRemoveModal() {
    this.setState({
      showConfirmRemoveModal: false,
      removeDeviceId: '',
    });
  }

  /**
   * Show the Confirm Remove Device Modal
   */
  _showConfirmRemoveModal(deviceId) {
    this.setState({
      showConfirmRemoveModal: true,
      removeDeviceId: deviceId,
    });
  }

  /**
   * Close the Add Device Modal
   */
  _closeAddDeviceModal() {
    this.props.clearRegisterState();
    this.setState({
      showAddDeviceModal: false,
    });
  }

  /**
   * Show the Add Device Modal
   */
  _showAddDeviceModal() {
    this.setState({
      showAddDeviceModal: true,
    });
  }

  /**
   * This will fetch all of the devices registered to this user.
   */
  _handleGetUserDevices() {
    this.props.getUserDevices();
  }

  /**
   * Kicks off the process of registering a device to a user.
   */
  _handleRegisterDevice() {
    const { deviceCode, deviceLabel } = this.state;
    const { registerDevice } = this.props;
    console.log(deviceLabel);
    registerDevice(deviceCode, deviceLabel);
  }

  /**
   * Called when the trash button is clicked for a device. This
   * will unregister the device from the user.
   *
   * @param {string} deviceId
   */
  _handleUnregisterDevice() {
    const { unregisterDevice } = this.props;
    const { removeDeviceId } = this.state;

    this.setState({
      showConfirmRemoveModal: false,
      removeDeviceId: '',
    });

    unregisterDevice(removeDeviceId);
  }

  /**
   * Event handler for when the user inputs a value into the device name
   * field.
   *
   * @param {string} fieldId 'device_name'
   * @param {string} value value of the device name
   */
  _handleDeviceNameInput(fieldId, value) {
    this.setState({
      newDeviceName: value,
    });
  }

  /**
   * Event handler to update the device name.
   */
  _handleEditDevice() {
    const { editDeviceId, newDeviceName } = this.state;
    const { updateDeviceName } = this.props;

    updateDeviceName(editDeviceId, newDeviceName);

    this.setState({
      showEditDeviceModal: false,
      editDeviceId: '',
      editDeviceName: '',
      newDeviceName: '',
    });
  }

  /**
   * Creates the inner content for the confirm removal modal.
   */
  _generateConfirmRemovalContent() {
    return (
      <View style={[styles.confirmRemovalModalContent]}>
        <Text style={[styles.confirmRemovalText]}>
          Removing this device will remove your ability to control this device in the nexus system and will remove it from your device list and all jobs that it is associated with.
        </Text>
        <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._handleUnregisterDevice}>
          <Text style={[global.confirmationButtonText]}>
            REMOVE DEVICE
          </Text>
        </Button>
        <Button style={[global.cancelButton, { marginBottom: 15 }]} onPress={this._closeConfirmRemoveModal}>
          <Text style={[global.cancelButtonText]}>
            DO NOT REMOVE DEVICE
          </Text>
        </Button>
      </View>
    );
  }

  /**
   * Creates the inner content for the add device modal.
   */
  _generateAddDeviceContent() {
    const {
      isRegisteringDevice,
      registerDeviceFailed,
      registerDeviceErrorMessage,
    } = this.props;
    return (
      <View style={[styles.addDeviceModalContent]}>
        <Text style={[styles.addDeviceModalText]}>
          Enter 6 digit bf-device code which can be found in the device info section on the panel display.
        </Text>
        <Text style={[styles.deviceCodePrefixText]}>
            bf-device-
        </Text>
        <View style={[styles.deviceCodeContainer]}>
          <Item style={ [global.item, styles.item, {}]} disabled>
            <Input
              placeholder={'# # # # # #'}
              style={ [styles.codeInput] }
              placeholderTextColor='white'
              onChangeText={ this._handleCodeChange }
            />
          </Item>
        </View>

        <Text style={[styles.nameDeviceHeader]}>
          Customize your device's name
        </Text>
        <View style={[styles.nameDeviceFormInput]}>
          <Item style={[styles.nameDeviceItem]}>
            <Input
            placeholder={'bf-device-######'}
            style={[styles.nameDeviceInput]}
            placeholderTextColor='white'
            onChangeText={ this._handleDeviceNameChange }
            />
          </Item>
        </View>
        <Text style={[styles.nameDeviceFooter]}>
          Use a name that's easy to remember!
        </Text>
        {
          registerDeviceFailed &&
            <Text style={[styles.errorMessage]}>
              { registerDeviceErrorMessage }
            </Text>
        }
        {
          isRegisteringDevice ?
            <Spinner color={'red'} />
            :
            <Button style={[global.confirmationButton]} onPress={this._handleRegisterDevice}>
              <Text style={[global.confirmationButtonText]}>
                ADD DEVICE
              </Text>
            </Button>
        }
      </View>
    );
  }

  /**
   *
   */
  _generateEditDeviceContent() {
    const { editDeviceName } = this.state;

    return (
      <View style={[styles.editDeviceModalContent]}>
        <Text style={[styles.editDeviceModalText]}>
          Make the changes to the device information below.
        </Text>
        <Text style={[styles.editDeviceCurrentIdText]}>
          Current device name: { editDeviceName }
        </Text>
        <FormInput
          fieldId={'deviceName'}
          placeholder={'New Device Name'}
          onChange={this._handleDeviceNameInput}
          itemStyles={[global.item]}
          inputStyles={[global.input]}
        />
        <Button style={[global.confirmationButton, { marginBottom: 15, marginTop: 15 }]} onPress={this._handleEditDevice}>
          <Text style={[global.confirmationButtonText]}>
            SAVE CHANGES
          </Text>
        </Button>
        <Button style={[global.cancelButton, { marginBottom: 15 }]} onPress={this._closeEditDeviceModal}>
          <Text style={[global.cancelButtonText]}>
            EXIT WITHOUT SAVING
          </Text>
        </Button>
      </View>
    );
  }

  /**
   * Component Render
   */
  render() {
    const {
      devices,
      isFetchingDevices,
      isUpdatingDeviceName
    } = this.props;

    const {
      showConfirmRemoveModal,
      showAddDeviceModal,
      showEditDeviceModal,
    } = this.state;

    // Generate a row component for each device.
    const deviceRows = [];
    for (const [index, device] of devices.entries()) {
      const deviceId = device['device_id'];
      const deviceName = device['device_name'] || deviceId;

      const lightRow = index % 2 !== 0;

      deviceRows.push((
          <View key={deviceId} style={[styles.deviceRow, lightRow ? styles.lightRow : {}]}>
          {/* Subject / Type */}
          <View style={[styles.deviceDetailsContainer]}>
            <Text style={[styles.deviceId]}>{deviceId}</Text>
            <Text style={[styles.deviceName]}>{deviceName}</Text>
          </View>

          {/* <View style={[styles.actionsContainer]}>
            <View>
              <TrashButton data={deviceId} onClick={this._showConfirmRemoveModal} />
            </View>
            <View>
              <EditButton data={{deviceId, deviceName}} onClick={this._showEditDeviceModal} />
            </View>
          </View> */}
        </View>
      ));
    }


    return (
      <View style={[styles.devices]}>
        <Text style={[global.pageTitle]}>
          STATIONS
        </Text>

        <View style={[styles.deviceTable, global.dropShadow]}>
          { isFetchingDevices || isUpdatingDeviceName ?
              <Spinner color="red" />
              :
              deviceRows
          }
        </View>
        {/* <View style={[styles.addDeviceButtonContainer]}>
          <Button style={[global.confirmationButton, styles.addDeviceButton]} onPress={this._showAddDeviceModal}>
            <Text style={[global.confirmationButtonText, styles.addDeviceButtonText]}>
              ADD DEVICE
            </Text>
          </Button>
        </View> */}

        {/* Confirm Delete */}
        {/* <Modal
          isVisible={showConfirmRemoveModal}
          title={'REMOVE DEVICE'}
          hideExitIcon={true}
          onClose={this._closeConfirmRemoveModal}
          content={this._generateConfirmRemovalContent()}
        /> */}

        {/* Add Device */}
        {/* <Modal
          isVisible={showAddDeviceModal}
          title={'ADD A DEVICE'}
          onClose={this._closeAddDeviceModal}
          content={showAddDeviceModal ? this._generateAddDeviceContent() : null}
        /> */}

        {/* Edit Device */}
        <Modal
          isVisible={showEditDeviceModal}
          title={'EDIT DEVICE INFORMATION'}
          onClose={this._closeEditDeviceModal}
          content={showEditDeviceModal ? this._generateEditDeviceContent() : null}
        />

      </View>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.user.devices,
    isFetchingDevices: state.user.isFetchingDevices,
    isRegisteringDevice: state.user.isRegisteringDevice,
    isUpdatingDeviceName: state.iot.isUpdatingDeviceName,
    registerDeviceFailed: state.user.registerDeviceFailed,
    registerDeviceErrorMessage: state.user.registerDeviceErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearRegisterState: () => dispatch(clearRegisterState()),
    getUserDevices: () => dispatch(getUserDevices()),
    registerDevice: (deviceCode, deviceLabel) => dispatch(registerDevice(deviceCode, deviceLabel)),
    unregisterDevice: (deviceId) => dispatch(unregisterDevice(deviceId)),
    updateDeviceName: (deviceId, deviceName) => dispatch(updateDeviceName(deviceId, deviceName)),
  };
};

Devices.propTypes = {
  clearRegisterState: PropTypes.func.isRequired,
  devices: PropTypes.array.isRequired,
  getUserDevices: PropTypes.func.isRequired,
  isFetchingDevices: PropTypes.bool.isRequired,
  isRegisteringDevice: PropTypes.bool.isRequired,
  isUpdatingDeviceName: PropTypes.bool.isRequired,
  navigation: PropTypes.any.isRequired,
  registerDevice: PropTypes.func.isRequired,
  registerDeviceFailed: PropTypes.bool.isRequired,
  registerDeviceErrorMessage: PropTypes.string.isRequired,
  unregisterDevice: PropTypes.func.isRequired,
  updateDeviceName: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
