// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Amplify
import { Auth } from 'aws-amplify';

// Redux
import { connect } from 'react-redux';
import { clearState } from '_redux/rootActions';
import {
  leaveAndJoinCrew,
  startInviteFlow,
} from '_redux/user/operations';
import {
  USER_HAS_DEVICES_ERROR_CODE,
  USER_IN_CREW_ERROR_CODE,
  USER_IS_ADMIN,
} from '_redux/crewMembers/utils';
import {
  INVITE_CODE_KEY,
} from '_redux/user/utils';

// UI Framework
import {
  Button,
  Text,
  View,
  Spinner,
} from 'native-base';
import {
  AsyncStorage,
  Linking,
  Image,
} from 'react-native';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_components/common/InviteModalContent.style';

/**
 * The InviteModalContent Component is responsible for working a user
 * through the flow of joining a crew given an invite code.
 */
class InviteModalContent extends Component {
  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      userAddedToCrew: false, // true if the user has been successfully added to the crew.
      userAddedToSystem: false, // true if the user has been successfully added as a system user.
      showConfirmLeave: false, // true if the user is trying to leave and join a crew.
    };

    this._close = this._close.bind(this);

    this._createNewAccount = this._createNewAccount.bind(this);
    this._leaveAndJoinCrew = this._leaveAndJoinCrew.bind(this);
  }

  /**
   * As soon as this modal mounts, grab the invite code out of local storage
   * and make a call to check for eligibility to join a crew. If the user is
   * eligible, add them without prompt.
   */
  async componentDidMount() {
    const { startInviteFlow } = this.props;
    const inviteCode = await AsyncStorage.getItem(INVITE_CODE_KEY);

    startInviteFlow(inviteCode);
  }

  /**
   * Check to see if the crew member was successfully added.
   *
   * @param {*} prevProps
   */
  async componentDidUpdate(prevProps) {
    const {
      isAddingCrewMember,
      failedToAddCrewMember,

      isAddingSystemUser,
      addSystemUserFailed,
    } = this.props;

    const {
      isAddingCrewMember: prevIsAddingCrewMember,
      isAddingSystemUser: prevIsAddingSystemUser,
    } = prevProps;

    // Check for crew member add success
    if (prevIsAddingCrewMember && !isAddingCrewMember && !failedToAddCrewMember) {
      this.setState({
        userAddedToCrew: true,
      });
    }

    // Check for system user add success
    if (prevIsAddingSystemUser && !isAddingSystemUser && !addSystemUserFailed) {
      this.setState({
        userAddedToSystem: true,
      });
    }
  }

  /**
   * User has declined to accept the invite, refresh the page
   * dropping the inviteCode query parameter and removing it from
   * local storage.
   */
  async _close() {
    await AsyncStorage.removeItem(INVITE_CODE_KEY);
    await Linking.openURL('/');
  }

  /**
   * User has chosen to leave their current crew and join the new
   * one from the invite code. Present a confirmation on first click.
   */
  async _leaveAndJoinCrew() {
    const { leaveAndJoinCrew } = this.props;
    const { showConfirmLeave } = this.state;

    const inviteCode = await AsyncStorage.getItem(INVITE_CODE_KEY);

    // Show confirmation modal if this is the user's first click
    // on the "Leave and Join" button.
    if (showConfirmLeave) {
      leaveAndJoinCrew(inviteCode);
    } else {
      this.setState({
        showConfirmLeave: true,
      });
    }
  }

  /**
   * Event handler when the user clicks the "Create New Account"
   * button. This will clear the redux state and sign the user out.
   * Leave the invite code in local storage.
   */
  async _createNewAccount() {
    const { clearState } = this.props;

    Auth.signOut({global: true})
      .then(() => {
        clearState();
      });
  }

  /**
   * Component Render.
   */
  render() {
    const {
      crewMemberEligibilityError,
      crewMemberCheckAndAddInProgress,
      leaveAndAddInProgress,
      systemUserEligibilityError,
      systemUserCheckAndAddInProgress,
    } = this.props;

    const {
      showConfirmLeave,
      userAddedToCrew,
      userAddedToSystem,
    } = this.state;

    // Determine what state this modal is in based on eligibility errors and redux
    // async action state.
    const userHasDevices = crewMemberEligibilityError === USER_HAS_DEVICES_ERROR_CODE;
    const userIsInCrew = crewMemberEligibilityError === USER_IN_CREW_ERROR_CODE;
    const userIsAdmin = systemUserEligibilityError === USER_IS_ADMIN || crewMemberEligibilityError === USER_IS_ADMIN;
    const processing = crewMemberCheckAndAddInProgress || leaveAndAddInProgress || systemUserCheckAndAddInProgress;

    // Determine the appropriate error message depending on the state we're in.
    let errorDescription = null;
    if (showConfirmLeave) {
      errorDescription = 'You will be removed from your current crew and no longer have access to those jobs. Are you sure you want to continue?';
    } else if (userHasDevices) {
      errorDescription = 'This Account Is already Assigned as an Owner Account with assigned devices. You will have to create a new account to join another crew.';
    } else if (userIsInCrew) {
      errorDescription = 'This Account Is already associated with an active crew. You will need to leave that crew to join another crew or create a new account';
    } else if (userIsAdmin) {
      errorDescription = 'This Account is already in the system as an administrative account. You will need to create a new account to join a crew.';
    }

    return (
      <View style={[styles.inviteModalContentContainer]}>
        {/* Loading spinner. Shows when their is an async redux action taking place. */}
        {
          processing &&
            <View>
              <Spinner color={'red'} />
              <Text style={[styles.successDescriptionText]}>
                { systemUserCheckAndAddInProgress ? 'Adding you to system...' : 'Adding you to crew...' }
              </Text>
            </View>
        }
        {/* Error case, either the user is an owner with devices, or they are already in a crew */}
        {/* Disabled this._leaveAndJoinCrew and this._createNewAccount from being called to avoid issues with
        assignments/devices being viewable by a user who shouldn't have access to them after changing
        the crew they're a part of. This can be added back (should be ensured it's not an issue or changed so that the issue is fixed)
        if it's decided changing crews is a useful feature in the future. This is in the code before
        disabling it: */}
        {/* userIsInCrew &&
                <Button style={[global.cancelButton, styles.actionButton]} onPress={this._leaveAndJoinCrew}>
                  <Text style={[global.cancelButtonText, styles.closeButtonText]}>
                    LEAVE CREW &amp; JOIN NEW CREW
                  </Text>
                </Button>
              }
              <Button style={[global.cancelButton, styles.actionButton]} onPress={this._createNewAccount}>
                <Text style={[global.cancelButtonText, styles.closeButtonText]}>
                  CREATE NEW ACCOUNT
                </Text>
              </Button> */}
        {
          !processing && (userIsInCrew || userHasDevices || userIsAdmin) &&
            <View style={[styles.errorContainer]}>
              {
                showConfirmLeave ?
                  <>
                    <Image style={[styles.warningIcon]} source={require('_assets/images/warning.png')} />
                    <Text style={[styles.warningText]}>
                      NOTICE
                    </Text>
                  </>
                  :
                  <>
                    <Image style={[styles.errorIcon]} source={require('_assets/images/error-x.png')} />
                    <Text style={[styles.wrongText]}>
                      SOMETHING&apos;S WRONG
                    </Text>
                  </>
              }

              <Text style={[styles.errorDescriptionText]}>
                { errorDescription }
              </Text>

              {
                userIsInCrew &&
                <Button style={[global.cancelButton, styles.actionButton]}>
                  <Text style={[global.cancelButtonText, styles.closeButtonText]}>
                    LEAVE CREW &amp; JOIN NEW CREW
                  </Text>
                </Button>
              }
              <Button style={[global.cancelButton, styles.actionButton]}>
                <Text style={[global.cancelButtonText, styles.closeButtonText]}>
                  CREATE NEW ACCOUNT
                </Text>
              </Button>
            </View>
        }
        {/* Success case, they were automatically added to the crew */}
        {
          !processing && (userAddedToCrew || userAddedToSystem) &&
            <View style={[styles.successContainer]}>
              <Image style={[styles.successIcon]} source={require('_assets/images/success-check.png')} />
              <Text style={[styles.successText]}>
                CONGRATULATIONS
              </Text>
              <Text style={[styles.successDescriptionText]}>
                { userAddedToSystem ? 'YOU HAVE SUCCESSFULLY JOINED THE SYSTEM' : 'YOU HAVE SUCCESSFULLY JOINED THE CREW' }
              </Text>
            </View>
        }
        <Button style={[global.cancelButton, styles.actionButton]} disabled={processing} onPress={this._close}>
          <Text style={[global.cancelButtonText, styles.closeButtonText]}>
            CLOSE
          </Text>
        </Button>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    crewMemberEligibilityError: state.crewMembers.crewMemberEligibilityError,
    isAddingCrewMember: state.crewMembers.isAddingCrewMember,
    failedToAddCrewMember: state.crewMembers.failedToAddCrewMember,

    crewMemberCheckAndAddInProgress: state.crewMembers.checkAndAddInProgress,
    leaveAndAddInProgress: state.crewMembers.leaveAndAddInProgress,

    isAddingSystemUser: state.admin.isAddingSystemUser,
    addSystemUserFailed: state.admin.addSystemUserFailed,
    systemUserCheckAndAddInProgress: state.admin.checkAndAddInProgress,
    systemUserEligibilityError: state.admin.systemUserEligibilityError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearState: () => dispatch(clearState()),
    leaveAndJoinCrew: (inviteCode) => dispatch(leaveAndJoinCrew(inviteCode)),
    startInviteFlow: (inviteCode) => dispatch(startInviteFlow(inviteCode)),
  };
};

InviteModalContent.propTypes = {
  crewMemberEligibilityError: PropTypes.string.isRequired,
  isAddingCrewMember: PropTypes.bool.isRequired,
  failedToAddCrewMember: PropTypes.bool.isRequired,

  isAddingSystemUser: PropTypes.bool.isRequired,
  addSystemUserFailed: PropTypes.bool.isRequired,
  systemUserEligibilityError: PropTypes.string.isRequired,

  crewMemberCheckAndAddInProgress: PropTypes.bool.isRequired,
  systemUserCheckAndAddInProgress: PropTypes.bool.isRequired,

  leaveAndJoinCrew: PropTypes.func.isRequired,
  leaveAndAddInProgress: PropTypes.bool.isRequired,
  startInviteFlow: PropTypes.func.isRequired,

  clearState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteModalContent);
