// Util
import { isUndefined } from '_util';
import {
  convertCelsiustoFahrenheit,
  convertKPAToPSI,
  convertLPHToGPH,
  convertPitDepth,
  iterateBinary,
  dec2bin
} from '_redux/iot/utils';




// Default value for missing telemetry data.
export const TELEMETRY_DEFAULT_VALUE = '-';

/**
 * Returns true if there is a request out to update a device name,
 * false otherwise.
 *
 * @param {*} state redux state
 */
export const isUpdatingDeviceName = (state) => {
  return state.iot.isUpdatingDeviceName;
};

/**
 * Returns a single device from the state.
 *
 * @param {*} state redux state
 * @param {string} deviceId id of the device to fetch
 */
export const getIoTDeviceById = (state, deviceId) => {
  return state.iot.devices[deviceId] || null;
};

/**
 * Returns true if there is a subscription to the device with the provided device id.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const iotDeviceIsConnected = (state, deviceId) => {
  return (state.iot.devices[deviceId] && state.iot.devices[deviceId].isConnected ? true : false);
};

/**
 * Returns the setpoints for the device with the provided device id.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const getSetPointsForDevice = (state, deviceId) => {

  const device = state.iot.devices[deviceId];

  if (device && !isUndefined(device.setPoints)) {
    const { setPoints } = device;

    if (setPoints.current) {
      return setPoints.current.state.reported;
    }

    if (setPoints.state) {
      return setPoints.state.reported;
    }
  }

  return {};
};

/**
 * Returns the telemetry for the device with the provided device id.
 * It performs conversions to the correct unit (e.g. KPA to PSI) for
 * the appropriate metrics.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const getTelemetryForDevice = (state, deviceId) => {

  const device = getIoTDeviceById(state, deviceId);
  const { telemetry = {} } = device || {};

  const { REMOTE_DATA_TX = {} } = telemetry || {};


  return {
    //New parts for municipal web app
    e1s1: !isUndefined(REMOTE_DATA_TX.e1s1) ? REMOTE_DATA_TX.e1s1 : TELEMETRY_DEFAULT_VALUE,
    e2s1: !isUndefined(REMOTE_DATA_TX.e2s1) ? REMOTE_DATA_TX.e2s1 : TELEMETRY_DEFAULT_VALUE,
    e3s1: !isUndefined(REMOTE_DATA_TX.e3s1) ? REMOTE_DATA_TX.e3s1 : TELEMETRY_DEFAULT_VALUE,
    e4s1: !isUndefined(REMOTE_DATA_TX.e4s1) ? REMOTE_DATA_TX.e4s1 : TELEMETRY_DEFAULT_VALUE,
    e5s1: !isUndefined(REMOTE_DATA_TX.e5s1) ? REMOTE_DATA_TX.e5s1 : TELEMETRY_DEFAULT_VALUE,
    e1s2: !isUndefined(REMOTE_DATA_TX.e1s2) ? REMOTE_DATA_TX.e1s2 : TELEMETRY_DEFAULT_VALUE,
    e2s2: !isUndefined(REMOTE_DATA_TX.e2s2) ? REMOTE_DATA_TX.e2s2 : TELEMETRY_DEFAULT_VALUE,
    e3s2: !isUndefined(REMOTE_DATA_TX.e3s2) ? REMOTE_DATA_TX.e3s2 : TELEMETRY_DEFAULT_VALUE,
    e4s2: !isUndefined(REMOTE_DATA_TX.e4s2) ? REMOTE_DATA_TX.e4s2 : TELEMETRY_DEFAULT_VALUE,
    e5s2: !isUndefined(REMOTE_DATA_TX.e5s2) ? REMOTE_DATA_TX.e5s2 : TELEMETRY_DEFAULT_VALUE,
    e1s3: !isUndefined(REMOTE_DATA_TX.e1s3) ? REMOTE_DATA_TX.e1s3 : TELEMETRY_DEFAULT_VALUE,
    e2s3: !isUndefined(REMOTE_DATA_TX.e2s3) ? REMOTE_DATA_TX.e2s3 : TELEMETRY_DEFAULT_VALUE,
    e3s3: !isUndefined(REMOTE_DATA_TX.e3s3) ? REMOTE_DATA_TX.e3s3 : TELEMETRY_DEFAULT_VALUE,
    e4s3: !isUndefined(REMOTE_DATA_TX.e4s3) ? REMOTE_DATA_TX.e4s3 : TELEMETRY_DEFAULT_VALUE,
    e5s3: !isUndefined(REMOTE_DATA_TX.e5s3) ? REMOTE_DATA_TX.e5s3 : TELEMETRY_DEFAULT_VALUE,
    ss1: !isUndefined(REMOTE_DATA_TX.ss1) ? REMOTE_DATA_TX.ss1 : TELEMETRY_DEFAULT_VALUE,
    ss2: !isUndefined(REMOTE_DATA_TX.ss2) ? REMOTE_DATA_TX.ss2 : TELEMETRY_DEFAULT_VALUE,
    ss3: !isUndefined(REMOTE_DATA_TX.ss3) ? REMOTE_DATA_TX.ss3 : TELEMETRY_DEFAULT_VALUE,
    ss4: !isUndefined(REMOTE_DATA_TX.ss4) ? REMOTE_DATA_TX.ss4 : TELEMETRY_DEFAULT_VALUE,
    ss5: !isUndefined(REMOTE_DATA_TX.ss5) ? REMOTE_DATA_TX.ss5 : TELEMETRY_DEFAULT_VALUE,
    ss6: !isUndefined(REMOTE_DATA_TX.ss6) ? REMOTE_DATA_TX.ss6 : TELEMETRY_DEFAULT_VALUE,
    ss7: !isUndefined(REMOTE_DATA_TX.ss7) ? REMOTE_DATA_TX.ss7 : TELEMETRY_DEFAULT_VALUE,
    e1_rpm: !isUndefined(REMOTE_DATA_TX.e1_rpm) ? REMOTE_DATA_TX.e1_rpm : TELEMETRY_DEFAULT_VALUE,
    e1_hrs_1: !isUndefined(REMOTE_DATA_TX.e1_hrs_1) ? REMOTE_DATA_TX.e1_hrs_1 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e1_hrs_2: !isUndefined(REMOTE_DATA_TX.e1_hrs_2) ? REMOTE_DATA_TX.e1_hrs_2 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e1_vdc: !isUndefined(REMOTE_DATA_TX.e1_vdc) ? REMOTE_DATA_TX.e1_vdc : TELEMETRY_DEFAULT_VALUE,
    e2_rpm: !isUndefined(REMOTE_DATA_TX.e2_rpm) ? REMOTE_DATA_TX.e2_rpm : TELEMETRY_DEFAULT_VALUE,
    e2_hrs_1: !isUndefined(REMOTE_DATA_TX.e2_hrs_1) ? REMOTE_DATA_TX.e2_hrs_1 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e2_hrs_2: !isUndefined(REMOTE_DATA_TX.e2_hrs_2) ? REMOTE_DATA_TX.e2_hrs_2 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e2_vdc: !isUndefined(REMOTE_DATA_TX.e2_vdc) ? REMOTE_DATA_TX.e2_vdc : TELEMETRY_DEFAULT_VALUE,
    e3_rpm: !isUndefined(REMOTE_DATA_TX.e3_rpm) ? REMOTE_DATA_TX.e3_rpm : TELEMETRY_DEFAULT_VALUE,
    e3_hrs_1: !isUndefined(REMOTE_DATA_TX.e3_hrs_1) ? REMOTE_DATA_TX.e3_hrs_1 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e3_hrs_2: !isUndefined(REMOTE_DATA_TX.e3_hrs_2) ? REMOTE_DATA_TX.e3_hrs_2 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e3_vdc: !isUndefined(REMOTE_DATA_TX.e3_vdc) ? REMOTE_DATA_TX.e3_vdc : TELEMETRY_DEFAULT_VALUE,
    e4_rpm: !isUndefined(REMOTE_DATA_TX.e4_rpm) ? REMOTE_DATA_TX.e4_rpm : TELEMETRY_DEFAULT_VALUE,
    e4_hrs_1: !isUndefined(REMOTE_DATA_TX.e4_hrs_1) ? REMOTE_DATA_TX.e4_hrs_1 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e4_hrs_2: !isUndefined(REMOTE_DATA_TX.e4_hrs_2) ? REMOTE_DATA_TX.e4_hrs_2 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e4_vdc: !isUndefined(REMOTE_DATA_TX.e4_vdc) ? REMOTE_DATA_TX.e4_vdc : TELEMETRY_DEFAULT_VALUE,
    e5_rpm: !isUndefined(REMOTE_DATA_TX.e5_rpm) ? REMOTE_DATA_TX.e5_rpm : TELEMETRY_DEFAULT_VALUE,
    e5_hrs_1: !isUndefined(REMOTE_DATA_TX.e5_hrs_1) ? REMOTE_DATA_TX.e5_hrs_1 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e5_hrs_2: !isUndefined(REMOTE_DATA_TX.e5_hrs_2) ? REMOTE_DATA_TX.e5_hrs_2 : TELEMETRY_DEFAULT_VALUE, //hrs_1 and hrs_2 hours should be added when displaying the total amount on the UI.
    e5_vdc: !isUndefined(REMOTE_DATA_TX.e5_vdc) ? REMOTE_DATA_TX.e5_vdc : TELEMETRY_DEFAULT_VALUE,
    s_lvl: !isUndefined(REMOTE_DATA_TX.s_lvl) ? REMOTE_DATA_TX.s_lvl : TELEMETRY_DEFAULT_VALUE,
    s_lvl_maint: !isUndefined(REMOTE_DATA_TX.s_lvl_maint) ? REMOTE_DATA_TX.s_lvl_maint : TELEMETRY_DEFAULT_VALUE,
    s_lvl_dband: !isUndefined(REMOTE_DATA_TX.s_lvl_dband) ? REMOTE_DATA_TX.s_lvl_dband : TELEMETRY_DEFAULT_VALUE,
    s_lvl_stop: !isUndefined(REMOTE_DATA_TX.s_lvl_stop) ? REMOTE_DATA_TX.s_lvl_stop : TELEMETRY_DEFAULT_VALUE,
    sp1_strl: !isUndefined(REMOTE_DATA_TX.sp1_strl) ? REMOTE_DATA_TX.sp1_strl : TELEMETRY_DEFAULT_VALUE,
    sp1_stpl: !isUndefined(REMOTE_DATA_TX.sp1_stpl) ? REMOTE_DATA_TX.sp1_stpl : TELEMETRY_DEFAULT_VALUE,
    sp2_strl: !isUndefined(REMOTE_DATA_TX.sp2_strl) ? REMOTE_DATA_TX.sp2_strl : TELEMETRY_DEFAULT_VALUE,
    sp2_stpl: !isUndefined(REMOTE_DATA_TX.sp2_stpl) ? REMOTE_DATA_TX.sp2_stpl : TELEMETRY_DEFAULT_VALUE,
    sp3_strl: !isUndefined(REMOTE_DATA_TX.sp3_strl) ? REMOTE_DATA_TX.sp3_strl : TELEMETRY_DEFAULT_VALUE,
    sp3_stpl: !isUndefined(REMOTE_DATA_TX.sp3_stpl) ? REMOTE_DATA_TX.sp3_stpl : TELEMETRY_DEFAULT_VALUE,
    sp1_s: !isUndefined(REMOTE_DATA_TX.sp1_s) ? REMOTE_DATA_TX.sp1_s : TELEMETRY_DEFAULT_VALUE,
    sp2_s: !isUndefined(REMOTE_DATA_TX.sp2_s) ? REMOTE_DATA_TX.sp2_s : TELEMETRY_DEFAULT_VALUE,
    sp3_s: !isUndefined(REMOTE_DATA_TX.sp3_s) ? REMOTE_DATA_TX.sp3_s : TELEMETRY_DEFAULT_VALUE,
    c_vdc: !isUndefined(REMOTE_DATA_TX.c_vdc) ? REMOTE_DATA_TX.c_vdc : TELEMETRY_DEFAULT_VALUE,
  };
};

export const getAlisoViejoTelemetryForDevice = (state, deviceId) => {

  const device = getIoTDeviceById(state, deviceId);
  const { telemetry = {} } = device || {};

  const { REMOTE_DATA_TX = {} } = telemetry || {};

  return {
    ver_major: !isUndefined(REMOTE_DATA_TX[0]) ? REMOTE_DATA_TX[0] : TELEMETRY_DEFAULT_VALUE,
    ver_minor: !isUndefined(REMOTE_DATA_TX[1]) ? REMOTE_DATA_TX[1] : TELEMETRY_DEFAULT_VALUE,
    ver_build: !isUndefined(REMOTE_DATA_TX[2]) ? REMOTE_DATA_TX[2] : TELEMETRY_DEFAULT_VALUE,
    Time_Seconds: !isUndefined(REMOTE_DATA_TX[3]) ? REMOTE_DATA_TX[3] : TELEMETRY_DEFAULT_VALUE,
    Time_Minute: !isUndefined(REMOTE_DATA_TX[4]) ? REMOTE_DATA_TX[4] : TELEMETRY_DEFAULT_VALUE,
    Time_Hour: !isUndefined(REMOTE_DATA_TX[5]) ? REMOTE_DATA_TX[5] : TELEMETRY_DEFAULT_VALUE,
    Time_Day: !isUndefined(REMOTE_DATA_TX[6]) ? REMOTE_DATA_TX[6] : TELEMETRY_DEFAULT_VALUE,
    Time_Month: !isUndefined(REMOTE_DATA_TX[7]) ? REMOTE_DATA_TX[7] : TELEMETRY_DEFAULT_VALUE,
    Time_Year: !isUndefined(REMOTE_DATA_TX[8]) ? REMOTE_DATA_TX[8] : TELEMETRY_DEFAULT_VALUE,
    Time_Week_Day: !isUndefined(REMOTE_DATA_TX[9]) ? REMOTE_DATA_TX[9] : TELEMETRY_DEFAULT_VALUE,
    Time_Year_Day: !isUndefined(REMOTE_DATA_TX[10]) ? REMOTE_DATA_TX[10] : TELEMETRY_DEFAULT_VALUE,
    Time_Is_Daylight_Savings_Time: !isUndefined(REMOTE_DATA_TX[11]) ? REMOTE_DATA_TX[11] : TELEMETRY_DEFAULT_VALUE,
    MB_SYSTEM_1_status_bits_local: !isUndefined(REMOTE_DATA_TX[12]) ? REMOTE_DATA_TX[12] : TELEMETRY_DEFAULT_VALUE,
    MB_SYSTEM_2_status_bits_local: !isUndefined(REMOTE_DATA_TX[13]) ? REMOTE_DATA_TX[13] : TELEMETRY_DEFAULT_VALUE,
    MB_SYSTEM_3_status_bits_local: !isUndefined(REMOTE_DATA_TX[14]) ? REMOTE_DATA_TX[14] : TELEMETRY_DEFAULT_VALUE,
    MB_SYSTEM_4_status_bits_local: !isUndefined(REMOTE_DATA_TX[15]) ? REMOTE_DATA_TX[15] : TELEMETRY_DEFAULT_VALUE,
    MB_alarm_shutdown_register_1: !isUndefined(REMOTE_DATA_TX[16]) ? REMOTE_DATA_TX[16] : TELEMETRY_DEFAULT_VALUE,
    system_voltage: !isUndefined(REMOTE_DATA_TX[17]) ? REMOTE_DATA_TX[17] : TELEMETRY_DEFAULT_VALUE,
    converted_analog_input_3: !isUndefined(REMOTE_DATA_TX[18]) ? REMOTE_DATA_TX[18] : TELEMETRY_DEFAULT_VALUE,
    converted_analog_input_4: !isUndefined(REMOTE_DATA_TX[19]) ? REMOTE_DATA_TX[19] : TELEMETRY_DEFAULT_VALUE,
    converted_analog_input_5: !isUndefined(REMOTE_DATA_TX[20]) ? REMOTE_DATA_TX[20] : TELEMETRY_DEFAULT_VALUE,
    P1_run_1000_hours: !isUndefined(REMOTE_DATA_TX[21]) ? REMOTE_DATA_TX[21] : TELEMETRY_DEFAULT_VALUE,
    P1_run_hours_tenths: !isUndefined(REMOTE_DATA_TX[22]) ? REMOTE_DATA_TX[22] : TELEMETRY_DEFAULT_VALUE,
    back_wash_run_1000_hours: !isUndefined(REMOTE_DATA_TX[23]) ? REMOTE_DATA_TX[23] : TELEMETRY_DEFAULT_VALUE,
    back_wash_run_hours_tenths: !isUndefined(REMOTE_DATA_TX[24]) ? REMOTE_DATA_TX[24] : TELEMETRY_DEFAULT_VALUE,
    sewer_flow_total_hi: !isUndefined(REMOTE_DATA_TX[25]) ? REMOTE_DATA_TX[25] : TELEMETRY_DEFAULT_VALUE,
    sewer_flow_total_low: !isUndefined(REMOTE_DATA_TX[26]) ? REMOTE_DATA_TX[26] : TELEMETRY_DEFAULT_VALUE,
    treated_flow_total_hi: !isUndefined(REMOTE_DATA_TX[27]) ? REMOTE_DATA_TX[27] : TELEMETRY_DEFAULT_VALUE,
    treated_flow_total_low: !isUndefined(REMOTE_DATA_TX[28]) ? REMOTE_DATA_TX[28] : TELEMETRY_DEFAULT_VALUE,
    MON_P1_run_hours: !isUndefined(REMOTE_DATA_TX[29]) ? REMOTE_DATA_TX[29] : TELEMETRY_DEFAULT_VALUE,
    TUE_P1_run_hours: !isUndefined(REMOTE_DATA_TX[30]) ? REMOTE_DATA_TX[30] : TELEMETRY_DEFAULT_VALUE,
    WED_P1_run_hours: !isUndefined(REMOTE_DATA_TX[31]) ? REMOTE_DATA_TX[31] : TELEMETRY_DEFAULT_VALUE,
    THR_P1_run_hours: !isUndefined(REMOTE_DATA_TX[32]) ? REMOTE_DATA_TX[32] : TELEMETRY_DEFAULT_VALUE,
    FRI_P1_run_hours: !isUndefined(REMOTE_DATA_TX[33]) ? REMOTE_DATA_TX[33] : TELEMETRY_DEFAULT_VALUE,
    SAT_P1_run_hours: !isUndefined(REMOTE_DATA_TX[34]) ? REMOTE_DATA_TX[34] : TELEMETRY_DEFAULT_VALUE,
    SUN_P1_run_hours: !isUndefined(REMOTE_DATA_TX[35]) ? REMOTE_DATA_TX[35] : TELEMETRY_DEFAULT_VALUE,
    MON_backwash_run_hours: !isUndefined(REMOTE_DATA_TX[36]) ? REMOTE_DATA_TX[36] : TELEMETRY_DEFAULT_VALUE,
    TUE_backwash_run_hours: !isUndefined(REMOTE_DATA_TX[37]) ? REMOTE_DATA_TX[37] : TELEMETRY_DEFAULT_VALUE,
    WED_backwash_run_hours: !isUndefined(REMOTE_DATA_TX[38]) ? REMOTE_DATA_TX[38] : TELEMETRY_DEFAULT_VALUE,
    THR_backwash_run_hours: !isUndefined(REMOTE_DATA_TX[39]) ? REMOTE_DATA_TX[39] : TELEMETRY_DEFAULT_VALUE,
    FRI_backwash_run_hours: !isUndefined(REMOTE_DATA_TX[40]) ? REMOTE_DATA_TX[40] : TELEMETRY_DEFAULT_VALUE,
    SAT_backwash_run_hours: !isUndefined(REMOTE_DATA_TX[41]) ? REMOTE_DATA_TX[41] : TELEMETRY_DEFAULT_VALUE,
    SUN_backwash_run_hours: !isUndefined(REMOTE_DATA_TX[42]) ? REMOTE_DATA_TX[42] : TELEMETRY_DEFAULT_VALUE,
    MON_sewer_flow: !isUndefined(REMOTE_DATA_TX[43]) ? REMOTE_DATA_TX[43] : TELEMETRY_DEFAULT_VALUE,
    TUE_sewer_flow: !isUndefined(REMOTE_DATA_TX[44]) ? REMOTE_DATA_TX[44] : TELEMETRY_DEFAULT_VALUE,
    WED_sewer_flow: !isUndefined(REMOTE_DATA_TX[45]) ? REMOTE_DATA_TX[45] : TELEMETRY_DEFAULT_VALUE,
    THR_sewer_flow: !isUndefined(REMOTE_DATA_TX[46]) ? REMOTE_DATA_TX[46] : TELEMETRY_DEFAULT_VALUE,
    FRI_sewer_flow: !isUndefined(REMOTE_DATA_TX[47]) ? REMOTE_DATA_TX[47] : TELEMETRY_DEFAULT_VALUE,
    SAT_sewer_flow: !isUndefined(REMOTE_DATA_TX[48]) ? REMOTE_DATA_TX[48] : TELEMETRY_DEFAULT_VALUE,
    SUN_sewer_flow: !isUndefined(REMOTE_DATA_TX[49]) ? REMOTE_DATA_TX[49] : TELEMETRY_DEFAULT_VALUE,
    MON_treated_flow: !isUndefined(REMOTE_DATA_TX[50]) ? REMOTE_DATA_TX[50] : TELEMETRY_DEFAULT_VALUE,
    TUE_treated_flow: !isUndefined(REMOTE_DATA_TX[51]) ? REMOTE_DATA_TX[51] : TELEMETRY_DEFAULT_VALUE,
    WED_treated_flow: !isUndefined(REMOTE_DATA_TX[52]) ? REMOTE_DATA_TX[52] : TELEMETRY_DEFAULT_VALUE,
    THR_treated_flow: !isUndefined(REMOTE_DATA_TX[53]) ? REMOTE_DATA_TX[53] : TELEMETRY_DEFAULT_VALUE,
    FRI_treated_flow: !isUndefined(REMOTE_DATA_TX[54]) ? REMOTE_DATA_TX[54] : TELEMETRY_DEFAULT_VALUE,
    SAT_treated_flow: !isUndefined(REMOTE_DATA_TX[55]) ? REMOTE_DATA_TX[55] : TELEMETRY_DEFAULT_VALUE,
    SUN_treated_flow: !isUndefined(REMOTE_DATA_TX[56]) ? REMOTE_DATA_TX[56] : TELEMETRY_DEFAULT_VALUE,
    first_stage_start: !isUndefined(REMOTE_DATA_TX[57]) ? REMOTE_DATA_TX[57] : TELEMETRY_DEFAULT_VALUE,
    first_stage_stop: !isUndefined(REMOTE_DATA_TX[58]) ? REMOTE_DATA_TX[58] : TELEMETRY_DEFAULT_VALUE,
    UV_activation_enable_flow: !isUndefined(REMOTE_DATA_TX[59]) ? REMOTE_DATA_TX[59] : TELEMETRY_DEFAULT_VALUE,
    FCV_man_output_percent: !isUndefined(REMOTE_DATA_TX[60]) ? REMOTE_DATA_TX[60] : TELEMETRY_DEFAULT_VALUE,
    sewer_flow_gal_per_pulse: !isUndefined(REMOTE_DATA_TX[61]) ? REMOTE_DATA_TX[61] : TELEMETRY_DEFAULT_VALUE,
    treated_flow_gal_per_pulse: !isUndefined(REMOTE_DATA_TX[62]) ? REMOTE_DATA_TX[62] : TELEMETRY_DEFAULT_VALUE,
  };
};

export const getWaterLevelForDevice = (state, deviceId) => {

  const device = getIoTDeviceById(state, deviceId);
  const { waterLevelData = {} } = device || {};



  return {
    waterLevelData
  };
};


export const getTelemetryForEngineBitPack = (state, deviceId) => {
  const {
    e1s1, //Bit packed decimal. See engineStatus1BitPack for description
    e2s1, //Bit packed decimal. See engineStatus1BitPack for description
    e3s1, //Bit packed decimal. See engineStatus1BitPack for description
    e4s1, //Bit packed decimal. See engineStatus1BitPack for description
    e5s1, //Bit packed decimal. See engineStatus1BitPack for description
    e1s2, //Bit packed decimal. See engineStatus2BitPack for description
    e2s2, //Bit packed decimal. See engineStatus2BitPack for description
    e3s2, //Bit packed decimal. See engineStatus2BitPack for description
    e4s2, //Bit packed decimal. See engineStatus2BitPack for description
    e5s2, //Bit packed decimal. See engineStatus2BitPack for description
    e1s3, //Bit packed decimal. See engineStatus3BitPack for description
    e2s3, //Bit packed decimal. See engineStatus3BitPack for description
    e3s3, //Bit packed decimal. See engineStatus3BitPack for description
    e4s3, //Bit packed decimal. See engineStatus3BitPack for description
    e5s3, //Bit packed decimal. See engineStatus3BitPack for description
  } = getTelemetryForDevice(state, deviceId);

  const engineStatus1BitPack =
  {
    b_eng_1_engine_ready: false,
    b_eng_1_engine_run_out: false,
    b_eng_1_engine_at_load: false,
    b_eng_1_engine_fail_out: false,
    b_eng_1_emergency_start_active_status: false,
    b_eng_1_clutch_disengaged: false,
    b_eng_1_engine_status: false,
    b_eng_1_prelube_timing: false,
    b_eng_1_crank_timing: false,
    b_eng_1_rest_timing: false,
    b_eng_1_lockout_timing: false,
    b_eng_1_warmup_timing: false,
    b_eng_1_stop_timing: false,
    b_eng_1_cooldown_timing: false,
    b_eng_1_start_timing: false,
    unassigned: false,
  }

  const engineStatus2BitPack =
  {
    b_eng_1_low_oil_press_SDown_alarm: false,
    b_eng_1_Over_Underload_left_or_common_SDown_alarm: false,
    b_eng_1_hi_water_temp_SDown_alarm: false,
    b_eng_1_hi_eng_oil_temp_SDown_alarm: false,
    b_eng_1_Over_Underload_right_SDown_alarm: false,
    b_eng_1_lo_coolant_level_SDown_alarm: false,
    b_eng_1_hi_lo_eng_oil_level_SDown_alarm: false,
    b_eng_1_excess_vibration_SDown_alarm: false,
    b_eng_1_hi_cat_temp_SDowm_alarm: false,
    b_eng_1_hi_gear_oil_temp_SDown_alarm: false,
    b_eng_1_lo_gear_pressure_SDown_alarm: false,
    Unassigned: false,
    b_eng_1_throttle_up_shutdown_alarm: false,
    b_eng_1_overspeed_alarm: false,
    b_eng_1_overcrank_alarm: false,
    b_eng_1_loss_of_speed_alarm: false,
  }

  const engineStatus3BitPack =
  {
    b_eng_1_man_position: false,
    b_eng_1_off_position: false,
    b_eng_1_auto_position: false,
    b_eng_1_ESTOP_fail: false,
    Unassigned1: false,
    b_eng_1_manual_throttle_control: false,
    b_eng_1_throttle_preset_position: false,
    b_eng_1_throttle_auto_position: false,
    b_eng_1_engine_run_out: false,
    b_eng_1_engine_fail_out: false,
    b_eng_1_throttle_idleback_failure_active: false,
    b_eng_1_door_board_comm_fail_alarm: false,
    Unassigned2: false,
    Unassigned3: false,
    Unassigned4: false,
    Unassigned5: false,
  }

  const e1s1BitPack = JSON.parse(JSON.stringify(engineStatus1BitPack));
  const e1s2BitPack = JSON.parse(JSON.stringify(engineStatus2BitPack));
  const e1s3BitPack = JSON.parse(JSON.stringify(engineStatus3BitPack));

  const e2s1BitPack = JSON.parse(JSON.stringify(engineStatus1BitPack));
  const e2s2BitPack = JSON.parse(JSON.stringify(engineStatus2BitPack));
  const e2s3BitPack = JSON.parse(JSON.stringify(engineStatus3BitPack));

  const e3s1BitPack = JSON.parse(JSON.stringify(engineStatus1BitPack));
  const e3s2BitPack = JSON.parse(JSON.stringify(engineStatus2BitPack));
  const e3s3BitPack = JSON.parse(JSON.stringify(engineStatus3BitPack));

  const e4s1BitPack = JSON.parse(JSON.stringify(engineStatus1BitPack));
  const e4s2BitPack = JSON.parse(JSON.stringify(engineStatus2BitPack));
  const e4s3BitPack = JSON.parse(JSON.stringify(engineStatus3BitPack));

  const e5s1BitPack = JSON.parse(JSON.stringify(engineStatus1BitPack));
  const e5s2BitPack = JSON.parse(JSON.stringify(engineStatus2BitPack));
  const e5s3BitPack = JSON.parse(JSON.stringify(engineStatus3BitPack));

  iterateBinary(dec2bin(e1s1), e1s1BitPack);
  iterateBinary(dec2bin(e1s2), e1s2BitPack);
  iterateBinary(dec2bin(e1s3), e1s3BitPack);

  iterateBinary(dec2bin(e2s1), e2s1BitPack);
  iterateBinary(dec2bin(e2s2), e2s2BitPack);
  iterateBinary(dec2bin(e2s3), e2s3BitPack);

  iterateBinary(dec2bin(e3s1), e3s1BitPack);
  iterateBinary(dec2bin(e3s2), e3s2BitPack);
  iterateBinary(dec2bin(e3s3), e3s3BitPack);

  iterateBinary(dec2bin(e4s1), e4s1BitPack);
  iterateBinary(dec2bin(e4s2), e4s2BitPack);
  iterateBinary(dec2bin(e4s3), e4s3BitPack);

  iterateBinary(dec2bin(e5s1), e5s1BitPack);
  iterateBinary(dec2bin(e5s2), e5s2BitPack);
  iterateBinary(dec2bin(e5s3), e5s3BitPack);


  return {
    e1s1BitPack,
    e1s2BitPack,
    e1s3BitPack,
    e2s1BitPack,
    e2s2BitPack,
    e2s3BitPack,
    e3s1BitPack,
    e3s2BitPack,
    e3s3BitPack,
    e4s1BitPack,
    e4s2BitPack,
    e4s3BitPack,
    e5s1BitPack,
    e5s2BitPack,
    e5s3BitPack,
  };
};

export const isEnginePresent = (state, deviceId) => {

  const device = getIoTDeviceById(state, deviceId);
  const { telemetry = {} } = device || {};

  const { REMOTE_DATA_TX = {} } = telemetry || {};

  return {
    engine1Online: REMOTE_DATA_TX.e1s1 > 0,
    engine2Online: REMOTE_DATA_TX.e2s1 > 0,
    engine3Online: REMOTE_DATA_TX.e3s1 > 0,
    engine4Online: REMOTE_DATA_TX.e4s1 > 0,
    engine5Online: REMOTE_DATA_TX.e5s1 > 0,
  }
  // const {
  //   e1s1,
  //   e2s1,
  //   e3s1,
  //   e4s1,
  //   e5s1,
  // } = getTelemetryForDevice(state, deviceId);


  // return {
  //   engine1Online: e1s1 != 0 && e1s1 != '-' ? true : false,
  //   engine2Online: e2s1 != 0 && e1s1 != '-' ? true : false,
  //   engine3Online: e3s1 != 0 && e1s1 != '-' ? true : false,
  //   engine4Online: e4s1 != 0 && e1s1 != '-' ? true : false,
  //   engine5Online: e5s1 != 0 && e1s1 != '-' ? true : false,
  // }
}

export const isCentralPresent = (state, deviceId) => {

  const device = getIoTDeviceById(state, deviceId);
  const { telemetry = {} } = device || {};

  const { REMOTE_DATA_TX = {} } = telemetry || {};

  return REMOTE_DATA_TX.c_vdc > 0;

  // const {
  //   ss1,
  //   ss2,
  //   ss3,
  //   ss4,
  //   ss5,
  //   ss6,
  //   ss7,
  // } = getTelemetryForDevice(state, deviceId);

  // if (ss1 != '-' || ss2 != '-' || ss3 != '-' || ss4 != '-' || ss5 != '-' || ss6 != '-' || ss7 != '-') {
  //   return true;
  // } else {
  //   return false;
  // }
}

export const isDiversionPresent = (state, deviceId) => {

  const device = getIoTDeviceById(state, deviceId);
  const { telemetry = {} } = device || {};

  const { REMOTE_DATA_TX = {} } = telemetry || {};

  return Array.isArray(REMOTE_DATA_TX);
}


export const getTelemetryForCentralBitPack = (state, deviceId) => {
  const {
    ss1, //Bit packed decimal. See ss1BitPack for description
    ss2, //Bit packed decimal. See ss2BitPack for description
    ss3, //Bit packed decimal. See ss3BitPack for description
    ss4, //Bit packed decimal. See ss4BitPack for description
    ss5, //Bit packed decimal. See ss5BitPack for description
    ss6, //Bit packed decimal. See ss6BitPack for description
    ss7, //Bit packed decimal. See ss7BitPack for description
    sp1_s, //central sump. 1 These are bit-packed.
    sp2_s, //central sump. 2 These are bit-packed.
    sp3_s, //central sump. 3 These are bit-packed.
  } = getTelemetryForDevice(state, deviceId);

  //This bitpack may need to be updated. I put unassigned for data that I'm unsure the meaning of.
  const sumpPumpBitPack =
  {
    Unassigned1: false,
    sump_is_required: false,
    sump_is_ready: false,
    Unassigned2: false,
    sump_is_running: false,
    Unassigned3: false,
    sump_has_failed: false,
    sump_has_failed2: false,
    Unassigned4: false,
    Unassigned5: false,
    Unassigned6: false,
    Unassigned7: false,
    Unassigned8: false,
    Unassigned9: false,
    Unassigned10: false,
    Unassigned11: false,
  }

  const ss1BitPack = {
    b_hi_level_float_alarm: false,
    b_intrusion_alarm_active: false,
    b_level_transducer_fail_alarm: false,
    b_flow_xducer_fail_alarm: false,
    b_run_ELECTRIC_PUMP_1_input: false,
    b_ELECTRIC_PUMP_1_summary_fail: false,
    b_panel_in_HAND_ELECTRIC_PUMP_1_input: false,
    b_electric_pump_1_not_ready: false,
    b_central_in_emergency_op: false,
    b_P1_seal_fail_alarm: false,
    b_diversion_to_channel_input: false,
    b_diversion_to_transistion_input: false,
    b_diversion_to_treatment_input: false,
    b_diversion_valve_output_called: false,
    b_diversion_valve_output_not_called: false,
    b_storm_event_active: false,
  }

  const ss2BitPack = {
    b_run_ELECTRIC_PUMP_2_input: false,
    b_ELECTRIC_PUMP_2_summary_fail: false,
    b_panel_in_HAND_ELECTRIC_PUMP_2_input: false,
    b_electric_pump_2_not_ready: false,
    b_P2_seal_fail_alarm: false,
    b_run_ELECTRIC_PUMP_3_input: false,
    b_ELECTRIC_PUMP_3_summary_fail: false,
    b_panel_in_HAND_ELECTRIC_PUMP_3_input: false,
    b_electric_pump_3_not_ready: false,
    b_P3_seal_fail_alarm: false,
    b_low_LPG_pressure_alarm: false,
    b_low_NATURAL_GAS_pressure_alarm: false,
    b_low_level_float_alarm: false,
    b_level_greater_stop: false,
    b_diversion_auto_input: false,
    b_hi_catch_basin_level_alarm: false,
  }

  const ss3BitPack = {
    b_test_level_input: false,
    b_ESTOP_fail_alarm: false,
    b_P2_hi_winding_temp_alarm: false,
    b_hi_level_float_status: false,
    b_combustable_gas_detect: false,
    b_P3_hi_winding_temp_alarm: false,
    b_eng_1_clutch_disengaged: false,
    b_eng_2_clutch_disengaged: false,
    b_eng_3_clutch_disengaged: false,
    b_eng_4_clutch_disengaged: false,
    b_eng_5_clutch_disengaged: false,
    b_gas_detect_trouble: false,
    b_P1_hi_winding_temp_alarm: false,
    b_bypass_intrusion_alarm: false,
    Unassigned1: false,
    Unassigned2: false,
  }

  const ss4BitPack = {
    b_low_catch_basin_level_alarm: false,
    b_hi_catch_basin_level_alarm: false,
    b_P1_seal_fail_alarm: false,
    b_P2_seal_fail_alarm: false,
    b_P3_seal_fail_alarm: false,
    b_P1_hi_winding_temp_alarm: false,
    b_P2_hi_winding_temp_alarm: false,
    b_P3_hi_winding_temp_alarm: false,
    b_pump_1_run_fail_alarm: false,
    b_pump_2_run_fail_alarm: false,
    b_pump_3_run_fail_alarm: false,
    b_low_NATURAL_GAS_pressure_alarm: false,
    b_low_LPG_pressure_alarm: false,
    b_common_alarm: false,
    b_fail_ELECTRIC_PUMP_1: false,
    b_fail_ELECTRIC_PUMP_2: false,
  }

  const ss5BitPack = {
    b_fail_ELECTRIC_PUMP_3: false,
    b_atleast_one_electric_motor_available: false,
    b_atleast_one_engine_available: false,
    b_level_transducer_fail_alarm: false,
    b_flow_xducer_fail_alarm: false,
    b_both_xmitters_fail_alarm: false,
    b_catch_basin_OOR_low_alarm: false,
    b_catch_basin_OOR_hi_alarm: false,
    b_flow_OOR_low_alarm: false,
    b_flow_OOR_hi_alarm: false,
    b_storm_event_active: false,
    Unassigned1: false,
    Unassigned2: false,
    Unassigned3: false,
    b_hi_level_float_alarm: false,
    b_low_level_float_alarm: false,
  }

  const ss6BitPack = {
    b_ELECTRIC_PUMP_1_required: false,
    b_ELECTRIC_PUMP_2_required: false,
    b_ELECTRIC_PUMP_3_required: false,
    b_high_voltage_active_alarm: false,
    b_low_voltage_active_alarm: false,
    b_central_in_emergency_op: false,
    b_diversion_valve_output_called: false,
    b_engine_1_run: false,
    b_engine_2_run: false,
    b_engine_3_run: false,
    b_engine_4_run: false,
    b_engine_5_run: false,
    b_lost_comm_ENGINE_1_alarm: false,
    b_lost_comm_ENGINE_2_alarm: false,
    b_lost_comm_ENGINE_3_alarm: false,
    b_lost_comm_ENGINE_4_alarm: false,
  }

  const ss7BitPack = {
    b_engine_1_call: false,
    b_engine_2_call: false,
    b_engine_3_call: false,
    b_engine_4_call: false,
    b_engine_5_call: false,
    b_inc: false,
    b_dec: false,
    b_engine_1_status: false,
    b_engine_2_status: false,
    b_engine_3_status: false,
    b_engine_4_status: false,
    b_engine_5_status: false,
    b_lost_comm_ENGINE_5_alarm: false,
    Unassigned1: false,
    Unassigned2: false,
    Unassigned3: false,
  }


  const sp1_sBitPack = JSON.parse(JSON.stringify(sumpPumpBitPack));
  const sp2_sBitPack = JSON.parse(JSON.stringify(sumpPumpBitPack));
  const sp3_sBitPack = JSON.parse(JSON.stringify(sumpPumpBitPack));


  iterateBinary(dec2bin(ss1), ss1BitPack);
  iterateBinary(dec2bin(ss2), ss2BitPack);
  iterateBinary(dec2bin(ss3), ss3BitPack);
  iterateBinary(dec2bin(ss4), ss4BitPack);
  iterateBinary(dec2bin(ss5), ss5BitPack);
  iterateBinary(dec2bin(ss6), ss6BitPack);
  iterateBinary(dec2bin(ss7), ss7BitPack);

  iterateBinary(dec2bin(sp1_s), sp1_sBitPack);
  iterateBinary(dec2bin(sp2_s), sp2_sBitPack);
  iterateBinary(dec2bin(sp3_s), sp3_sBitPack);


  return {
    ss1BitPack,
    ss2BitPack,
    ss3BitPack,
    ss4BitPack,
    ss5BitPack,
    ss6BitPack,
    ss7BitPack,
    sp1_sBitPack,
    sp2_sBitPack,
    sp3_sBitPack,
  };
};

export const getTelemetryForDiversionBitPack = (state, deviceId) => {
  const {
    MB_SYSTEM_1_status_bits_local, //Bit packed decimal. See ss1BitPack for description
    MB_SYSTEM_2_status_bits_local,
    MB_SYSTEM_3_status_bits_local,
    MB_SYSTEM_4_status_bits_local, //Bit packed decimal. See ss2BitPack for description
    MB_alarm_shutdown_register_1,
  } = getAlisoViejoTelemetryForDevice(state, deviceId);


  const alarmShutdownRegister1BitPack =
  {
    b_high_system_var_1_alarm: false,
    b_hi_hi_float_level_active: false,
    b_low_system_var_1_alarm: false,
    b_lo_lo_float_level_active: false,
    b_back_wash_active: false,
    b_level_transducer_fail_active: false,
    b_high_voltage_alarm_active: false,
    b_low_voltage_alarm_active: false,
    b_pump_1_summary_fail: false,
    b_Controller_FAILURE: false,
    b_treated_flow_transducer_fail_active: false,
    b_sewer_flow_transducer_fail_active: false,
    b_MODBUS_COILS748: false,
    b_MODBUS_COILS749: false,
    b_MODBUS_COILS750: false,
    b_MODBUS_COILS751: false,
  }

  const mbSystem1StatusBitpack = {
    b_Controller_power: false,
    b_AUTO_operation: false,
    b_pump_1_required: false,
    b_flow_ctrl_valve_enable_output: false,
    b_UV_system_active: false,
    b_pump_1_running_input: false,
    b_storm_event_active: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
  }

  const mbSystem2StatusBitpack = {
    sdly_1ST_STAGE_STARTb_timer_active: false,
    sdly_1ST_STAGE_STOPb_timer_active: false,
    sdly_PUMP_1_RUN_DELAYb_timer_active: false,
    b_low_level_alarm_dly_timing: false,
    b_hi_level_alarm_dly_timing: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
  }

  const mbSystem3StatusBitpack = {
    b_manual_start_ready: false,
    b_in_range_start_ready: false,
    b_mb_Low_Level_Enabled: false,
    b_mb_Hi_Level_Enabled: false,
    b_flow_valve_function_in_AUTO: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
  }

  const mbSystem4StatusBitpack = {
    b_main_selector_OFF: false,
    b_main_selector_MAN: false,
    b_main_selector_AUTO: false,
    b_UA_selector_OFF: false,
    b_UA_selector_AUTO: false,
    b_UA_selector_ACTIVE: false,
    b_manual_start_input: false,
    b_in_range_start_input: false,
    b_ESTOP_fail: false,
    b_enable_analog_test: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
    b_null: false,
  }


  iterateBinary(dec2bin(MB_alarm_shutdown_register_1), alarmShutdownRegister1BitPack);
  iterateBinary(dec2bin(MB_SYSTEM_1_status_bits_local), mbSystem1StatusBitpack);
  iterateBinary(dec2bin(MB_SYSTEM_2_status_bits_local), mbSystem2StatusBitpack);
  iterateBinary(dec2bin(MB_SYSTEM_3_status_bits_local), mbSystem3StatusBitpack);
  iterateBinary(dec2bin(MB_SYSTEM_4_status_bits_local), mbSystem4StatusBitpack);

  return {
    alarmShutdownRegister1BitPack,
    mbSystem1StatusBitpack,
    mbSystem2StatusBitpack,
    mbSystem3StatusBitpack,
    mbSystem4StatusBitpack,
  };
};

/**
 * Returns true if there is a warning on for the device. False
 * otherwise.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isWarningOnForDevice = (state, deviceId) => {
  // return true;
  const {
    espn,
    efmi,
  } = getTelemetryForDevice(state, deviceId);

  return (espn !== 0) && ((efmi >= 3 && efmi <= 21) || efmi === 31);
};

/**
 * Returns true if there is an alarm on for the device. False otherwise.
 *
 * @param {} state
 * @param {*} deviceId
 */
export const isAlarmOnForDevice = (state, deviceId) => {
  // return true;
  const {
    espn,
    efmi,
  } = getTelemetryForDevice(state, deviceId);

  return (espn !== 0) && (efmi === 0 || efmi === 1 || efmi === 2);
};

/**
 * Returns true if the engine is running for the given device id.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isEngineRunningForDevice = (state, deviceId) => {
  const {
    es,
  } = getTelemetryForDevice(state, deviceId);

  return es !== 1 && es !== '-';
};

/**
 * Returns true if device is reconnecting otherwise false.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isReconnecting = (state, deviceId) => {
  return getIoTDeviceById(state, deviceId)?.reconnectTimerId != null;
}


