// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonGroup from '_components/device_card/ButtonGroup.component';
import EngineState from '_components/device_card/EngineState.component';
import Indicator from '_components/device_card/Indicator.component';
import PressureControl from '_components/device_card/PressureControl.component';
import MiniPressureControl from '_components/device_card/MiniPressureControl.component';
import SpeedControl from '_components/device_card/SpeedControl.component';
import MiniSpeedControl from '_components/device_card/MiniSpeedControl.component';
import MiniDiversionTimerStatusVitals from '_components/device_card/MiniDiversionTimerStatusVitals.component';
import MiniDiversionFunctionStatusVitals from '_components/device_card/MiniDiversionFunctionStatusVitals.component';
import MiniDiversionSystemStatusVitals from '_components/device_card/MiniDiversionSystemStatusVitals.component';
import TelemetryValue from '_components/device_card/TelemetryValue.component';
import Vitals from '_components/device_card/Vitals.component';
import Valves from '_components/device_card/Valves.component';

// Redux
import { connect } from 'react-redux';
import { executeCommand } from '_redux/iot/actions';
import { getTelemetryForDiversionBitPack, getTelemetryForDevice, isWarningOnForDevice, isAlarmOnForDevice, isEngineRunningForDevice } from '_redux/iot/selectors';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { TELEMETRY_DEFAULT_VALUE } from '_redux/iot/selectors';

// UI Framework
import { Text, View } from 'native-base';
import { Image, TouchableOpacity } from 'react-native';
import { Grid, Col, Row } from 'react-native-easy-grid';
import { LinearGauge } from 'react-canvas-gauges';

// Styling
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/MiniCurrentDiversionStatus1.style';
class MiniCurrentDiversionStatus1 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      powerButtonOn: false,
      vitalsMode: true,
    };

  }



  render() {
    const {
      deviceId,
      telemetry,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      REMOTE_DATA_TX,
      statusData,
    } = this.props;

    const {
      s_lvl,
      s_lvl_maint,
      s_lvl_dband,
      s_lvl_stop
    } = REMOTE_DATA_TX


    const {
      mbSystem2StatusBitpack,
    } = statusData;

    const {
      sdly_1ST_STAGE_STARTb_timer_active,
      sdly_1ST_STAGE_STOPb_timer_active,
      sdly_PUMP_1_RUN_DELAYb_timer_active,
      b_low_level_alarm_dly_timing,
      b_hi_level_alarm_dly_timing,
    } = mbSystem2StatusBitpack;

    return (
      <Grid>
        <Col size={75} style={[styles.leftColumn]}>
          <Text>Timer Status</Text>
          {
            <MiniDiversionTimerStatusVitals deviceId={deviceId} />
          }
          <Text>Function Status</Text>
          {
            <MiniDiversionFunctionStatusVitals deviceId={deviceId} />
          }
          <Text>System Status</Text>
          {
            <MiniDiversionSystemStatusVitals deviceId={deviceId} />
          }
        </Col>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    telemetry: getTelemetryForDevice(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
    REMOTE_DATA_TX: getTelemetryForDevice(state, ownProps.deviceId),
    statusData: getTelemetryForDiversionBitPack(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
  };
};

MiniCurrentDiversionStatus1.propTypes = {
  deviceId: PropTypes.string.isRequired,
  executeCommand: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniCurrentDiversionStatus1);
