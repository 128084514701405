// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getTelemetryForDiversionBitPack, getAlisoViejoTelemetryForDevice, getTelemetryForEngineBitPack, isEnginePresent } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import MiniTelemetryValue from '_components/device_card/MiniTelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/MiniDiversionStatusVitals.style';

import { defaultVitalsSettings } from '_components/device_settings/MiniDiversionSystemStatusVitalsSettings.component';

const MAX_VITALS = 20;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <Text style={[styles.vitalsValue]}>{value}</Text>
    </Row>
  );
}


class MiniDiversionSystemStatusVitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      deviceSettings,
      isUserFetchingDeviceCardSettings,
      engineData,
      centralData,
      REMOTE_DATA_TX,
      isEnginePresent,
      engineNumber,
      statusData,
    } = this.props;

    const {
      mbSystem1StatusBitpack,
    } = statusData;

    const {
      b_Controller_power,
      b_AUTO_operation,
      b_pump_1_required,
      b_flow_ctrl_valve_enable_output,
      b_UV_system_active,
      b_pump_1_running_input,
      b_storm_event_active,
    } = mbSystem1StatusBitpack;

    let controllerPowerOn = "-";
    if(b_Controller_power){
      controllerPowerOn = 'ON';
    }else{
      controllerPowerOn = 'OFF';
    }

    let autoOperation = "-";
    if(b_AUTO_operation){
      autoOperation = 'ON';
    }else{
      autoOperation = 'OFF';
    }

    let pumpRequired = "-";
    if(b_pump_1_required){
      pumpRequired = 'ON';
    }else{
      pumpRequired = 'OFF';
    }

    let dischargeFlowControlValve = "-";
    if(b_flow_ctrl_valve_enable_output){
      dischargeFlowControlValve = 'ON';
    }else{
      dischargeFlowControlValve = 'OFF';
    }

    let ultravioletSystem = "-";
    if(b_UV_system_active){
      ultravioletSystem = 'ON';
    }else{
      ultravioletSystem = 'OFF';
    }

    let motorRunning = "-";
    if(b_pump_1_running_input){
      motorRunning = 'ON';
    }else{
      motorRunning = 'OFF';
    }

    let stormEvent = "-";
    if(b_storm_event_active){
      stormEvent = 'ON';
    }else{
      stormEvent = 'OFF';
    }

    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;


    //Note: for the engine hours and water level it's divided by 10 to give the correct ammount. We may want to perform this or other calculations outside
    // of the "vitals" const.
    //Lw Lvl Bub Timing
    //Ultraviolet System
    const vitals = [
      { id: 'controllerPowerOn', label: 'Man Start Avail', value: controllerPowerOn, unit: '' },
      { id: 'autoOperation', label: 'Auto Operation', value: autoOperation, unit: '' },
      { id: 'pumpRequired', label: 'Pump Required', value: pumpRequired, unit: '' },
      { id: 'dischargeFlowControlValve', label: 'Discharge Flow Control Valve', value: dischargeFlowControlValve, unit: '' },
      { id: 'ultravioletSystem', label: 'Ultraviolet System', value: ultravioletSystem, unit: '' },
      { id: 'motorRunning', label: 'Motor Running', value: motorRunning, unit: '' },
      { id: 'stormEvent', label: 'Storm Event', value: stormEvent, unit: '' },
    ];


    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value}
            unit={item.unit}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    REMOTE_DATA_TX: getAlisoViejoTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    engineData: getTelemetryForEngineBitPack(state, ownProps.deviceId),
    isEnginePresent: isEnginePresent(state, ownProps.deviceId),
    statusData: getTelemetryForDiversionBitPack(state, ownProps.deviceId),
  };
};

MiniDiversionSystemStatusVitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(MiniDiversionSystemStatusVitals);
