// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import {
  iotDeviceIsConnected,
  isAlarmOnForDevice,
  isWarningOnForDevice,
  isEngineRunningForDevice,
}
  from '_redux/iot/selectors';
import { isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';


// Components
import CurrentEngine from '_components/device_card/CurrentEngine.component';
import DeviceSettings from '_components/device_settings/DeviceSettings.component';
import SignalStrength from '_components/device_card/SignalStrength.component';
import CurrentCentral from '_components/device_card/CurrentCentral.component';
import CurrentDiversionStation from '_components/device_card/CurrentDiversionStation.component';
import CurrentDiversionMisc from '_components/device_card/CurrentDiversionMisc.component';
import CurrentDiversionFlowrate from '_components/device_card/CurrentDiversionFlowrate.component';


// React-Native
import { Image, TouchableOpacity } from 'react-native';

// Native Base
import {
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';

import { styles as global, card_yellow, card_gray, card_red, card_green } from '_style/Global.style';
import { styles } from '_components/device_card/DeviceCard.style';

class DeviceCard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSettings: false,
    };

    this._handleSettingsButtonPress = this._handleSettingsButtonPress.bind(this);
    this._handleCloseSettingsButtonPress = this._handleCloseSettingsButtonPress.bind(this);
  }

  _handleSettingsButtonPress() {
    this.setState({
      showSettings: true,
    });
  }

  _handleCloseSettingsButtonPress() {
    this.setState({
      showSettings: false,
    });
  }

  // Callback to Dashboard
  // We received this callback from CurrentEngine
  handleCallbackMakeValvesVitalsFalse = () => {
    this.props.handleCallbackMakeValvesVitalsFalse();
  }

  render() {

    const {
      deviceId,
      deviceName,
      deviceIsConnected,
      disabled = false,
      isFetchingCardSettings,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      showAllValves,
      showAllVitals,
      engineNumber,
      centralDeviceCard,
      engineDeviceCard,
      diversionStationCard,
      diversionMiscCard,
      diversionFlowrateCard,
      REMOTE_DATA_TX,
    } = this.props;
    const { showSettings } = this.state;


    let headerColor = card_gray;
    if (isEngineRunningForDevice) headerColor = card_green;
    if (isWarningOnForDevice) headerColor = card_yellow;
    if (isAlarmOnForDevice) headerColor = card_red;

    return (
      <Card style={[global.card, styles.deviceCard]}>
        {
          disabled &&
          <View style={[styles.disabledContainer]}>
            <Text style={[styles.disabledText]}>This device has been suspended.</Text>
          </View>
        }
        {
          !deviceIsConnected &&
          <View style={[global.disconnectedContainer]}>
            <Text style={[global.connectingText]}>
              CONNECTING
            </Text>
            <Spinner color='white' />
          </View>
        }

        {/* Header */}
        <CardItem header style={[styles.deviceCardTitle, { backgroundColor: headerColor }]}>

          {/* Device Id */}
          <Text style={[
            global.fontRobotoCondensedBold,
            global.textDark,
            styles.deviceCardTitleText
          ]}>
            {/* Replaced below from this: { deviceName || deviceId } */}
            {deviceName || deviceId}
          </Text>
        </CardItem>

        {/* Current Engine*/}
        <CardItem style={[styles.deviceCardContent]}>
          {
            engineDeviceCard ?
            <CurrentEngine engineNumber={engineNumber} callbackMakeValvesVitalsFalse={this.handleCallbackMakeValvesVitalsFalse} deviceId={deviceId} />
            :
            centralDeviceCard ?
            <CurrentCentral callbackMakeValvesVitalsFalse={this.handleCallbackMakeValvesVitalsFalse} deviceId={deviceId} />
            :
            diversionStationCard ?
            <CurrentDiversionStation callbackMakeValvesVitalsFalse={this.handleCallbackMakeValvesVitalsFalse} deviceId={deviceId} />
            :
            diversionMiscCard ?
            <CurrentDiversionMisc callbackMakeValvesVitalsFalse={this.handleCallbackMakeValvesVitalsFalse} deviceId={deviceId} />
            :
            diversionFlowrateCard ?
            <CurrentDiversionFlowrate callbackMakeValvesVitalsFalse={this.handleCallbackMakeValvesVitalsFalse} deviceId={deviceId} />
            :
            null
          }
        </CardItem>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceIsConnected: iotDeviceIsConnected(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
  };
};

DeviceCard.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  deviceIsConnected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isFetchingCardSettings: PropTypes.bool.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(DeviceCard);
